import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/FutsalDaejeon"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>
      <Parallax
        bgImage="https://img.0u0.kr/new_port/futsalDaejeon_bg2.jpg"
        strength={130}
      >
        <div className="parallaxHeight vh100"></div>
      </Parallax>
      <div className="whiteBg">
        <div className="fullPhotoBox two flexStyle fixMobies">
          <img src="https://img.0u0.kr/new_port/futsalDaejeon_bg.jpg" alt="" />
          <div className="textAreas">
            <div className="basicDetailTitleBox">
              <div className="txtBox">
                <h2>풋살대전 소개</h2>
                풋살대전 앱을 통해 매칭 신청과 진행 현황을 한 눈에 확인할 수
                있으며, 대회 진행, 대진표, 경기 결과, 일정 및 세부 내용을 확인
                할 수 있습니다. 팀을 등록하고 매칭 등록/신청, 용병 구인/구직,
                출석관리, 장부관리 등 다양한 기능을 이용할 수 있습니다.
                <br />
                <br />
                IONIC3로 개발되었으며, API는 PHP로 제작되었습니다. 본인은 IONIC3
                부분을 담당하였으며 퍼블리싱 일부, API 연동, 플러그인 연동
                작업을 진행하였습니다. 현재는 풋살대전 서비스가 중단되었습니다.
                <br />
                <br />
                퍼블리싱이 아닌 처음으로 플러그인과 API를 연동하고 플레이
                스토어, 애플 앱스토어에 업로드 한 작업입니다.
                <div className="linkBox">
                <a
                      href="https://img.0u0.kr/pdf/futsal.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                    소개 PDF
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Detail;
