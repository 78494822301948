import React from "react";

function MainSVG() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="279.321"
        height="258.926"
        viewBox="0 0 279.321 258.926"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#ebe9ec" />
            <stop offset="0.996" stop-color="#99a7d8" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#fff" />
            <stop offset="1" stop-color="#99a7d8" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="0.64"
            y1="0.5"
            x2="1.64"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#ff9800" />
            <stop offset="1" stop-color="#ffc107" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.011" stop-color="#eff1f8" />
            <stop offset="0.184" stop-color="#dadff0" />
            <stop offset="0.532" stop-color="#b6c0e3" />
            <stop offset="0.817" stop-color="#a1adda" />
            <stop offset="1" stop-color="#99a7d8" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#d3daed" />
            <stop offset="1" stop-color="#99a7d8" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="-7.843"
            y1="0.5"
            x2="-6.843"
            y2="0.5"
          />
          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.5"
            r="0.874"
            gradientTransform="translate(0.211 280.835) scale(0.577 0.517)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#5c629e" />
            <stop offset="0.183" stop-color="#8e92bb" />
            <stop offset="0.385" stop-color="#bec0d8" />
            <stop offset="0.563" stop-color="#e1e2ed" />
            <stop offset="0.708" stop-color="#f7f7fa" />
            <stop offset="0.802" stop-color="#fff" />
          </radialGradient>
          <radialGradient
            id="radial-gradient-2"
            cx="0.5"
            cy="0.5"
            r="0.874"
            gradientTransform="translate(0.211 181.223) scale(0.577 0.517)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#48486a" />
            <stop offset="0.029" stop-color="#4f4f70" />
            <stop offset="0.286" stop-color="#8d8da2" />
            <stop offset="0.52" stop-color="#bebeca" />
            <stop offset="0.725" stop-color="#e1e1e7" />
            <stop offset="0.892" stop-color="#f7f7f8" />
            <stop offset="1" stop-color="#fff" />
          </radialGradient>
          <linearGradient
            id="linear-gradient-9"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#ff9800" />
            <stop offset="0.802" stop-color="#ffc107" />
          </linearGradient>
          <radialGradient
            id="radial-gradient-3"
            cx="0.5"
            cy="0.5"
            r="0.875"
            gradientTransform="translate(0.211 323.888) scale(0.577 0.517)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#48486a" />
            <stop offset="0.024" stop-color="#4f4f70" />
            <stop offset="0.229" stop-color="#8d8da2" />
            <stop offset="0.417" stop-color="#bebeca" />
            <stop offset="0.581" stop-color="#e1e1e7" />
            <stop offset="0.715" stop-color="#f7f7f8" />
            <stop offset="0.802" stop-color="#fff" />
          </radialGradient>
          <linearGradient
            id="linear-gradient-11"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.021" stop-color="#ffc107" />
            <stop offset="0.319" stop-color="#ffaf03" />
            <stop offset="0.71" stop-color="#ff9e01" />
            <stop offset="1" stop-color="#ff9800" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-18"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#ffc107" />
            <stop offset="1" stop-color="#ff9800" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-27"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.157" stop-color="#ff9800" />
            <stop offset="0.413" stop-color="#ff9c00" />
            <stop offset="0.69" stop-color="#ffa902" />
            <stop offset="0.976" stop-color="#ffbe06" />
            <stop offset="1" stop-color="#ffc107" />
          </linearGradient>
        </defs>
        <g id="Graphics" transform="translate(-191.227 -35.666)">
          <g id="그룹_139" data-name="그룹 139">
            <g id="그룹_1" data-name="그룹 1">
              <path
                id="패스_3"
                data-name="패스 3"
                d="M295.2,162.506l-77,44.015a2.5,2.5,0,0,0-.009,4.333L338.072,280.07a9.933,9.933,0,0,0,9.9.021l77-44.015a2.5,2.5,0,0,0,.009-4.333L305.093,162.527A9.935,9.935,0,0,0,295.2,162.506Z"
                fill="#fff"
                opacity="0"
              />
              <path
                id="패스_4"
                data-name="패스 4"
                d="M295.276,162.577l-76.764,43.882a2.491,2.491,0,0,0-.01,4.32l119.52,69a9.906,9.906,0,0,0,9.867.021l76.764-43.882a2.491,2.491,0,0,0,.01-4.32l-119.52-69A9.906,9.906,0,0,0,295.276,162.577Z"
                fill="#fbfbfb"
                opacity="0.024"
              />
              <path
                id="패스_5"
                data-name="패스 5"
                d="M295.356,162.648,218.826,206.4a2.483,2.483,0,0,0-.009,4.307L337.972,279.5a9.872,9.872,0,0,0,9.837.022l76.53-43.748a2.483,2.483,0,0,0,.009-4.307L305.193,162.669A9.877,9.877,0,0,0,295.356,162.648Z"
                fill="#f6f6f8"
                opacity="0.049"
              />
              <path
                id="패스_6"
                data-name="패스 6"
                d="M295.436,162.72l-76.295,43.614a2.475,2.475,0,0,0-.01,4.293l118.791,68.584a9.843,9.843,0,0,0,9.807.021l76.295-43.613a2.476,2.476,0,0,0,.01-4.294L305.243,162.741A9.843,9.843,0,0,0,295.436,162.72Z"
                fill="#f2f2f4"
                opacity="0.073"
              />
              <path
                id="패스_7"
                data-name="패스 7"
                d="M295.517,162.791l-76.062,43.48a2.468,2.468,0,0,0-.009,4.281l118.425,68.373a9.816,9.816,0,0,0,9.777.021l76.062-43.48a2.469,2.469,0,0,0,.009-4.281L305.293,162.812A9.814,9.814,0,0,0,295.517,162.791Z"
                fill="#ededf0"
                opacity="0.098"
              />
              <path
                id="패스_8"
                data-name="패스 8"
                d="M295.6,162.863,219.77,206.209a2.46,2.46,0,0,0-.01,4.267l118.061,68.163a9.784,9.784,0,0,0,9.747.021l75.827-43.346a2.461,2.461,0,0,0,.01-4.268L305.343,162.884A9.78,9.78,0,0,0,295.6,162.863Z"
                fill="#e9e9ed"
                opacity="0.122"
              />
              <path
                id="패스_9"
                data-name="패스 9"
                d="M295.677,162.934l-75.593,43.212a2.453,2.453,0,0,0-.009,4.254l117.7,67.952a9.752,9.752,0,0,0,9.717.021l75.593-43.212a2.453,2.453,0,0,0,.009-4.254l-117.7-67.952A9.752,9.752,0,0,0,295.677,162.934Z"
                fill="#e4e4e9"
                opacity="0.146"
              />
              <path
                id="패스_10"
                data-name="패스 10"
                d="M295.757,163.005,220.4,206.084a2.445,2.445,0,0,0-.009,4.241l117.331,67.741a9.724,9.724,0,0,0,9.686.021l75.359-43.078a2.445,2.445,0,0,0,.009-4.241L305.444,163.026A9.726,9.726,0,0,0,295.757,163.005Z"
                fill="#e0e0e6"
                opacity="0.171"
              />
              <path
                id="패스_11"
                data-name="패스 11"
                d="M295.838,163.077l-75.125,42.944a2.438,2.438,0,0,0-.009,4.228L337.671,277.78a9.69,9.69,0,0,0,9.656.02l75.125-42.944a2.437,2.437,0,0,0,.009-4.227L305.494,163.1A9.692,9.692,0,0,0,295.838,163.077Z"
                fill="#dbdbe2"
                opacity="0.195"
              />
              <path
                id="패스_12"
                data-name="패스 12"
                d="M295.918,163.148l-74.89,42.811a2.43,2.43,0,0,0-.01,4.214l116.6,67.321a9.663,9.663,0,0,0,9.626.02l74.89-42.81a2.43,2.43,0,0,0,.009-4.215l-116.6-67.32A9.66,9.66,0,0,0,295.918,163.148Z"
                fill="#d7d7de"
                opacity="0.22"
              />
              <path
                id="패스_13"
                data-name="패스 13"
                d="M296,163.219,221.342,205.9a2.422,2.422,0,0,0-.009,4.2l116.238,67.11a9.633,9.633,0,0,0,9.6.021l74.656-42.677a2.422,2.422,0,0,0,.009-4.2L305.594,163.24A9.633,9.633,0,0,0,296,163.219Z"
                fill="#d2d2db"
                opacity="0.244"
              />
              <path
                id="패스_14"
                data-name="패스 14"
                d="M296.078,163.291l-74.421,42.543a2.415,2.415,0,0,0-.01,4.188l115.873,66.9a9.6,9.6,0,0,0,9.566.021L421.508,234.4a2.415,2.415,0,0,0,.009-4.188l-115.873-66.9A9.6,9.6,0,0,0,296.078,163.291Z"
                fill="#ceced7"
                opacity="0.268"
              />
              <path
                id="패스_15"
                data-name="패스 15"
                d="M296.159,163.362l-74.188,42.409a2.407,2.407,0,0,0-.009,4.175L337.47,276.635a9.572,9.572,0,0,0,9.536.02l74.188-42.409a2.407,2.407,0,0,0,.009-4.174L305.694,163.383A9.57,9.57,0,0,0,296.159,163.362Z"
                fill="#c9c9d3"
                opacity="0.293"
              />
              <path
                id="패스_16"
                data-name="패스 16"
                d="M296.239,163.433l-73.953,42.276a2.4,2.4,0,0,0-.009,4.161L337.42,276.349a9.543,9.543,0,0,0,9.506.02l73.953-42.275a2.4,2.4,0,0,0,.009-4.162L305.745,163.454A9.543,9.543,0,0,0,296.239,163.433Z"
                fill="#c5c5d0"
                opacity="0.317"
              />
              <path
                id="패스_17"
                data-name="패스 17"
                d="M296.319,163.505,222.6,205.646a2.392,2.392,0,0,0-.009,4.148L337.37,276.062a9.511,9.511,0,0,0,9.476.021l73.719-42.141a2.392,2.392,0,0,0,.009-4.149L305.8,163.525A9.514,9.514,0,0,0,296.319,163.505Z"
                fill="#c1c1cc"
                opacity="0.341"
              />
              <path
                id="패스_18"
                data-name="패스 18"
                d="M296.4,163.576l-73.484,42.007a2.385,2.385,0,0,0-.009,4.136L337.32,275.776a9.48,9.48,0,0,0,9.445.02l73.485-42.007a2.384,2.384,0,0,0,.009-4.135L305.845,163.6A9.484,9.484,0,0,0,296.4,163.576Z"
                fill="#bcbcc8"
                opacity="0.366"
              />
              <path
                id="패스_19"
                data-name="패스 19"
                d="M296.48,163.648l-73.251,41.873a2.377,2.377,0,0,0-.009,4.122L337.27,275.49a9.451,9.451,0,0,0,9.415.02l73.251-41.873a2.378,2.378,0,0,0,.009-4.123l-114.05-65.846A9.448,9.448,0,0,0,296.48,163.648Z"
                fill="#b8b8c5"
                opacity="0.39"
              />
              <path
                id="패스_20"
                data-name="패스 20"
                d="M296.56,163.719l-73.017,41.739a2.369,2.369,0,0,0-.008,4.109L337.219,275.2a9.423,9.423,0,0,0,9.386.02l73.016-41.739a2.37,2.37,0,0,0,.009-4.11L305.945,163.739A9.421,9.421,0,0,0,296.56,163.719Z"
                fill="#b3b3c1"
                opacity="0.415"
              />
              <path
                id="패스_21"
                data-name="패스 21"
                d="M296.64,163.79,223.858,205.4a2.362,2.362,0,0,0-.009,4.1l113.32,65.425a9.39,9.39,0,0,0,9.355.02l72.783-41.605a2.362,2.362,0,0,0,.009-4.1L305.995,163.81A9.392,9.392,0,0,0,296.64,163.79Z"
                fill="#afafbe"
                opacity="0.439"
              />
              <path
                id="패스_22"
                data-name="패스 22"
                d="M296.721,163.862l-72.549,41.471a2.354,2.354,0,0,0-.008,4.083l112.955,65.215a9.36,9.36,0,0,0,9.325.02l72.548-41.471A2.354,2.354,0,0,0,419,229.1L306.046,163.882A9.36,9.36,0,0,0,296.721,163.862Z"
                fill="#aaaaba"
                opacity="0.463"
              />
              <path
                id="패스_23"
                data-name="패스 23"
                d="M296.8,163.933l-72.314,41.338a2.346,2.346,0,0,0-.009,4.069l112.591,65.005a9.331,9.331,0,0,0,9.3.02l72.314-41.338a2.346,2.346,0,0,0,.009-4.069l-112.591-65A9.329,9.329,0,0,0,296.8,163.933Z"
                fill="#a6a6b6"
                opacity="0.488"
              />
              <path
                id="패스_24"
                data-name="패스 24"
                d="M296.881,164l-72.08,41.2a2.339,2.339,0,0,0-.008,4.057l112.226,64.793a9.3,9.3,0,0,0,9.265.02l72.079-41.2a2.339,2.339,0,0,0,.009-4.057L306.146,164.024A9.3,9.3,0,0,0,296.881,164Z"
                fill="#a1a1b3"
                opacity="0.512"
              />
              <path
                id="패스_25"
                data-name="패스 25"
                d="M296.961,164.076l-71.845,41.07a2.331,2.331,0,0,0-.009,4.043l111.862,64.583a9.266,9.266,0,0,0,9.234.02l71.846-41.07a2.331,2.331,0,0,0,.009-4.043L306.2,164.1A9.27,9.27,0,0,0,296.961,164.076Z"
                fill="#9d9daf"
                opacity="0.537"
              />
              <path
                id="패스_26"
                data-name="패스 26"
                d="M297.042,164.147,225.43,205.083a2.323,2.323,0,0,0-.008,4.03l111.5,64.373a9.241,9.241,0,0,0,9.2.02l71.611-40.936a2.324,2.324,0,0,0,.009-4.03l-111.5-64.373A9.239,9.239,0,0,0,297.042,164.147Z"
                fill="#9898ab"
                opacity="0.561"
              />
              <path
                id="패스_27"
                data-name="패스 27"
                d="M297.122,164.219l-71.377,40.8a2.316,2.316,0,0,0-.009,4.017L336.868,273.2a9.211,9.211,0,0,0,9.175.02l71.377-40.8a2.317,2.317,0,0,0,.009-4.017L306.3,164.238A9.207,9.207,0,0,0,297.122,164.219Z"
                fill="#9494a8"
                opacity="0.585"
              />
              <path
                id="패스_28"
                data-name="패스 28"
                d="M297.2,164.29l-71.143,40.668a2.309,2.309,0,0,0-.008,4l110.767,63.952a9.18,9.18,0,0,0,9.145.019l71.142-40.668a2.309,2.309,0,0,0,.009-4L306.347,164.309A9.182,9.182,0,0,0,297.2,164.29Z"
                fill="#8f8fa4"
                opacity="0.61"
              />
              <path
                id="패스_29"
                data-name="패스 29"
                d="M297.282,164.361,226.374,204.9a2.3,2.3,0,0,0-.009,3.99l110.4,63.741a9.148,9.148,0,0,0,9.114.02l70.909-40.534a2.3,2.3,0,0,0,.009-3.991L306.4,164.381A9.15,9.15,0,0,0,297.282,164.361Z"
                fill="#8b8ba1"
                opacity="0.634"
              />
              <path
                id="패스_30"
                data-name="패스 30"
                d="M297.363,164.433l-70.675,40.4a2.293,2.293,0,0,0-.008,3.977l110.038,63.531a9.119,9.119,0,0,0,9.084.02l70.674-40.4a2.293,2.293,0,0,0,.009-3.977L306.447,164.452A9.117,9.117,0,0,0,297.363,164.433Z"
                fill="#86869d"
                opacity="0.659"
              />
              <path
                id="패스_31"
                data-name="패스 31"
                d="M297.443,164.5,227,204.771a2.285,2.285,0,0,0-.009,3.964l109.674,63.32a9.087,9.087,0,0,0,9.054.019l70.44-40.266a2.286,2.286,0,0,0,.009-3.964L306.5,164.523A9.09,9.09,0,0,0,297.443,164.5Z"
                fill="#828299"
                opacity="0.683"
              />
              <path
                id="패스_32"
                data-name="패스 32"
                d="M297.523,164.576l-70.206,40.132a2.278,2.278,0,0,0-.008,3.951l109.308,63.109a9.06,9.06,0,0,0,9.025.02l70.2-40.133a2.278,2.278,0,0,0,.009-3.951L306.547,164.6A9.058,9.058,0,0,0,297.523,164.576Z"
                fill="#7e7e96"
                opacity="0.707"
              />
              <path
                id="패스_33"
                data-name="패스 33"
                d="M297.6,164.647l-69.972,40a2.27,2.27,0,0,0-.009,3.937l108.944,62.9a9.029,9.029,0,0,0,8.994.02l69.972-40a2.271,2.271,0,0,0,.008-3.938L306.6,164.666A9.024,9.024,0,0,0,297.6,164.647Z"
                fill="#797992"
                opacity="0.732"
              />
              <path
                id="패스_34"
                data-name="패스 34"
                d="M297.684,164.718l-69.738,39.865a2.262,2.262,0,0,0-.008,3.924L336.517,271.2a9,9,0,0,0,8.964.019l69.737-39.864a2.263,2.263,0,0,0,.009-3.925L306.648,164.737A9,9,0,0,0,297.684,164.718Z"
                fill="#75758e"
                opacity="0.756"
              />
              <path
                id="패스_35"
                data-name="패스 35"
                d="M297.764,164.79l-69.5,39.731a2.255,2.255,0,0,0-.009,3.911L336.467,270.91a8.968,8.968,0,0,0,8.934.019L414.9,231.2a2.255,2.255,0,0,0,.009-3.911L306.7,164.809A8.968,8.968,0,0,0,297.764,164.79Z"
                fill="#70708b"
                opacity="0.78"
              />
              <path
                id="패스_36"
                data-name="패스 36"
                d="M297.844,164.861l-69.269,39.6a2.247,2.247,0,0,0-.008,3.9l107.85,62.268a8.939,8.939,0,0,0,8.9.019l69.269-39.6a2.249,2.249,0,0,0,.009-3.9L306.748,164.88A8.936,8.936,0,0,0,297.844,164.861Z"
                fill="#6c6c87"
                opacity="0.805"
              />
              <path
                id="패스_37"
                data-name="패스 37"
                d="M297.925,164.932,228.89,204.4a2.239,2.239,0,0,0-.009,3.884l107.486,62.057a8.9,8.9,0,0,0,8.873.019l69.035-39.463a2.24,2.24,0,0,0,.008-3.885L306.8,164.951A8.907,8.907,0,0,0,297.925,164.932Z"
                fill="#676783"
                opacity="0.829"
              />
              <path
                id="패스_38"
                data-name="패스 38"
                d="M298.005,165l-68.8,39.329a2.233,2.233,0,0,0-.008,3.872l107.12,61.846a8.877,8.877,0,0,0,8.844.019l68.8-39.329a2.233,2.233,0,0,0,.008-3.872L306.848,165.023A8.875,8.875,0,0,0,298.005,165Z"
                fill="#636380"
                opacity="0.854"
              />
              <path
                id="패스_39"
                data-name="패스 39"
                d="M298.085,165.075l-68.566,39.2a2.225,2.225,0,0,0-.008,3.859l106.755,61.636a8.85,8.85,0,0,0,8.814.019l68.566-39.2a2.224,2.224,0,0,0,.008-3.858L306.9,165.094A8.846,8.846,0,0,0,298.085,165.075Z"
                fill="#5e5e7c"
                opacity="0.878"
              />
              <path
                id="패스_40"
                data-name="패스 40"
                d="M298.165,165.147l-68.332,39.061a2.217,2.217,0,0,0-.008,3.845l106.391,61.425A8.812,8.812,0,0,0,345,269.5l68.332-39.061a2.218,2.218,0,0,0,.009-3.846L306.949,165.165A8.816,8.816,0,0,0,298.165,165.147Z"
                fill="#5a5a79"
                opacity="0.902"
              />
              <path
                id="패스_41"
                data-name="패스 41"
                d="M298.246,165.218l-68.1,38.927a2.211,2.211,0,0,0-.009,3.833l106.027,61.214a8.785,8.785,0,0,0,8.753.019l68.1-38.927a2.21,2.21,0,0,0,.008-3.833L307,165.237A8.785,8.785,0,0,0,298.246,165.218Z"
                fill="#555575"
                opacity="0.927"
              />
              <path
                id="패스_42"
                data-name="패스 42"
                d="M298.326,165.289l-67.864,38.794a2.2,2.2,0,0,0-.008,3.819l105.662,61a8.758,8.758,0,0,0,8.723.019L412.7,230.131a2.2,2.2,0,0,0,.008-3.819l-105.662-61A8.758,8.758,0,0,0,298.326,165.289Z"
                fill="#515171"
                opacity="0.951"
              />
              <path
                id="패스_43"
                data-name="패스 43"
                d="M298.406,165.361,230.777,204.02a2.194,2.194,0,0,0-.008,3.806l105.3,60.794a8.724,8.724,0,0,0,8.693.018l67.629-38.659a2.195,2.195,0,0,0,.008-3.806L307.1,165.379A8.724,8.724,0,0,0,298.406,165.361Z"
                fill="#4c4c6e"
                opacity="0.976"
              />
              <path
                id="패스_44"
                data-name="패스 44"
                d="M298.486,165.432l-67.395,38.526a2.186,2.186,0,0,0-.008,3.792l104.933,60.583a8.693,8.693,0,0,0,8.662.019l67.395-38.526a2.187,2.187,0,0,0,.009-3.793L307.149,165.45A8.7,8.7,0,0,0,298.486,165.432Z"
                fill="#48486a"
              />
            </g>
            <g id="그룹_6" data-name="그룹 6">
              <path
                id="패스_45"
                data-name="패스 45"
                d="M421.453,127.8,305.161,60.659a2.357,2.357,0,0,0-3.535,2.041v87.641a5.124,5.124,0,0,0,2.561,4.436l116.292,67.141a2.356,2.356,0,0,0,3.535-2.04V132.236A5.122,5.122,0,0,0,421.453,127.8Z"
                fill="url(#linear-gradient)"
              />
              <path
                id="패스_46"
                data-name="패스 46"
                d="M419.95,128.8,303.658,61.661a2.357,2.357,0,0,0-3.535,2.041v87.641a5.123,5.123,0,0,0,2.562,4.436L418.977,222.92a2.356,2.356,0,0,0,3.535-2.04V133.238A5.123,5.123,0,0,0,419.95,128.8Z"
                fill="#48486a"
              />
              <path
                id="패스_47"
                data-name="패스 47"
                d="M415.622,134.338,306.189,70.794v80.17l109.433,63.544Z"
                fill="#fff"
              />
              <path
                id="패스_48"
                data-name="패스 48"
                d="M295.408,160l-74.471,39.283s-.41,5.815,1.179,6.732l114.1,65.876a9.458,9.458,0,0,0,9.42.02l73.282-41.891c3.03-1.88,1.2-6.746,1.2-6.746L304.827,160.022A9.456,9.456,0,0,0,295.408,160Z"
                fill="url(#linear-gradient-2)"
              />
              <path
                id="패스_49"
                data-name="패스 49"
                d="M295.408,155.493l-73.283,41.891a2.378,2.378,0,0,0-.009,4.124l114.1,65.876a9.458,9.458,0,0,0,9.42.02l73.282-41.891a2.378,2.378,0,0,0,.009-4.125l-114.1-65.875A9.453,9.453,0,0,0,295.408,155.493Z"
                fill="#f7f7f7"
              />
              <path
                id="패스_50"
                data-name="패스 50"
                d="M288.911,209.737,269.6,220.779a.627.627,0,0,0,0,1.087l30.074,17.363a2.491,2.491,0,0,0,2.482,0l19.316-11.042a.627.627,0,0,0,0-1.087l-30.074-17.363A2.493,2.493,0,0,0,288.911,209.737Z"
                fill="#e5e9f4"
              />
              <path
                id="패스_51"
                data-name="패스 51"
                d="M303.488,161.893l6.314,3.645a.377.377,0,0,1,0,.653l-3.137,1.8a.815.815,0,0,1-.812,0l-6.314-3.646a.376.376,0,0,1,0-.652l3.137-1.8A.815.815,0,0,1,303.488,161.893Z"
                fill="#d3daed"
              />
              <path
                id="패스_52"
                data-name="패스 52"
                d="M312.74,167.4l6.314,3.645a.377.377,0,0,1,0,.653l-3.137,1.8a.81.81,0,0,1-.812,0l-6.314-3.645a.377.377,0,0,1,0-.653l3.136-1.8A.814.814,0,0,1,312.74,167.4Z"
                fill="#d3daed"
              />
              <path
                id="패스_53"
                data-name="패스 53"
                d="M322.025,172.622l6.314,3.645a.377.377,0,0,1,0,.653l-3.136,1.8a.814.814,0,0,1-.813,0l-6.314-3.645a.377.377,0,0,1,0-.653l3.136-1.8A.814.814,0,0,1,322.025,172.622Z"
                fill="#d3daed"
              />
              <path
                id="패스_54"
                data-name="패스 54"
                d="M331.591,178.011l6.313,3.645a.377.377,0,0,1,0,.653l-3.137,1.8a.815.815,0,0,1-.812,0l-6.314-3.645a.377.377,0,0,1,0-.653l3.136-1.8A.814.814,0,0,1,331.591,178.011Z"
                fill="#d3daed"
              />
              <path
                id="패스_55"
                data-name="패스 55"
                d="M340.78,183.132l6.314,3.646a.376.376,0,0,1,0,.652l-3.137,1.8a.812.812,0,0,1-.812,0l-6.314-3.645a.377.377,0,0,1,0-.653l3.136-1.8A.817.817,0,0,1,340.78,183.132Z"
                fill="#d3daed"
              />
              <path
                id="패스_56"
                data-name="패스 56"
                d="M349.714,188.644l6.313,3.645a.376.376,0,0,1,0,.652l-3.137,1.8a.812.812,0,0,1-.812,0l-6.314-3.646a.376.376,0,0,1,0-.652l3.136-1.8A.812.812,0,0,1,349.714,188.644Z"
                fill="#d3daed"
              />
              <path
                id="패스_57"
                data-name="패스 57"
                d="M359.067,193.8l6.314,3.646a.376.376,0,0,1,0,.652l-3.136,1.8a.812.812,0,0,1-.813,0l-6.314-3.645a.376.376,0,0,1,0-.652l3.136-1.8A.817.817,0,0,1,359.067,193.8Z"
                fill="#d3daed"
              />
              <path
                id="패스_58"
                data-name="패스 58"
                d="M368.281,199.287l6.314,3.646a.376.376,0,0,1,0,.652l-3.136,1.8a.814.814,0,0,1-.813,0l-6.314-3.645a.377.377,0,0,1,0-.653l3.137-1.8A.812.812,0,0,1,368.281,199.287Z"
                fill="#d3daed"
              />
              <path
                id="패스_59"
                data-name="패스 59"
                d="M377.342,204.634l6.314,3.646a.376.376,0,0,1,0,.652l-3.136,1.8a.817.817,0,0,1-.813,0l-6.313-3.645a.377.377,0,0,1,0-.653l3.137-1.8A.815.815,0,0,1,377.342,204.634Z"
                fill="#d3daed"
              />
              <path
                id="패스_60"
                data-name="패스 60"
                d="M386.894,210.138l6.314,3.645a.376.376,0,0,1,0,.652l-3.136,1.8a.814.814,0,0,1-.813,0l-6.313-3.645a.376.376,0,0,1,0-.652l3.137-1.8A.812.812,0,0,1,386.894,210.138Z"
                fill="#d3daed"
              />
              <path
                id="패스_61"
                data-name="패스 61"
                d="M396.171,215.343l6.314,3.645a.377.377,0,0,1,0,.653l-3.136,1.8a.814.814,0,0,1-.813,0l-6.313-3.645a.377.377,0,0,1,0-.653l3.137-1.8A.815.815,0,0,1,396.171,215.343Z"
                fill="#d3daed"
              />
              <g id="그룹_2" data-name="그룹 2">
                <path
                  id="패스_62"
                  data-name="패스 62"
                  d="M290.646,168.762l4.277-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.889.889,0,0,1,.021,1.528l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.469A.889.889,0,0,1,290.646,168.762Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_63"
                  data-name="패스 63"
                  d="M300.223,174.273l4.278-2.5a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,300.223,174.273Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_64"
                  data-name="패스 64"
                  d="M309.522,179.491l4.278-2.505a1.789,1.789,0,0,1,1.8,0l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.55a1.788,1.788,0,0,1-1.829.026l-4.285-2.468A.89.89,0,0,1,309.522,179.491Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_65"
                  data-name="패스 65"
                  d="M319.414,184.88l4.278-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,319.414,184.88Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_66"
                  data-name="패스 66"
                  d="M328.13,190l4.277-2.505a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.788,1.788,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,328.13,190Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_67"
                  data-name="패스 67"
                  d="M336.985,195.484l4.278-2.5a1.789,1.789,0,0,1,1.8-.006l4.178,2.413a.889.889,0,0,1,.021,1.528l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.469A.889.889,0,0,1,336.985,195.484Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_68"
                  data-name="패스 68"
                  d="M346.447,200.669l4.278-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.154,2.551a1.79,1.79,0,0,1-1.83.025l-4.285-2.468A.89.89,0,0,1,346.447,200.669Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_69"
                  data-name="패스 69"
                  d="M355.707,205.919l4.278-2.5a1.789,1.789,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.154,2.551a1.79,1.79,0,0,1-1.83.025l-4.285-2.468A.89.89,0,0,1,355.707,205.919Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_70"
                  data-name="패스 70"
                  d="M364.812,211.5,369.09,209a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,364.812,211.5Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_71"
                  data-name="패스 71"
                  d="M374.732,217.345l4.277-2.5a1.786,1.786,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,374.732,217.345Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_72"
                  data-name="패스 72"
                  d="M383.7,222.212l4.277-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,383.7,222.212Z"
                  fill="#d3daed"
                />
              </g>
              <g id="그룹_3" data-name="그룹 3">
                <path
                  id="패스_73"
                  data-name="패스 73"
                  d="M281.115,173.944l4.277-2.5a1.789,1.789,0,0,1,1.8-.006l4.178,2.412a.889.889,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.467A.89.89,0,0,1,281.115,173.944Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_74"
                  data-name="패스 74"
                  d="M290.692,179.456l4.278-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,290.692,179.456Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_75"
                  data-name="패스 75"
                  d="M299.991,184.674l4.278-2.5a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026L300,186.213A.89.89,0,0,1,299.991,184.674Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_76"
                  data-name="패스 76"
                  d="M309.883,190.062l4.278-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529L316,194.044a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,309.883,190.062Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_77"
                  data-name="패스 77"
                  d="M318.6,195.184l4.278-2.505a1.787,1.787,0,0,1,1.8-.005l4.178,2.412a.889.889,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.285-2.468A.89.89,0,0,1,318.6,195.184Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_78"
                  data-name="패스 78"
                  d="M327.454,200.666l4.278-2.505a1.789,1.789,0,0,1,1.8,0l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.285-2.469A.89.89,0,0,1,327.454,200.666Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_79"
                  data-name="패스 79"
                  d="M336.916,205.852l4.278-2.505a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,336.916,205.852Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_80"
                  data-name="패스 80"
                  d="M346.177,211.1l4.277-2.505a1.789,1.789,0,0,1,1.8-.005L356.431,211a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.788,1.788,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,346.177,211.1Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_81"
                  data-name="패스 81"
                  d="M355.281,216.686l4.278-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,355.281,216.686Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_82"
                  data-name="패스 82"
                  d="M365.2,222.528l4.278-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.89.89,0,0,1,.02,1.529l-4.155,2.55a1.788,1.788,0,0,1-1.829.026l-4.285-2.468A.89.89,0,0,1,365.2,222.528Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_83"
                  data-name="패스 83"
                  d="M374.171,227.394l4.278-2.5a1.789,1.789,0,0,1,1.8-.006l4.178,2.412a.889.889,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,374.171,227.394Z"
                  fill="#d3daed"
                />
              </g>
              <g id="그룹_4" data-name="그룹 4">
                <path
                  id="패스_84"
                  data-name="패스 84"
                  d="M271.584,179.127l4.278-2.505a1.789,1.789,0,0,1,1.8,0l4.179,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.285-2.468A.89.89,0,0,1,271.584,179.127Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_85"
                  data-name="패스 85"
                  d="M281.162,184.639l4.278-2.505a1.789,1.789,0,0,1,1.8-.006l4.178,2.413a.889.889,0,0,1,.021,1.528l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,281.162,184.639Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_86"
                  data-name="패스 86"
                  d="M290.461,189.856l4.277-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.891.891,0,0,1,.021,1.53l-4.155,2.55a1.788,1.788,0,0,1-1.829.025l-4.286-2.467A.89.89,0,0,1,290.461,189.856Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_87"
                  data-name="패스 87"
                  d="M300.353,195.245l4.277-2.5a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,300.353,195.245Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_88"
                  data-name="패스 88"
                  d="M309.068,200.367l4.278-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.889.889,0,0,1,.02,1.528l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.285-2.469A.889.889,0,0,1,309.068,200.367Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_89"
                  data-name="패스 89"
                  d="M317.923,205.849l4.278-2.505a1.789,1.789,0,0,1,1.8,0l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.55a1.788,1.788,0,0,1-1.829.026l-4.285-2.468A.89.89,0,0,1,317.923,205.849Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_90"
                  data-name="패스 90"
                  d="M327.386,211.035l4.277-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.788,1.788,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,327.386,211.035Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_91"
                  data-name="패스 91"
                  d="M336.646,216.285l4.277-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,336.646,216.285Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_92"
                  data-name="패스 92"
                  d="M345.751,221.868l4.277-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,345.751,221.868Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_93"
                  data-name="패스 93"
                  d="M355.67,227.711l4.278-2.505a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.889.889,0,0,1,.021,1.528l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.469A.889.889,0,0,1,355.67,227.711Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_94"
                  data-name="패스 94"
                  d="M364.64,232.577l4.278-2.5a1.786,1.786,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.154,2.55a1.787,1.787,0,0,1-1.83.026l-4.285-2.468A.89.89,0,0,1,364.64,232.577Z"
                  fill="#d3daed"
                />
              </g>
              <g id="그룹_5" data-name="그룹 5">
                <path
                  id="패스_95"
                  data-name="패스 95"
                  d="M262.053,184.31l4.278-2.505a1.789,1.789,0,0,1,1.8,0l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.285-2.468A.89.89,0,0,1,262.053,184.31Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_96"
                  data-name="패스 96"
                  d="M271.631,189.822l4.278-2.505a1.789,1.789,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.285-2.467A.89.89,0,0,1,271.631,189.822Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_97"
                  data-name="패스 97"
                  d="M280.93,195.039l4.278-2.5a1.784,1.784,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.79,1.79,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,280.93,195.039Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_98"
                  data-name="패스 98"
                  d="M290.822,200.428l4.278-2.5a1.789,1.789,0,0,1,1.8-.005l4.178,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.829.026l-4.286-2.468A.89.89,0,0,1,290.822,200.428Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_99"
                  data-name="패스 99"
                  d="M300.353,206.117l4.277-2.505a1.791,1.791,0,0,1,1.8-.006l21.749,12.562a.89.89,0,0,1,.02,1.529l-4.155,2.551a1.79,1.79,0,0,1-1.829.025l-21.856-12.617A.89.89,0,0,1,300.353,206.117Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_100"
                  data-name="패스 100"
                  d="M327.115,221.468l4.278-2.505a1.789,1.789,0,0,1,1.8-.006l4.179,2.413a.889.889,0,0,1,.02,1.528l-4.155,2.551a1.79,1.79,0,0,1-1.829.026l-4.286-2.469A.889.889,0,0,1,327.115,221.468Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_101"
                  data-name="패스 101"
                  d="M336.22,227.051l4.277-2.5a1.789,1.789,0,0,1,1.8-.005l4.179,2.412a.89.89,0,0,1,.02,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.468A.89.89,0,0,1,336.22,227.051Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_102"
                  data-name="패스 102"
                  d="M346.139,232.894l4.278-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.412a.89.89,0,0,1,.021,1.529l-4.155,2.551a1.788,1.788,0,0,1-1.829.025l-4.286-2.467A.89.89,0,0,1,346.139,232.894Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_103"
                  data-name="패스 103"
                  d="M355.109,237.76l4.278-2.5a1.791,1.791,0,0,1,1.8-.006l4.178,2.413a.89.89,0,0,1,.021,1.529l-4.155,2.55a1.79,1.79,0,0,1-1.83.026l-4.285-2.468A.89.89,0,0,1,355.109,237.76Z"
                  fill="#d3daed"
                />
              </g>
            </g>
            <g id="그룹_7" data-name="그룹 7">
              <g id="그룹_144" data-name="그룹 144">
                <path
                  id="패스_104"
                  data-name="패스 104"
                  d="M222.873,140.866l5.546-3.2,5.409,3.2-5.409,3.2Z"
                  fill="#6d6d88"
                />
                <path
                  id="패스_105"
                  data-name="패스 105"
                  d="M222.873,140.866v44.49l5.546,3.028V144.068Z"
                  fill="#363650"
                />
                <path
                  id="패스_106"
                  data-name="패스 106"
                  d="M233.828,140.866v44.316l-5.409,3.2V144.068Z"
                  fill="#48486a"
                />
              </g>
              <g id="그룹_143" data-name="그룹 143">
                <path
                  id="패스_107"
                  data-name="패스 107"
                  d="M214.007,161.2l5.546-3.2,5.41,3.2-5.409,3.2Z"
                  fill="#ffda6a"
                />
                <path
                  id="패스_108"
                  data-name="패스 108"
                  d="M214.007,161.2v29.235l5.546,3.028V164.4Z"
                  fill="#ffc107"
                />
                <path
                  id="패스_109"
                  data-name="패스 109"
                  d="M224.962,161.2V190.26l-5.409,3.2V164.4Z"
                  fill="#ffcd39"
                />
              </g>
              <g id="그룹_142" data-name="그룹 142">
                <path
                  id="패스_110"
                  data-name="패스 110"
                  d="M205.142,155.925l5.546-3.2,5.409,3.2-5.409,3.2Z"
                  fill="#f8f9fc"
                />
                <path
                  id="패스_111"
                  data-name="패스 111"
                  d="M205.142,155.925v39.586l5.546,3.028V159.126Z"
                  fill="#e5e9f4"
                />
                <path
                  id="패스_112"
                  data-name="패스 112"
                  d="M216.1,155.925v39.413l-5.409,3.2V159.126Z"
                  fill="#edf0f8"
                />
              </g>
            </g>
            <g id="그룹_9" data-name="그룹 9">
              <g id="그룹_8" data-name="그룹 8">
                <path
                  id="패스_113"
                  data-name="패스 113"
                  d="M406.787,224.213c1.344-.765,1.348-2.024.009-2.8L337.4,181.35a5.3,5.3,0,0,0-4.85.035l-6.536,3.9a1.512,1.512,0,0,0,.02,2.846l69.127,39.91a5.442,5.442,0,0,0,4.878.015Z"
                  fill="#fff"
                  opacity="0"
                />
                <path
                  id="패스_114"
                  data-name="패스 114"
                  d="M406.712,224.178c1.339-.763,1.343-2.017.009-2.787l-69.288-40.015a5.282,5.282,0,0,0-4.832.035l-6.512,3.884a1.507,1.507,0,0,0,.02,2.836l69.02,39.86a5.423,5.423,0,0,0,4.86.015Z"
                  fill="#fbfbfb"
                  opacity="0.024"
                />
                <path
                  id="패스_115"
                  data-name="패스 115"
                  d="M406.637,224.142c1.334-.759,1.338-2.009.008-2.776L337.466,181.4a5.265,5.265,0,0,0-4.814.034l-6.488,3.871a1.5,1.5,0,0,0,.021,2.825l68.912,39.81a5.4,5.4,0,0,0,4.842.014Z"
                  fill="#f6f6f8"
                  opacity="0.049"
                />
                <path
                  id="패스_116"
                  data-name="패스 116"
                  d="M406.562,224.107c1.329-.757,1.333-2,.008-2.766L337.5,181.428a5.241,5.241,0,0,0-4.795.034l-6.464,3.856a1.5,1.5,0,0,0,.02,2.815l68.806,39.759a5.382,5.382,0,0,0,4.824.014Z"
                  fill="#f2f2f4"
                  opacity="0.073"
                />
                <path
                  id="패스_117"
                  data-name="패스 117"
                  d="M406.487,224.072c1.324-.754,1.327-1.994.008-2.756l-68.964-39.862a5.222,5.222,0,0,0-4.777.034l-6.439,3.841a1.491,1.491,0,0,0,.02,2.805l68.7,39.709a5.366,5.366,0,0,0,4.806.014Z"
                  fill="#ededf0"
                  opacity="0.098"
                />
                <path
                  id="패스_118"
                  data-name="패스 118"
                  d="M406.412,224.037c1.319-.752,1.322-1.987.008-2.746L337.564,181.48a5.2,5.2,0,0,0-4.759.034l-6.415,3.827a1.485,1.485,0,0,0,.02,2.794L395,227.793a5.344,5.344,0,0,0,4.788.014Z"
                  fill="#e9e9ed"
                  opacity="0.122"
                />
                <path
                  id="패스_119"
                  data-name="패스 119"
                  d="M406.337,224c1.313-.748,1.317-1.979.008-2.735L337.6,181.506a5.181,5.181,0,0,0-4.741.034l-6.391,3.812a1.479,1.479,0,0,0,.02,2.784l68.484,39.608a5.325,5.325,0,0,0,4.77.014Z"
                  fill="#e4e4e9"
                  opacity="0.146"
                />
                <path
                  id="패스_120"
                  data-name="패스 120"
                  d="M406.262,223.966c1.308-.746,1.312-1.971.008-2.724l-68.64-39.71a5.164,5.164,0,0,0-4.724.034l-6.366,3.8a1.473,1.473,0,0,0,.02,2.772l68.377,39.558a5.306,5.306,0,0,0,4.752.015Z"
                  fill="#e0e0e6"
                  opacity="0.171"
                />
                <path
                  id="패스_121"
                  data-name="패스 121"
                  d="M406.186,223.931c1.3-.743,1.308-1.964.009-2.714l-68.532-39.659a5.145,5.145,0,0,0-4.706.034l-6.342,3.783a1.468,1.468,0,0,0,.02,2.762l68.27,39.508a5.287,5.287,0,0,0,4.734.014Z"
                  fill="#dbdbe2"
                  opacity="0.195"
                />
                <path
                  id="패스_122"
                  data-name="패스 122"
                  d="M406.111,223.9c1.3-.739,1.3-1.956.008-2.7L337.7,181.584a5.126,5.126,0,0,0-4.688.034l-6.318,3.769a1.462,1.462,0,0,0,.02,2.751L394.873,227.6a5.261,5.261,0,0,0,4.716.014Z"
                  fill="#d7d7de"
                  opacity="0.22"
                />
                <path
                  id="패스_123"
                  data-name="패스 123"
                  d="M406.036,223.86c1.294-.737,1.3-1.949.008-2.693L337.729,181.61a5.107,5.107,0,0,0-4.67.034l-6.293,3.754a1.457,1.457,0,0,0,.019,2.741l68.056,39.407a5.245,5.245,0,0,0,4.7.014Z"
                  fill="#d2d2db"
                  opacity="0.244"
                />
                <path
                  id="패스_124"
                  data-name="패스 124"
                  d="M405.961,223.825c1.289-.734,1.293-1.941.008-2.683l-68.207-39.506a5.091,5.091,0,0,0-4.652.033l-6.269,3.741a1.451,1.451,0,0,0,.019,2.73L394.809,227.5a5.223,5.223,0,0,0,4.68.014Z"
                  fill="#ceced7"
                  opacity="0.268"
                />
                <path
                  id="패스_125"
                  data-name="패스 125"
                  d="M405.886,223.79c1.284-.732,1.288-1.934.008-2.673l-68.1-39.455a5.065,5.065,0,0,0-4.634.033l-6.245,3.726a1.446,1.446,0,0,0,.019,2.72l67.842,39.306a5.2,5.2,0,0,0,4.662.014Z"
                  fill="#c9c9d3"
                  opacity="0.293"
                />
                <path
                  id="패스_126"
                  data-name="패스 126"
                  d="M405.811,223.754c1.279-.728,1.283-1.926.008-2.662l-67.991-39.4a5.046,5.046,0,0,0-4.616.033l-6.221,3.711a1.44,1.44,0,0,0,.02,2.709L394.746,227.4a5.183,5.183,0,0,0,4.643.014Z"
                  fill="#c5c5d0"
                  opacity="0.317"
                />
                <path
                  id="패스_127"
                  data-name="패스 127"
                  d="M405.736,223.719c1.274-.725,1.277-1.919.008-2.652l-67.883-39.353a5.027,5.027,0,0,0-4.6.033l-6.2,3.7a1.434,1.434,0,0,0,.02,2.7l67.628,39.206a5.162,5.162,0,0,0,4.624.014Z"
                  fill="#c1c1cc"
                  opacity="0.341"
                />
                <path
                  id="패스_128"
                  data-name="패스 128"
                  d="M405.661,223.684a1.394,1.394,0,0,0,.008-2.642l-67.775-39.3a5.008,5.008,0,0,0-4.58.033l-6.173,3.682a1.428,1.428,0,0,0,.02,2.688L394.682,227.3a5.136,5.136,0,0,0,4.606.014Z"
                  fill="#bcbcc8"
                  opacity="0.366"
                />
                <path
                  id="패스_129"
                  data-name="패스 129"
                  d="M405.586,223.649a1.388,1.388,0,0,0,.007-2.632l-67.667-39.251a4.987,4.987,0,0,0-4.561.033l-6.148,3.668a1.423,1.423,0,0,0,.019,2.677l67.414,39.1a5.12,5.12,0,0,0,4.588.014Z"
                  fill="#b8b8c5"
                  opacity="0.39"
                />
                <path
                  id="패스_130"
                  data-name="패스 130"
                  d="M405.511,223.613a1.382,1.382,0,0,0,.007-2.62l-67.559-39.2a4.968,4.968,0,0,0-4.543.033l-6.124,3.653a1.417,1.417,0,0,0,.019,2.667L394.618,227.2a5.1,5.1,0,0,0,4.57.014Z"
                  fill="#b3b3c1"
                  opacity="0.415"
                />
                <path
                  id="패스_131"
                  data-name="패스 131"
                  d="M405.435,223.578a1.376,1.376,0,0,0,.008-2.61l-67.451-39.15a4.946,4.946,0,0,0-4.525.033l-6.1,3.638a1.412,1.412,0,0,0,.019,2.657l67.2,39a5.079,5.079,0,0,0,4.552.014Z"
                  fill="#afafbe"
                  opacity="0.439"
                />
                <path
                  id="패스_132"
                  data-name="패스 132"
                  d="M405.36,223.543a1.371,1.371,0,0,0,.008-2.6l-67.343-39.1a4.929,4.929,0,0,0-4.508.033l-6.075,3.624a1.406,1.406,0,0,0,.019,2.646L394.554,227.1a5.063,5.063,0,0,0,4.534.013Z"
                  fill="#aaaaba"
                  opacity="0.463"
                />
                <path
                  id="패스_133"
                  data-name="패스 133"
                  d="M405.285,223.507a1.365,1.365,0,0,0,.008-2.589l-67.235-39.047a4.907,4.907,0,0,0-4.49.032l-6.051,3.609a1.4,1.4,0,0,0,.019,2.635l66.986,38.9a5.041,5.041,0,0,0,4.516.014Z"
                  fill="#a6a6b6"
                  opacity="0.488"
                />
                <path
                  id="패스_134"
                  data-name="패스 134"
                  d="M405.21,223.472a1.36,1.36,0,0,0,.008-2.579l-67.127-39a4.89,4.89,0,0,0-4.472.031l-6.026,3.6a1.394,1.394,0,0,0,.018,2.624L394.49,227a5.019,5.019,0,0,0,4.5.013Z"
                  fill="#a1a1b3"
                  opacity="0.512"
                />
                <path
                  id="패스_135"
                  data-name="패스 135"
                  d="M405.135,223.437a1.355,1.355,0,0,0,.008-2.569l-67.019-38.945a4.868,4.868,0,0,0-4.454.031l-6,3.581a1.389,1.389,0,0,0,.018,2.614l66.772,38.8a5,5,0,0,0,4.48.014Z"
                  fill="#9d9daf"
                  opacity="0.537"
                />
                <path
                  id="패스_136"
                  data-name="패스 136"
                  d="M405.06,223.4a1.35,1.35,0,0,0,.008-2.559l-66.911-38.894a4.849,4.849,0,0,0-4.436.031l-5.978,3.567a1.383,1.383,0,0,0,.019,2.6L394.426,226.9a4.981,4.981,0,0,0,4.462.013Z"
                  fill="#9898ab"
                  opacity="0.561"
                />
                <path
                  id="패스_137"
                  data-name="패스 137"
                  d="M404.985,223.366a1.344,1.344,0,0,0,.007-2.548l-66.8-38.843a4.827,4.827,0,0,0-4.418.031l-5.954,3.552a1.378,1.378,0,0,0,.019,2.593l66.557,38.7a4.958,4.958,0,0,0,4.444.014Z"
                  fill="#9494a8"
                  opacity="0.585"
                />
                <path
                  id="패스_138"
                  data-name="패스 138"
                  d="M404.91,223.331a1.338,1.338,0,0,0,.007-2.537L338.223,182a4.808,4.808,0,0,0-4.4.031l-5.93,3.538a1.372,1.372,0,0,0,.019,2.582l66.45,38.652a4.942,4.942,0,0,0,4.426.013Z"
                  fill="#8f8fa4"
                  opacity="0.61"
                />
                <path
                  id="패스_139"
                  data-name="패스 139"
                  d="M404.835,223.3a1.333,1.333,0,0,0,.007-2.527l-66.586-38.742a4.789,4.789,0,0,0-4.382.031l-5.906,3.523a1.367,1.367,0,0,0,.019,2.572l66.343,38.6a4.917,4.917,0,0,0,4.408.013Z"
                  fill="#8b8ba1"
                  opacity="0.634"
                />
                <path
                  id="패스_140"
                  data-name="패스 140"
                  d="M404.759,223.261a1.327,1.327,0,0,0,.008-2.517l-66.478-38.691a4.77,4.77,0,0,0-4.364.031l-5.881,3.508a1.362,1.362,0,0,0,.018,2.562L394.3,226.705a4.9,4.9,0,0,0,4.39.013Z"
                  fill="#86869d"
                  opacity="0.659"
                />
                <path
                  id="패스_141"
                  data-name="패스 141"
                  d="M404.685,223.225a1.322,1.322,0,0,0,.007-2.506l-66.371-38.64a4.749,4.749,0,0,0-4.345.031l-5.857,3.494a1.355,1.355,0,0,0,.018,2.55l66.129,38.5a4.879,4.879,0,0,0,4.372.013Z"
                  fill="#828299"
                  opacity="0.683"
                />
                <path
                  id="패스_142"
                  data-name="패스 142"
                  d="M404.609,223.19a1.316,1.316,0,0,0,.008-2.5L338.354,182.1a4.729,4.729,0,0,0-4.327.031l-5.833,3.479a1.35,1.35,0,0,0,.018,2.54l66.022,38.451a4.86,4.86,0,0,0,4.354.013Z"
                  fill="#7e7e96"
                  opacity="0.707"
                />
                <path
                  id="패스_143"
                  data-name="패스 143"
                  d="M404.534,223.155a1.311,1.311,0,0,0,.008-2.486l-66.155-38.538a4.71,4.71,0,0,0-4.309.031l-5.809,3.465a1.344,1.344,0,0,0,.018,2.529l65.915,38.4a4.836,4.836,0,0,0,4.335.013Z"
                  fill="#797992"
                  opacity="0.732"
                />
                <path
                  id="패스_144"
                  data-name="패스 144"
                  d="M404.459,223.12a1.306,1.306,0,0,0,.008-2.476L338.42,182.157a4.688,4.688,0,0,0-4.291.031l-5.785,3.45a1.339,1.339,0,0,0,.018,2.519l65.808,38.35a4.822,4.822,0,0,0,4.318.013Z"
                  fill="#75758e"
                  opacity="0.756"
                />
                <path
                  id="패스_145"
                  data-name="패스 145"
                  d="M404.384,223.084a1.3,1.3,0,0,0,.007-2.465l-65.938-38.436a4.671,4.671,0,0,0-4.274.03l-5.76,3.436a1.334,1.334,0,0,0,.018,2.509l65.7,38.3a4.794,4.794,0,0,0,4.3.013Z"
                  fill="#70708b"
                  opacity="0.78"
                />
                <path
                  id="패스_146"
                  data-name="패스 146"
                  d="M404.309,223.049a1.3,1.3,0,0,0,.007-2.455l-65.83-38.385a4.652,4.652,0,0,0-4.256.03l-5.735,3.422a1.327,1.327,0,0,0,.018,2.5l65.593,38.249a4.782,4.782,0,0,0,4.281.013Z"
                  fill="#6c6c87"
                  opacity="0.805"
                />
                <path
                  id="패스_147"
                  data-name="패스 147"
                  d="M404.234,223.014a1.289,1.289,0,0,0,.007-2.444l-65.722-38.335a4.633,4.633,0,0,0-4.238.03l-5.711,3.407a1.321,1.321,0,0,0,.018,2.487l65.486,38.2a4.756,4.756,0,0,0,4.263.013Z"
                  fill="#676783"
                  opacity="0.829"
                />
                <path
                  id="패스_148"
                  data-name="패스 148"
                  d="M404.159,222.978a1.283,1.283,0,0,0,.007-2.433l-65.614-38.284a4.614,4.614,0,0,0-4.22.03l-5.687,3.393a1.316,1.316,0,0,0,.018,2.476l65.38,38.149a4.738,4.738,0,0,0,4.244.013Z"
                  fill="#636380"
                  opacity="0.854"
                />
                <path
                  id="패스_149"
                  data-name="패스 149"
                  d="M404.084,222.943a1.278,1.278,0,0,0,.007-2.423l-65.506-38.233a4.592,4.592,0,0,0-4.2.03L328.72,185.7a1.311,1.311,0,0,0,.018,2.466l65.273,38.1a4.719,4.719,0,0,0,4.226.012Z"
                  fill="#5e5e7c"
                  opacity="0.878"
                />
                <path
                  id="패스_150"
                  data-name="패스 150"
                  d="M404.009,222.908a1.273,1.273,0,0,0,.007-2.413l-65.4-38.182a4.573,4.573,0,0,0-4.184.03l-5.639,3.364a1.3,1.3,0,0,0,.018,2.455l65.166,38.048a4.7,4.7,0,0,0,4.208.013Z"
                  fill="#5a5a79"
                  opacity="0.902"
                />
                <path
                  id="패스_151"
                  data-name="패스 151"
                  d="M403.933,222.873a1.267,1.267,0,0,0,.008-2.4l-65.29-38.131a4.554,4.554,0,0,0-4.166.03l-5.614,3.349a1.3,1.3,0,0,0,.017,2.445l65.059,38a4.678,4.678,0,0,0,4.19.012Z"
                  fill="#555575"
                  opacity="0.927"
                />
                <path
                  id="패스_152"
                  data-name="패스 152"
                  d="M403.858,222.837a1.261,1.261,0,0,0,.007-2.392l-65.181-38.08a4.534,4.534,0,0,0-4.148.03l-5.59,3.334a1.294,1.294,0,0,0,.017,2.435l64.952,37.947a4.655,4.655,0,0,0,4.172.012Z"
                  fill="#515171"
                  opacity="0.951"
                />
                <path
                  id="패스_153"
                  data-name="패스 153"
                  d="M403.783,222.8a1.256,1.256,0,0,0,.007-2.382l-65.073-38.029a4.518,4.518,0,0,0-4.13.029l-5.566,3.321a1.288,1.288,0,0,0,.017,2.424l64.845,37.9a4.636,4.636,0,0,0,4.154.012Z"
                  fill="#4c4c6e"
                  opacity="0.976"
                />
                <path
                  id="패스_154"
                  data-name="패스 154"
                  d="M403.708,222.767a1.25,1.25,0,0,0,.007-2.371l-64.966-37.979a4.5,4.5,0,0,0-4.111.029l-5.542,3.306a1.283,1.283,0,0,0,.017,2.414l64.738,37.846a4.617,4.617,0,0,0,4.136.013Z"
                  fill="#48486a"
                />
              </g>
              <path
                id="패스_155"
                data-name="패스 155"
                d="M405.68,222.765c1.3-.739,1.3-1.956.009-2.7L338.6,181.325a5.126,5.126,0,0,0-4.688.033l-6.32,3.77a1.462,1.462,0,0,0,.02,2.752l66.833,38.586a5.261,5.261,0,0,0,4.716.014Z"
                fill="#ffc107"
              />
              <path
                id="패스_156"
                data-name="패스 156"
                d="M405.68,222.086c1.3-.74,1.3-1.957.009-2.705L338.6,180.645a5.126,5.126,0,0,0-4.688.034l-6.32,3.77a1.462,1.462,0,0,0,.02,2.752l66.833,38.586a5.268,5.268,0,0,0,4.716.014Z"
                fill="url(#linear-gradient-3)"
              />
              <path
                id="패스_157"
                data-name="패스 157"
                d="M403.323,218.1l-4.289,2.491-.958-.553,4.253-2.505Z"
                fill="#48486a"
              />
              <path
                id="패스_158"
                data-name="패스 158"
                d="M398.1,215.072l-2.91,1.68-.958-.553,2.873-1.695Z"
                fill="#48486a"
              />
              <path
                id="패스_159"
                data-name="패스 159"
                d="M392.877,212.048l-2.91,1.68-.958-.553,2.873-1.695Z"
                fill="#48486a"
              />
              <path
                id="패스_160"
                data-name="패스 160"
                d="M387.654,209.024l-2.91,1.68-.958-.553,2.874-1.695Z"
                fill="#48486a"
              />
              <path
                id="패스_161"
                data-name="패스 161"
                d="M382.43,206l-4.289,2.491-.958-.553,4.253-2.506Z"
                fill="#48486a"
              />
              <path
                id="패스_162"
                data-name="패스 162"
                d="M377.207,202.976l-2.91,1.68-.958-.553,2.873-1.695Z"
                fill="#48486a"
              />
              <path
                id="패스_163"
                data-name="패스 163"
                d="M371.984,199.952l-2.91,1.68-.958-.553,2.873-1.695Z"
                fill="#48486a"
              />
              <path
                id="패스_164"
                data-name="패스 164"
                d="M366.761,196.928l-2.91,1.68-.958-.553,2.873-1.7Z"
                fill="#48486a"
              />
              <path
                id="패스_165"
                data-name="패스 165"
                d="M361.538,193.9l-4.289,2.491-.958-.553,4.253-2.506Z"
                fill="#48486a"
              />
              <path
                id="패스_166"
                data-name="패스 166"
                d="M356.314,190.88l-2.91,1.68-.958-.553,2.874-1.7Z"
                fill="#48486a"
              />
              <path
                id="패스_167"
                data-name="패스 167"
                d="M351.091,187.856l-2.91,1.68-.958-.553,2.873-1.695Z"
                fill="#48486a"
              />
              <path
                id="패스_168"
                data-name="패스 168"
                d="M345.868,184.832l-2.91,1.68L342,185.96l2.873-1.7Z"
                fill="#48486a"
              />
              <path
                id="패스_169"
                data-name="패스 169"
                d="M340.645,181.808l-4.289,2.491-.958-.553,4.253-2.506Z"
                fill="#48486a"
              />
            </g>
            <g id="그룹_11" data-name="그룹 11">
              <g id="그룹_10" data-name="그룹 10">
                <path
                  id="패스_170"
                  data-name="패스 170"
                  d="M433.948,237.34a4.287,4.287,0,0,0-3.863,0l-55.634,32.12a1.179,1.179,0,0,0,0,2.231l35.958,20.76a4.275,4.275,0,0,0,3.86,0l55.484-32.2a1.182,1.182,0,0,0,0-2.235Z"
                  fill="#fff"
                  opacity="0"
                />
                <path
                  id="패스_171"
                  data-name="패스 171"
                  d="M433.894,237.407a4.262,4.262,0,0,0-3.845,0l-55.381,31.974a1.174,1.174,0,0,0,0,2.221l35.794,20.665a4.251,4.251,0,0,0,3.843,0l55.231-32.055a1.177,1.177,0,0,0,0-2.225Z"
                  fill="#fbfbfb"
                  opacity="0.024"
                />
                <path
                  id="패스_172"
                  data-name="패스 172"
                  d="M433.841,237.474a4.245,4.245,0,0,0-3.828,0L374.884,269.3a1.168,1.168,0,0,0,0,2.21l35.631,20.572a4.229,4.229,0,0,0,3.825-.005l54.979-31.908a1.172,1.172,0,0,0,0-2.215Z"
                  fill="#f6f6f8"
                  opacity="0.049"
                />
                <path
                  id="패스_173"
                  data-name="패스 173"
                  d="M433.787,237.541a4.228,4.228,0,0,0-3.811,0L375.1,269.223a1.163,1.163,0,0,0,0,2.2L410.568,291.9a4.215,4.215,0,0,0,3.808,0L469.1,260.133a1.166,1.166,0,0,0,0-2.2Z"
                  fill="#f2f2f4"
                  opacity="0.073"
                />
                <path
                  id="패스_174"
                  data-name="패스 174"
                  d="M433.733,237.608a4.209,4.209,0,0,0-3.793,0l-54.622,31.536a1.158,1.158,0,0,0,0,2.19l35.3,20.383a4.2,4.2,0,0,0,3.791-.005L468.886,260.1a1.161,1.161,0,0,0,0-2.194Z"
                  fill="#ededf0"
                  opacity="0.098"
                />
                <path
                  id="패스_175"
                  data-name="패스 175"
                  d="M433.679,237.675a4.19,4.19,0,0,0-3.775,0l-54.37,31.39a1.152,1.152,0,0,0,0,2.18l35.141,20.288a4.177,4.177,0,0,0,3.772,0l54.222-31.47a1.155,1.155,0,0,0,0-2.184Z"
                  fill="#e9e9ed"
                  opacity="0.122"
                />
                <path
                  id="패스_176"
                  data-name="패스 176"
                  d="M433.625,237.742a4.164,4.164,0,0,0-3.757,0l-54.117,31.244a1.147,1.147,0,0,0,0,2.17l34.977,20.194a4.157,4.157,0,0,0,3.755-.005l53.97-31.323a1.15,1.15,0,0,0,0-2.174Z"
                  fill="#e4e4e9"
                  opacity="0.146"
                />
                <path
                  id="패스_177"
                  data-name="패스 177"
                  d="M433.571,237.809a4.147,4.147,0,0,0-3.74,0l-53.863,31.1a1.142,1.142,0,0,0,0,2.16l34.813,20.1a4.135,4.135,0,0,0,3.737,0l53.718-31.177a1.145,1.145,0,0,0,0-2.164Z"
                  fill="#e0e0e6"
                  opacity="0.171"
                />
                <path
                  id="패스_178"
                  data-name="패스 178"
                  d="M433.517,237.876a4.128,4.128,0,0,0-3.722,0l-53.611,30.952a1.136,1.136,0,0,0,0,2.149l34.65,20.005a4.118,4.118,0,0,0,3.72,0l53.466-31.03a1.14,1.14,0,0,0,0-2.154Z"
                  fill="#dbdbe2"
                  opacity="0.195"
                />
                <path
                  id="패스_179"
                  data-name="패스 179"
                  d="M433.464,237.943a4.105,4.105,0,0,0-3.7,0L376.4,268.749a1.13,1.13,0,0,0,0,2.139L410.887,290.8a4.1,4.1,0,0,0,3.7,0L467.8,259.911a1.134,1.134,0,0,0,0-2.144Z"
                  fill="#d7d7de"
                  opacity="0.22"
                />
                <path
                  id="패스_180"
                  data-name="패스 180"
                  d="M433.41,238.01a4.088,4.088,0,0,0-3.688,0l-53.1,30.66a1.125,1.125,0,0,0,0,2.129l34.323,19.816a4.076,4.076,0,0,0,3.685,0l52.961-30.738a1.128,1.128,0,0,0,0-2.133Z"
                  fill="#d2d2db"
                  opacity="0.244"
                />
                <path
                  id="패스_181"
                  data-name="패스 181"
                  d="M433.356,238.077a4.069,4.069,0,0,0-3.67,0l-52.852,30.514a1.12,1.12,0,0,0,0,2.119l34.16,19.722a4.06,4.06,0,0,0,3.667-.005l52.709-30.591a1.123,1.123,0,0,0,0-2.123Z"
                  fill="#ceced7"
                  opacity="0.268"
                />
                <path
                  id="패스_182"
                  data-name="패스 182"
                  d="M433.3,238.144a4.05,4.05,0,0,0-3.652,0l-52.6,30.368a1.115,1.115,0,0,0,0,2.109l34,19.627a4.037,4.037,0,0,0,3.649,0L467.153,259.8a1.118,1.118,0,0,0,0-2.113Z"
                  fill="#c9c9d3"
                  opacity="0.293"
                />
                <path
                  id="패스_183"
                  data-name="패스 183"
                  d="M433.248,238.211a4.027,4.027,0,0,0-3.635,0l-52.346,30.222a1.109,1.109,0,0,0,0,2.1L411.1,290.065a4.017,4.017,0,0,0,3.632-.005l52.2-30.3a1.112,1.112,0,0,0,0-2.1Z"
                  fill="#c5c5d0"
                  opacity="0.317"
                />
                <path
                  id="패스_184"
                  data-name="패스 184"
                  d="M433.194,238.278a4.008,4.008,0,0,0-3.617,0l-52.093,30.076a1.1,1.1,0,0,0,0,2.088l33.669,19.439a4,4,0,0,0,3.615,0l51.952-30.152a1.107,1.107,0,0,0,0-2.093Z"
                  fill="#c1c1cc"
                  opacity="0.341"
                />
                <path
                  id="패스_185"
                  data-name="패스 185"
                  d="M433.14,238.345a3.989,3.989,0,0,0-3.6,0l-51.84,29.93a1.1,1.1,0,0,0,0,2.078L411.206,289.7a3.978,3.978,0,0,0,3.6,0l51.7-30.005a1.1,1.1,0,0,0,0-2.083Z"
                  fill="#bcbcc8"
                  opacity="0.366"
                />
                <path
                  id="패스_186"
                  data-name="패스 186"
                  d="M433.087,238.412a3.974,3.974,0,0,0-3.583,0L377.917,268.2a1.094,1.094,0,0,0,0,2.069l33.342,19.25a3.961,3.961,0,0,0,3.58,0l51.447-29.86a1.1,1.1,0,0,0,0-2.072Z"
                  fill="#b8b8c5"
                  opacity="0.39"
                />
                <path
                  id="패스_187"
                  data-name="패스 187"
                  d="M433.033,238.479a3.955,3.955,0,0,0-3.565,0l-51.334,29.638a1.088,1.088,0,0,0,0,2.058l33.178,19.155a3.942,3.942,0,0,0,3.562,0l51.2-29.713a1.091,1.091,0,0,0,0-2.062Z"
                  fill="#b3b3c1"
                  opacity="0.415"
                />
                <path
                  id="패스_188"
                  data-name="패스 188"
                  d="M432.979,238.546a3.929,3.929,0,0,0-3.547,0L378.35,268.037a1.083,1.083,0,0,0,0,2.048l33.016,19.062a3.92,3.92,0,0,0,3.544-.005l50.943-29.566a1.086,1.086,0,0,0,0-2.052Z"
                  fill="#afafbe"
                  opacity="0.439"
                />
                <path
                  id="패스_189"
                  data-name="패스 189"
                  d="M432.925,238.613a3.91,3.91,0,0,0-3.529,0l-50.829,29.345a1.077,1.077,0,0,0,0,2.038l32.852,18.967a3.9,3.9,0,0,0,3.527,0l50.691-29.42a1.08,1.08,0,0,0,0-2.042Z"
                  fill="#aaaaba"
                  opacity="0.463"
                />
                <path
                  id="패스_190"
                  data-name="패스 190"
                  d="M432.871,238.68a3.893,3.893,0,0,0-3.512,0l-50.575,29.2a1.072,1.072,0,0,0,0,2.028l32.688,18.872a3.881,3.881,0,0,0,3.509,0L465.42,259.5a1.075,1.075,0,0,0,0-2.032Z"
                  fill="#a6a6b6"
                  opacity="0.488"
                />
                <path
                  id="패스_191"
                  data-name="패스 191"
                  d="M432.817,238.746a3.88,3.88,0,0,0-3.494,0L379,267.8a1.067,1.067,0,0,0,0,2.018L411.525,288.6a3.864,3.864,0,0,0,3.492,0L465.2,259.465a1.07,1.07,0,0,0,0-2.022Z"
                  fill="#a1a1b3"
                  opacity="0.512"
                />
                <path
                  id="패스_192"
                  data-name="패스 192"
                  d="M432.763,238.814a3.855,3.855,0,0,0-3.476,0l-50.07,28.907a1.061,1.061,0,0,0,0,2.008l32.361,18.683a3.845,3.845,0,0,0,3.474,0l49.935-28.981a1.064,1.064,0,0,0,0-2.011Z"
                  fill="#9d9daf"
                  opacity="0.537"
                />
                <path
                  id="패스_193"
                  data-name="패스 193"
                  d="M432.71,238.88a3.84,3.84,0,0,0-3.46,0l-49.816,28.762a1.055,1.055,0,0,0,0,2l32.2,18.59a3.828,3.828,0,0,0,3.457,0L464.77,259.39a1.059,1.059,0,0,0,0-2Z"
                  fill="#9898ab"
                  opacity="0.561"
                />
                <path
                  id="패스_194"
                  data-name="패스 194"
                  d="M432.656,238.947a3.821,3.821,0,0,0-3.442,0L379.65,267.563a1.05,1.05,0,0,0,0,1.987l32.035,18.5a3.8,3.8,0,0,0,3.439,0l49.429-28.688a1.053,1.053,0,0,0,0-1.991Z"
                  fill="#9494a8"
                  opacity="0.585"
                />
                <path
                  id="패스_195"
                  data-name="패스 195"
                  d="M432.6,239.014a3.8,3.8,0,0,0-3.424,0l-49.311,28.47a1.045,1.045,0,0,0,0,1.977l31.871,18.4a3.789,3.789,0,0,0,3.421,0l49.178-28.542a1.048,1.048,0,0,0,0-1.981Z"
                  fill="#8f8fa4"
                  opacity="0.61"
                />
                <path
                  id="패스_196"
                  data-name="패스 196"
                  d="M432.548,239.082a3.771,3.771,0,0,0-3.406,0L380.083,267.4a1.04,1.04,0,0,0,0,1.967l31.708,18.306a3.766,3.766,0,0,0,3.4,0l48.925-28.4a1.043,1.043,0,0,0,0-1.971Z"
                  fill="#8b8ba1"
                  opacity="0.634"
                />
                <path
                  id="패스_197"
                  data-name="패스 197"
                  d="M432.494,239.148a3.76,3.76,0,0,0-3.389,0L380.3,267.326a1.034,1.034,0,0,0,0,1.957l31.544,18.211a3.744,3.744,0,0,0,3.386,0L463.9,259.242a1.037,1.037,0,0,0,0-1.961Z"
                  fill="#86869d"
                  opacity="0.659"
                />
                <path
                  id="패스_198"
                  data-name="패스 198"
                  d="M432.44,239.215a3.741,3.741,0,0,0-3.371,0l-48.552,28.032a1.028,1.028,0,0,0,0,1.946l31.38,18.118a3.73,3.73,0,0,0,3.369,0l48.421-28.1a1.032,1.032,0,0,0,0-1.951Z"
                  fill="#828299"
                  opacity="0.683"
                />
                <path
                  id="패스_199"
                  data-name="패스 199"
                  d="M432.386,239.282a3.722,3.722,0,0,0-3.353,0l-48.3,27.886a1.023,1.023,0,0,0,0,1.936l31.217,18.023a3.707,3.707,0,0,0,3.352,0l48.168-27.956a1.026,1.026,0,0,0,0-1.94Z"
                  fill="#7e7e96"
                  opacity="0.707"
                />
                <path
                  id="패스_200"
                  data-name="패스 200"
                  d="M432.332,239.349a3.7,3.7,0,0,0-3.336,0l-48.046,27.74a1.018,1.018,0,0,0,0,1.926L412,286.944a3.686,3.686,0,0,0,3.333,0l47.917-27.81a1.021,1.021,0,0,0,0-1.93Z"
                  fill="#797992"
                  opacity="0.732"
                />
                <path
                  id="패스_201"
                  data-name="패스 201"
                  d="M432.279,239.416a3.681,3.681,0,0,0-3.319,0L381.167,267.01a1.013,1.013,0,0,0,0,1.916l30.89,17.834a3.669,3.669,0,0,0,3.316,0l47.664-27.663a1.016,1.016,0,0,0,0-1.92Z"
                  fill="#75758e"
                  opacity="0.756"
                />
                <path
                  id="패스_202"
                  data-name="패스 202"
                  d="M432.225,239.483a3.662,3.662,0,0,0-3.3,0l-47.541,27.448a1.007,1.007,0,0,0,0,1.906l30.727,17.74a3.652,3.652,0,0,0,3.3,0l47.411-27.517a1.01,1.01,0,0,0,0-1.91Z"
                  fill="#70708b"
                  opacity="0.78"
                />
                <path
                  id="패스_203"
                  data-name="패스 203"
                  d="M432.171,239.55a3.645,3.645,0,0,0-3.284,0l-47.287,27.3a1,1,0,0,0,0,1.9l30.563,17.646a3.63,3.63,0,0,0,3.281,0l47.16-27.37a1,1,0,0,0,0-1.9Z"
                  fill="#6c6c87"
                  opacity="0.805"
                />
                <path
                  id="패스_204"
                  data-name="패스 204"
                  d="M432.117,239.617a3.626,3.626,0,0,0-3.266,0l-47.034,27.156a1,1,0,0,0,0,1.885l30.4,17.551a3.613,3.613,0,0,0,3.264,0l46.907-27.224a1,1,0,0,0,0-1.89Z"
                  fill="#676783"
                  opacity="0.829"
                />
                <path
                  id="패스_205"
                  data-name="패스 205"
                  d="M432.063,239.684a3.6,3.6,0,0,0-3.248,0l-46.782,27.009a.992.992,0,0,0,0,1.876l30.236,17.457a3.591,3.591,0,0,0,3.246,0l46.655-27.077a1,1,0,0,0,0-1.88Z"
                  fill="#636380"
                  opacity="0.854"
                />
                <path
                  id="패스_206"
                  data-name="패스 206"
                  d="M432.009,239.751a3.582,3.582,0,0,0-3.23,0L382.25,266.614a.986.986,0,0,0,0,1.866l30.072,17.362a3.574,3.574,0,0,0,3.229,0l46.4-26.932a.989.989,0,0,0,0-1.869Z"
                  fill="#5e5e7c"
                  opacity="0.878"
                />
                <path
                  id="패스_207"
                  data-name="패스 207"
                  d="M431.956,239.818a3.567,3.567,0,0,0-3.214,0l-46.276,26.717a.981.981,0,0,0,0,1.856l29.909,17.268a3.557,3.557,0,0,0,3.212,0l46.15-26.785a.983.983,0,0,0,0-1.859Z"
                  fill="#5a5a79"
                  opacity="0.902"
                />
                <path
                  id="패스_208"
                  data-name="패스 208"
                  d="M431.9,239.885a3.548,3.548,0,0,0-3.2,0l-46.023,26.571a.975.975,0,0,0,0,1.845l29.746,17.174a3.529,3.529,0,0,0,3.193,0l45.9-26.638a.978.978,0,0,0,0-1.849Z"
                  fill="#555575"
                  opacity="0.927"
                />
                <path
                  id="패스_209"
                  data-name="패스 209"
                  d="M431.848,239.952a3.529,3.529,0,0,0-3.178,0L382.9,266.377a.97.97,0,0,0,0,1.835l29.582,17.079a3.512,3.512,0,0,0,3.176,0L461.3,258.8a.973.973,0,0,0,0-1.839Z"
                  fill="#515171"
                  opacity="0.951"
                />
                <path
                  id="패스_210"
                  data-name="패스 210"
                  d="M431.794,240.019a3.506,3.506,0,0,0-3.161,0L383.116,266.3a.965.965,0,0,0,0,1.825l29.419,16.985a3.493,3.493,0,0,0,3.158,0l45.394-26.345a.968.968,0,0,0,0-1.829Z"
                  fill="#4c4c6e"
                  opacity="0.976"
                />
                <path
                  id="패스_211"
                  data-name="패스 211"
                  d="M431.74,240.086a3.487,3.487,0,0,0-3.143,0l-45.264,26.133a.959.959,0,0,0,0,1.815l29.255,16.89a3.476,3.476,0,0,0,3.141,0l45.141-26.2a.962.962,0,0,0,0-1.819Z"
                  fill="#48486a"
                />
              </g>
              <path
                id="패스_212"
                data-name="패스 212"
                d="M432.363,236.817a4.077,4.077,0,0,0-3.674,0L375.359,266.8a.654.654,0,0,0-.333.57v1.012a1.189,1.189,0,0,0,.6,1.03l34.357,19.813a4.062,4.062,0,0,0,3.671,0L466.28,258.7a1.765,1.765,0,0,0,.881-1.539l0-.774Z"
                fill="url(#linear-gradient-4)"
              />
              <path
                id="패스_213"
                data-name="패스 213"
                d="M432.363,235.545a4.078,4.078,0,0,0-3.674,0l-52.9,30.545a1.121,1.121,0,0,0,0,2.121l34.194,19.742a4.065,4.065,0,0,0,3.671,0l52.762-30.623a1.124,1.124,0,0,0,0-2.125Z"
                fill="#fff"
              />
              <path
                id="패스_214"
                data-name="패스 214"
                d="M427.626,240.012l29.65,17.118-15.1,8.539-29.509-17.061Z"
                fill="#d3daed"
              />
              <path
                id="패스_215"
                data-name="패스 215"
                d="M411.171,249.949l13.47,7.851-16.872,9.537-13.313-7.787Z"
                fill="#e5e9f4"
              />
              <path
                id="패스_216"
                data-name="패스 216"
                d="M427.039,259.222l13.47,7.851-16.872,9.537-13.313-7.787Z"
                fill="#edf0f8"
              />
              <path
                id="패스_217"
                data-name="패스 217"
                d="M388.791,262.342,421,280.843l-1.622.936L387.223,263.3Z"
                fill="#dce1f1"
              />
              <path
                id="패스_218"
                data-name="패스 218"
                d="M391.866,268.468l16.943,9.718-1.622.936-16.889-9.7Z"
                fill="#dce1f1"
              />
            </g>
            <g id="그룹_14" data-name="그룹 14">
              <g
                id="그룹_12"
                data-name="그룹 12"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              >
                <path
                  id="패스_219"
                  data-name="패스 219"
                  d="M191.227,244.423V243.8l33.249-19.53a1.987,1.987,0,0,1,1.983-.22l17.456,10.022a.957.957,0,0,1,.385.412l.1.2a1.613,1.613,0,0,1-.644,2.1l-32.636,18.836a2.521,2.521,0,0,1-2.27,0l-16.7-9.615A1.827,1.827,0,0,1,191.227,244.423Z"
                  fill="#fff"
                  opacity="0"
                />
                <path
                  id="패스_220"
                  data-name="패스 220"
                  d="M191.3,244.375v-.621l33.135-19.463a1.979,1.979,0,0,1,1.976-.22l17.4,9.988a.956.956,0,0,1,.384.41l.1.2a1.609,1.609,0,0,1-.643,2.1l-32.524,18.77a2.51,2.51,0,0,1-2.262,0l-16.646-9.581A1.821,1.821,0,0,1,191.3,244.375Z"
                  fill="#fbfbfb"
                  opacity="0.024"
                />
                <path
                  id="패스_221"
                  data-name="패스 221"
                  d="M191.381,244.327v-.619l33.021-19.4a1.97,1.97,0,0,1,1.969-.218l17.336,9.952a.946.946,0,0,1,.382.41l.1.2a1.6,1.6,0,0,1-.64,2.088l-32.412,18.706a2.5,2.5,0,0,1-2.254,0L192.291,245.9A1.816,1.816,0,0,1,191.381,244.327Z"
                  fill="#f6f6f8"
                  opacity="0.049"
                />
                <path
                  id="패스_222"
                  data-name="패스 222"
                  d="M191.458,244.279v-.617l32.907-19.329a1.964,1.964,0,0,1,1.962-.217l17.276,9.918a.95.95,0,0,1,.381.408l.1.2a1.6,1.6,0,0,1-.638,2.081l-32.3,18.641a2.491,2.491,0,0,1-2.247,0l-16.531-9.515A1.81,1.81,0,0,1,191.458,244.279Z"
                  fill="#f2f2f4"
                  opacity="0.073"
                />
                <path
                  id="패스_223"
                  data-name="패스 223"
                  d="M191.535,244.231v-.614l32.792-19.262a1.956,1.956,0,0,1,1.956-.217l17.216,9.884a.943.943,0,0,1,.38.406l.1.2a1.592,1.592,0,0,1-.636,2.074l-32.188,18.576a2.485,2.485,0,0,1-2.238,0l-16.474-9.482A1.8,1.8,0,0,1,191.535,244.231Z"
                  fill="#ededf0"
                  opacity="0.098"
                />
                <path
                  id="패스_224"
                  data-name="패스 224"
                  d="M191.612,244.183v-.612l32.678-19.195a1.952,1.952,0,0,1,1.949-.216l17.156,9.849a.941.941,0,0,1,.378.4l.1.2a1.585,1.585,0,0,1-.634,2.066L211.16,255.19a2.476,2.476,0,0,1-2.231,0l-16.417-9.449A1.8,1.8,0,0,1,191.612,244.183Z"
                  fill="#e9e9ed"
                  opacity="0.122"
                />
                <path
                  id="패스_225"
                  data-name="패스 225"
                  d="M191.689,244.136v-.611L224.253,224.4a1.944,1.944,0,0,1,1.942-.215l17.1,9.815a.937.937,0,0,1,.377.4l.1.2a1.579,1.579,0,0,1-.631,2.059L211.169,255.1a2.466,2.466,0,0,1-2.223,0l-16.36-9.416A1.789,1.789,0,0,1,191.689,244.136Z"
                  fill="#e4e4e9"
                  opacity="0.146"
                />
                <path
                  id="패스_226"
                  data-name="패스 226"
                  d="M191.766,244.088v-.609l32.45-19.06a1.936,1.936,0,0,1,1.935-.215l17.036,9.781a.93.93,0,0,1,.376.4l.1.2a1.575,1.575,0,0,1-.63,2.052l-31.851,18.382a2.455,2.455,0,0,1-2.215,0l-16.3-9.383A1.784,1.784,0,0,1,191.766,244.088Z"
                  fill="#e0e0e6"
                  opacity="0.171"
                />
                <path
                  id="패스_227"
                  data-name="패스 227"
                  d="M191.843,244.04v-.607l32.336-18.993a1.932,1.932,0,0,1,1.928-.214l16.976,9.746a.934.934,0,0,1,.374.4l.1.195a1.569,1.569,0,0,1-.627,2.045l-31.739,18.318a2.453,2.453,0,0,1-2.208,0l-16.244-9.35A1.779,1.779,0,0,1,191.843,244.04Z"
                  fill="#dbdbe2"
                  opacity="0.195"
                />
                <path
                  id="패스_228"
                  data-name="패스 228"
                  d="M191.92,243.992v-.6l32.221-18.927a1.925,1.925,0,0,1,1.922-.213l16.916,9.712a.927.927,0,0,1,.373.4l.094.2a1.563,1.563,0,0,1-.624,2.037l-31.628,18.253a2.44,2.44,0,0,1-2.2,0l-16.187-9.317A1.769,1.769,0,0,1,191.92,243.992Z"
                  fill="#d7d7de"
                  opacity="0.22"
                />
                <path
                  id="패스_229"
                  data-name="패스 229"
                  d="M192,243.944v-.6L224.1,224.483a1.917,1.917,0,0,1,1.915-.213l16.856,9.678a.923.923,0,0,1,.372.4l.094.193a1.559,1.559,0,0,1-.623,2.031L211.2,254.758a2.432,2.432,0,0,1-2.192,0l-16.129-9.284A1.764,1.764,0,0,1,192,243.944Z"
                  fill="#d2d2db"
                  opacity="0.244"
                />
                <path
                  id="패스_230"
                  data-name="패스 230"
                  d="M192.074,243.9v-.6L224.067,224.5a1.911,1.911,0,0,1,1.908-.212l16.8,9.643a.915.915,0,0,1,.37.4l.094.193a1.553,1.553,0,0,1-.62,2.023l-31.4,18.124a2.427,2.427,0,0,1-2.184,0l-16.073-9.251A1.761,1.761,0,0,1,192.074,243.9Z"
                  fill="#ceced7"
                  opacity="0.268"
                />
                <path
                  id="패스_231"
                  data-name="패스 231"
                  d="M192.151,243.848v-.6l31.879-18.726a1.905,1.905,0,0,1,1.9-.211l16.736,9.609a.92.92,0,0,1,.369.395l.093.193a1.546,1.546,0,0,1-.618,2.015l-31.29,18.059a2.413,2.413,0,0,1-2.177,0l-16.015-9.218A1.753,1.753,0,0,1,192.151,243.848Z"
                  fill="#c9c9d3"
                  opacity="0.293"
                />
                <path
                  id="패스_232"
                  data-name="패스 232"
                  d="M192.228,243.8v-.6l31.764-18.658a1.9,1.9,0,0,1,1.895-.211l16.676,9.575a.913.913,0,0,1,.368.393l.093.192a1.543,1.543,0,0,1-.616,2.009L211.229,254.5a2.406,2.406,0,0,1-2.168,0L193.1,245.314A1.748,1.748,0,0,1,192.228,243.8Z"
                  fill="#c5c5d0"
                  opacity="0.317"
                />
                <path
                  id="패스_233"
                  data-name="패스 233"
                  d="M192.3,243.752v-.593l31.65-18.591a1.89,1.89,0,0,1,1.888-.209l16.616,9.539a.909.909,0,0,1,.366.393l.093.19a1.537,1.537,0,0,1-.614,2l-31.066,17.929a2.4,2.4,0,0,1-2.161,0l-15.9-9.151A1.74,1.74,0,0,1,192.3,243.752Z"
                  fill="#c1c1cc"
                  opacity="0.341"
                />
                <path
                  id="패스_234"
                  data-name="패스 234"
                  d="M192.382,243.7v-.591l31.536-18.523a1.882,1.882,0,0,1,1.881-.209l16.556,9.505a.907.907,0,0,1,.365.391l.092.19a1.53,1.53,0,0,1-.611,1.994l-30.954,17.865a2.387,2.387,0,0,1-2.153,0l-15.843-9.119A1.732,1.732,0,0,1,192.382,243.7Z"
                  fill="#bcbcc8"
                  opacity="0.366"
                />
                <path
                  id="패스_235"
                  data-name="패스 235"
                  d="M192.459,243.656v-.589l31.422-18.456a1.872,1.872,0,0,1,1.873-.208l16.5,9.471a.906.906,0,0,1,.364.389l.092.19a1.526,1.526,0,0,1-.61,1.987l-30.842,17.8a2.383,2.383,0,0,1-2.145,0l-15.785-9.086A1.728,1.728,0,0,1,192.459,243.656Z"
                  fill="#b8b8c5"
                  opacity="0.39"
                />
                <path
                  id="패스_236"
                  data-name="패스 236"
                  d="M192.536,243.609v-.587l31.308-18.39a1.87,1.87,0,0,1,1.867-.207l16.436,9.436a.9.9,0,0,1,.362.388l.092.189a1.52,1.52,0,0,1-.607,1.98l-30.73,17.735a2.366,2.366,0,0,1-2.137,0L193.4,245.1A1.723,1.723,0,0,1,192.536,243.609Z"
                  fill="#b3b3c1"
                  opacity="0.415"
                />
                <path
                  id="패스_237"
                  data-name="패스 237"
                  d="M192.613,243.561v-.585l31.193-18.322a1.86,1.86,0,0,1,1.86-.207l16.377,9.4a.9.9,0,0,1,.361.387l.091.188a1.514,1.514,0,0,1-.6,1.972l-30.618,17.671a2.364,2.364,0,0,1-2.13,0l-15.671-9.02A1.716,1.716,0,0,1,192.613,243.561Z"
                  fill="#afafbe"
                  opacity="0.439"
                />
                <path
                  id="패스_238"
                  data-name="패스 238"
                  d="M192.69,243.513v-.583l31.079-18.255a1.854,1.854,0,0,1,1.853-.206l16.317,9.368a.893.893,0,0,1,.36.385l.091.187a1.508,1.508,0,0,1-.6,1.966l-30.506,17.6a2.351,2.351,0,0,1-2.121,0l-15.614-8.986A1.709,1.709,0,0,1,192.69,243.513Z"
                  fill="#aaaaba"
                  opacity="0.463"
                />
                <path
                  id="패스_239"
                  data-name="패스 239"
                  d="M192.767,243.465v-.581L223.732,224.7a1.848,1.848,0,0,1,1.846-.205l16.257,9.333a.9.9,0,0,1,.359.384l.09.187a1.5,1.5,0,0,1-.6,1.958L211.29,253.894a2.343,2.343,0,0,1-2.114,0l-15.556-8.953A1.7,1.7,0,0,1,192.767,243.465Z"
                  fill="#a6a6b6"
                  opacity="0.488"
                />
                <path
                  id="패스_240"
                  data-name="패스 240"
                  d="M192.844,243.417v-.578L223.7,224.717a1.842,1.842,0,0,1,1.839-.2l16.2,9.3a.89.89,0,0,1,.357.382l.091.186a1.5,1.5,0,0,1-.6,1.951L211.3,253.808a2.338,2.338,0,0,1-2.106,0l-15.5-8.921A1.7,1.7,0,0,1,192.844,243.417Z"
                  fill="#a1a1b3"
                  opacity="0.512"
                />
                <path
                  id="패스_241"
                  data-name="패스 241"
                  d="M192.921,243.369v-.576l30.736-18.054a1.836,1.836,0,0,1,1.833-.2l16.137,9.265a.885.885,0,0,1,.356.381l.09.185a1.492,1.492,0,0,1-.6,1.944l-30.17,17.411a2.322,2.322,0,0,1-2.1,0l-15.441-8.887A1.69,1.69,0,0,1,192.921,243.369Z"
                  fill="#9d9daf"
                  opacity="0.537"
                />
                <path
                  id="패스_242"
                  data-name="패스 242"
                  d="M193,243.321v-.574L223.62,224.76a1.83,1.83,0,0,1,1.826-.2l16.077,9.23a.886.886,0,0,1,.355.38l.089.185a1.485,1.485,0,0,1-.594,1.936l-30.057,17.347a2.317,2.317,0,0,1-2.09,0l-15.384-8.854A1.685,1.685,0,0,1,193,243.321Z"
                  fill="#9898ab"
                  opacity="0.561"
                />
                <path
                  id="패스_243"
                  data-name="패스 243"
                  d="M193.075,243.273V242.7l30.508-17.92a1.824,1.824,0,0,1,1.819-.2l16.017,9.2a.882.882,0,0,1,.353.378l.089.184a1.479,1.479,0,0,1-.591,1.929l-29.945,17.283a2.315,2.315,0,0,1-2.083,0l-15.326-8.822A1.676,1.676,0,0,1,193.075,243.273Z"
                  fill="#9494a8"
                  opacity="0.585"
                />
                <path
                  id="패스_244"
                  data-name="패스 244"
                  d="M193.152,243.225v-.569L223.546,224.8a1.811,1.811,0,0,1,1.812-.2l15.957,9.161a.87.87,0,0,1,.352.376l.089.184a1.475,1.475,0,0,1-.589,1.922l-29.834,17.217a2.3,2.3,0,0,1-2.075,0l-15.269-8.788A1.671,1.671,0,0,1,193.152,243.225Z"
                  fill="#8f8fa4"
                  opacity="0.61"
                />
                <path
                  id="패스_245"
                  data-name="패스 245"
                  d="M193.229,243.177v-.567l30.28-17.786a1.807,1.807,0,0,1,1.8-.2l15.9,9.126a.873.873,0,0,1,.351.376l.088.182a1.469,1.469,0,0,1-.587,1.915l-29.721,17.153a2.294,2.294,0,0,1-2.067,0l-15.212-8.756A1.664,1.664,0,0,1,193.229,243.177Z"
                  fill="#8b8ba1"
                  opacity="0.634"
                />
                <path
                  id="패스_246"
                  data-name="패스 246"
                  d="M193.306,243.13v-.566l30.165-17.719a1.8,1.8,0,0,1,1.8-.2l15.837,9.092a.863.863,0,0,1,.349.374l.089.182a1.464,1.464,0,0,1-.585,1.907L211.351,253.29a2.285,2.285,0,0,1-2.06,0l-15.154-8.723A1.659,1.659,0,0,1,193.306,243.13Z"
                  fill="#86869d"
                  opacity="0.659"
                />
                <path
                  id="패스_247"
                  data-name="패스 247"
                  d="M193.383,243.082v-.564l30.051-17.651a1.792,1.792,0,0,1,1.792-.2L241,233.726a.862.862,0,0,1,.348.372l.088.181a1.459,1.459,0,0,1-.583,1.9l-29.5,17.023a2.273,2.273,0,0,1-2.051,0l-15.1-8.689A1.653,1.653,0,0,1,193.383,243.082Z"
                  fill="#828299"
                  opacity="0.683"
                />
                <path
                  id="패스_248"
                  data-name="패스 248"
                  d="M193.46,243.034v-.561L223.4,224.888a1.786,1.786,0,0,1,1.785-.2l15.717,9.023a.869.869,0,0,1,.347.371l.087.181a1.453,1.453,0,0,1-.58,1.893l-29.385,16.959a2.271,2.271,0,0,1-2.044,0l-15.039-8.657A1.643,1.643,0,0,1,193.46,243.034Z"
                  fill="#7e7e96"
                  opacity="0.707"
                />
                <path
                  id="패스_249"
                  data-name="패스 249"
                  d="M193.537,242.986v-.559l29.823-17.518a1.78,1.78,0,0,1,1.778-.2l15.657,8.989a.856.856,0,0,1,.345.37l.088.18a1.447,1.447,0,0,1-.579,1.885l-29.272,16.9a2.26,2.26,0,0,1-2.036,0l-14.982-8.624A1.638,1.638,0,0,1,193.537,242.986Z"
                  fill="#797992"
                  opacity="0.732"
                />
                <path
                  id="패스_250"
                  data-name="패스 250"
                  d="M193.614,242.938v-.557l29.709-17.45a1.772,1.772,0,0,1,1.771-.2l15.6,8.955a.849.849,0,0,1,.344.368l.087.179a1.443,1.443,0,0,1-.576,1.879l-29.161,16.829a2.249,2.249,0,0,1-2.028,0l-14.925-8.59A1.635,1.635,0,0,1,193.614,242.938Z"
                  fill="#75758e"
                  opacity="0.756"
                />
                <path
                  id="패스_251"
                  data-name="패스 251"
                  d="M193.691,242.89v-.555l29.594-17.383a1.766,1.766,0,0,1,1.765-.2l15.537,8.92a.85.85,0,0,1,.343.367l.086.179a1.435,1.435,0,0,1-.574,1.871l-29.048,16.765a2.239,2.239,0,0,1-2.02,0L194.506,244.3A1.627,1.627,0,0,1,193.691,242.89Z"
                  fill="#70708b"
                  opacity="0.78"
                />
                <path
                  id="패스_252"
                  data-name="패스 252"
                  d="M193.768,242.842v-.552l29.48-17.317a1.762,1.762,0,0,1,1.758-.2l15.477,8.886a.846.846,0,0,1,.341.365l.087.178a1.431,1.431,0,0,1-.572,1.864l-28.936,16.7a2.23,2.23,0,0,1-2.013,0l-14.81-8.524A1.622,1.622,0,0,1,193.768,242.842Z"
                  fill="#6c6c87"
                  opacity="0.805"
                />
                <path
                  id="패스_253"
                  data-name="패스 253"
                  d="M193.845,242.794v-.55l29.366-17.249a1.752,1.752,0,0,1,1.751-.195l15.417,8.852a.842.842,0,0,1,.34.364l.086.177a1.425,1.425,0,0,1-.569,1.857l-28.825,16.635a2.224,2.224,0,0,1-2,0l-14.753-8.491A1.614,1.614,0,0,1,193.845,242.794Z"
                  fill="#676783"
                  opacity="0.829"
                />
                <path
                  id="패스_254"
                  data-name="패스 254"
                  d="M193.922,242.746V242.2l29.252-17.182a1.743,1.743,0,0,1,1.744-.193l15.357,8.816a.848.848,0,0,1,.339.363l.085.176a1.419,1.419,0,0,1-.567,1.85L211.42,252.6a2.216,2.216,0,0,1-2,0l-14.7-8.459A1.607,1.607,0,0,1,193.922,242.746Z"
                  fill="#636380"
                  opacity="0.854"
                />
                <path
                  id="패스_255"
                  data-name="패스 255"
                  d="M194,242.7v-.546l29.137-17.115a1.741,1.741,0,0,1,1.738-.192l15.3,8.782a.839.839,0,0,1,.337.361l.086.176a1.414,1.414,0,0,1-.565,1.842l-28.6,16.506a2.2,2.2,0,0,1-1.989,0L194.8,244.087A1.6,1.6,0,0,1,194,242.7Z"
                  fill="#5e5e7c"
                  opacity="0.878"
                />
                <path
                  id="패스_256"
                  data-name="패스 256"
                  d="M194.076,242.651v-.544L223.1,225.059a1.731,1.731,0,0,1,1.731-.192l15.237,8.748a.826.826,0,0,1,.336.359l.085.175a1.409,1.409,0,0,1-.563,1.836l-28.488,16.441a2.2,2.2,0,0,1-1.981,0l-14.58-8.392A1.6,1.6,0,0,1,194.076,242.651Z"
                  fill="#5a5a79"
                  opacity="0.902"
                />
                <path
                  id="패스_257"
                  data-name="패스 257"
                  d="M194.153,242.6v-.542l28.909-16.981a1.727,1.727,0,0,1,1.724-.191l15.177,8.713a.833.833,0,0,1,.335.358l.084.175a1.4,1.4,0,0,1-.56,1.828L211.446,252.34a2.19,2.19,0,0,1-1.973,0l-14.524-8.36A1.59,1.59,0,0,1,194.153,242.6Z"
                  fill="#555575"
                  opacity="0.927"
                />
                <path
                  id="패스_258"
                  data-name="패스 258"
                  d="M194.23,242.555v-.54L223.025,225.1a1.719,1.719,0,0,1,1.717-.19l15.117,8.679a.82.82,0,0,1,.333.357l.085.174a1.4,1.4,0,0,1-.559,1.82l-28.263,16.312a2.182,2.182,0,0,1-1.966,0l-14.466-8.326A1.582,1.582,0,0,1,194.23,242.555Z"
                  fill="#515171"
                  opacity="0.951"
                />
                <path
                  id="패스_259"
                  data-name="패스 259"
                  d="M194.307,242.507v-.538l28.68-16.846a1.713,1.713,0,0,1,1.711-.19l15.057,8.645a.819.819,0,0,1,.332.355l.084.173a1.393,1.393,0,0,1-.556,1.814l-28.152,16.247a2.171,2.171,0,0,1-1.958,0L195.1,243.874A1.578,1.578,0,0,1,194.307,242.507Z"
                  fill="#4c4c6e"
                  opacity="0.976"
                />
                <path
                  id="패스_260"
                  data-name="패스 260"
                  d="M194.384,242.459v-.535l28.566-16.78a1.709,1.709,0,0,1,1.7-.189l15,8.61a.826.826,0,0,1,.331.354l.083.173a1.386,1.386,0,0,1-.554,1.806L211.472,252.08a2.161,2.161,0,0,1-1.95,0l-14.351-8.26A1.57,1.57,0,0,1,194.384,242.459Z"
                  fill="#48486a"
                />
              </g>
              <path
                id="패스_261"
                data-name="패스 261"
                d="M191.872,243.208v-.595l31.761-18.656a1.9,1.9,0,0,1,1.893-.21L242.2,233.32a.918.918,0,0,1,.367.394l.093.191a1.541,1.541,0,0,1-.616,2.009l-31.174,17.992a2.406,2.406,0,0,1-2.168,0l-15.956-9.184A1.748,1.748,0,0,1,191.872,243.208Z"
                fill="url(#linear-gradient-5)"
              />
              <path
                id="패스_262"
                data-name="패스 262"
                d="M192.319,243.07a.662.662,0,0,1,0-1.252l31.225-18.028a2.406,2.406,0,0,1,2.168,0l16.384,9.459a.662.662,0,0,1,0,1.252l-31.225,18.028a2.406,2.406,0,0,1-2.168,0Z"
                fill="#f9f7f9"
              />
              <path
                id="패스_263"
                data-name="패스 263"
                d="M196.08,240.817,221.9,225.808l16.5,9.525-25.824,15.009Z"
                fill="#d3daed"
              />
              <g id="그룹_13" data-name="그룹 13">
                <path
                  id="패스_264"
                  data-name="패스 264"
                  d="M233.886,230.967a.236.236,0,0,1-.122-.033l-4.288-2.475a.246.246,0,1,1,.247-.426l4.287,2.475a.246.246,0,0,1-.124.459Z"
                  fill="#99a7d8"
                />
              </g>
              <ellipse
                id="타원_1"
                data-name="타원 1"
                cx="1.895"
                cy="1.094"
                rx="1.895"
                ry="1.094"
                transform="translate(200.435 245.659)"
                fill="#f1f2f2"
              />
            </g>
            <g id="그룹_59" data-name="그룹 59">
              <g
                id="그룹_57"
                data-name="그룹 57"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              >
                <g id="그룹_15" data-name="그룹 15" opacity="0">
                  <path
                    id="패스_265"
                    data-name="패스 265"
                    d="M243.355,255.1a6.5,6.5,0,0,1,5.893-.034l20.4,11.707c1.626.934,1.637,2.48.025,3.437L255.8,278.439a6.446,6.446,0,0,1-5.883.036l-20.4-11.78c-1.623-.938-1.63-2.483-.015-3.435Z"
                    fill="#fff"
                  />
                </g>
                <g id="그룹_16" data-name="그룹 16" opacity="0.024">
                  <path
                    id="패스_266"
                    data-name="패스 266"
                    d="M243.406,255.169a6.447,6.447,0,0,1,5.845-.033l20.228,11.611c1.612.926,1.624,2.46.025,3.409l-13.755,8.165a6.4,6.4,0,0,1-5.835.036l-20.236-11.684c-1.61-.929-1.617-2.462-.015-3.406Z"
                    fill="#fbfbfb"
                  />
                </g>
                <g id="그룹_17" data-name="그룹 17" opacity="0.049">
                  <path
                    id="패스_267"
                    data-name="패스 267"
                    d="M243.457,255.243a6.39,6.39,0,0,1,5.8-.033l20.06,11.516c1.6.918,1.611,2.439.025,3.38L255.7,278.2a6.342,6.342,0,0,1-5.787.035l-20.068-11.587c-1.6-.922-1.6-2.442-.015-3.378Z"
                    fill="#f6f6f8"
                  />
                </g>
                <g id="그룹_18" data-name="그룹 18" opacity="0.073">
                  <path
                    id="패스_268"
                    data-name="패스 268"
                    d="M243.508,255.317a6.336,6.336,0,0,1,5.749-.032L269.15,266.7c1.586.91,1.6,2.419.024,3.352l-13.527,8.03a6.285,6.285,0,0,1-5.738.035l-19.9-11.49c-1.583-.915-1.59-2.422-.015-3.35Z"
                    fill="#f2f2f4"
                  />
                </g>
                <g id="그룹_19" data-name="그룹 19" opacity="0.098">
                  <path
                    id="패스_269"
                    data-name="패스 269"
                    d="M243.559,255.391a6.28,6.28,0,0,1,5.7-.032l19.726,11.323c1.573.9,1.584,2.4.024,3.324L255.6,277.968a6.231,6.231,0,0,1-5.69.035l-19.734-11.394c-1.571-.906-1.577-2.4-.015-3.322Z"
                    fill="#ededf0"
                  />
                </g>
                <g id="그룹_20" data-name="그룹 20" opacity="0.122">
                  <path
                    id="패스_270"
                    data-name="패스 270"
                    d="M243.61,255.465a6.229,6.229,0,0,1,5.652-.032l19.559,11.227c1.559.895,1.57,2.378.024,3.3l-13.3,7.9a6.181,6.181,0,0,1-5.642.034l-19.567-11.3c-1.557-.9-1.563-2.381-.014-3.294Z"
                    fill="#e9e9ed"
                  />
                </g>
                <g id="그룹_21" data-name="그룹 21" opacity="0.146">
                  <path
                    id="패스_271"
                    data-name="패스 271"
                    d="M243.662,255.539a6.173,6.173,0,0,1,5.6-.032l19.391,11.131c1.546.887,1.557,2.358.024,3.268l-13.186,7.827a6.13,6.13,0,0,1-5.594.034l-19.4-11.2c-1.544-.891-1.55-2.361-.015-3.266Z"
                    fill="#e4e4e9"
                  />
                </g>
                <g id="그룹_22" data-name="그룹 22" opacity="0.171">
                  <path
                    id="패스_272"
                    data-name="패스 272"
                    d="M243.713,255.612a6.12,6.12,0,0,1,5.554-.031l19.225,11.035c1.532.88,1.543,2.338.023,3.24l-13.072,7.76a6.073,6.073,0,0,1-5.545.033l-19.233-11.1c-1.53-.884-1.536-2.341-.014-3.238Z"
                    fill="#e0e0e6"
                  />
                </g>
                <g id="그룹_23" data-name="그룹 23" opacity="0.195">
                  <path
                    id="패스_273"
                    data-name="패스 273"
                    d="M243.764,255.686a6.069,6.069,0,0,1,5.506-.031l19.057,10.939c1.519.872,1.53,2.317.023,3.212L255.392,277.5a6.022,6.022,0,0,1-5.5.033L230.83,266.524c-1.517-.876-1.524-2.32-.014-3.209Z"
                    fill="#dbdbe2"
                  />
                </g>
                <g id="그룹_24" data-name="그룹 24" opacity="0.22">
                  <path
                    id="패스_274"
                    data-name="패스 274"
                    d="M243.815,255.76a6.018,6.018,0,0,1,5.458-.031l18.89,10.843c1.505.865,1.516,2.3.023,3.183l-12.845,7.625a5.966,5.966,0,0,1-5.449.033l-18.9-10.91c-1.5-.868-1.511-2.3-.014-3.181Z"
                    fill="#d7d7de"
                  />
                </g>
                <g id="그룹_25" data-name="그룹 25" opacity="0.244">
                  <path
                    id="패스_275"
                    data-name="패스 275"
                    d="M243.866,255.834a5.961,5.961,0,0,1,5.41-.031L268,266.55c1.493.857,1.5,2.277.023,3.156l-12.731,7.557a5.921,5.921,0,0,1-5.4.033l-18.73-10.814c-1.49-.861-1.5-2.28-.014-3.153Z"
                    fill="#d2d2db"
                  />
                </g>
                <g id="그룹_26" data-name="그룹 26" opacity="0.268">
                  <path
                    id="패스_276"
                    data-name="패스 276"
                    d="M243.917,255.908a5.908,5.908,0,0,1,5.361-.031l18.556,10.651c1.479.85,1.489,2.257.022,3.127l-12.617,7.49a5.862,5.862,0,0,1-5.352.033L231.324,266.46c-1.477-.852-1.484-2.259-.014-3.124Z"
                    fill="#ceced7"
                  />
                </g>
                <g id="그룹_27" data-name="그룹 27" opacity="0.293">
                  <path
                    id="패스_277"
                    data-name="패스 277"
                    d="M243.968,255.982a5.857,5.857,0,0,1,5.313-.031l18.388,10.555c1.466.842,1.476,2.236.023,3.1l-12.5,7.423a5.814,5.814,0,0,1-5.3.032l-18.4-10.621c-1.464-.845-1.47-2.239-.013-3.1Z"
                    fill="#c9c9d3"
                  />
                </g>
                <g id="그룹_28" data-name="그룹 28" opacity="0.317">
                  <path
                    id="패스_278"
                    data-name="패스 278"
                    d="M244.019,256.055a5.8,5.8,0,0,1,5.265-.029l18.22,10.459c1.453.833,1.463,2.215.023,3.07l-12.39,7.355a5.76,5.76,0,0,1-5.256.032l-18.228-10.524c-1.451-.838-1.457-2.218-.014-3.069Z"
                    fill="#c5c5d0"
                  />
                </g>
                <g id="그룹_29" data-name="그룹 29" opacity="0.341">
                  <path
                    id="패스_279"
                    data-name="패스 279"
                    d="M244.07,256.129a5.749,5.749,0,0,1,5.217-.029l18.053,10.363c1.439.826,1.449,2.195.022,3.042l-12.276,7.287a5.706,5.706,0,0,1-5.208.032L231.817,266.4c-1.437-.83-1.443-2.2-.013-3.041Z"
                    fill="#c1c1cc"
                  />
                </g>
                <g id="그룹_30" data-name="그룹 30" opacity="0.366">
                  <path
                    id="패스_280"
                    data-name="패스 280"
                    d="M244.121,256.2a5.693,5.693,0,0,1,5.168-.029l17.886,10.267c1.426.818,1.436,2.175.022,3.014l-12.162,7.22a5.655,5.655,0,0,1-5.16.031l-17.893-10.331c-1.424-.822-1.43-2.177-.013-3.012Z"
                    fill="#bcbcc8"
                  />
                </g>
                <g id="그룹_31" data-name="그룹 31" opacity="0.39">
                  <path
                    id="패스_281"
                    data-name="패스 281"
                    d="M244.172,256.277a5.642,5.642,0,0,1,5.12-.029l17.719,10.171c1.412.811,1.422,2.155.022,2.986l-12.049,7.152a5.6,5.6,0,0,1-5.111.031l-17.726-10.234c-1.411-.814-1.417-2.157-.014-2.984Z"
                    fill="#b8b8c5"
                  />
                </g>
                <g id="그룹_32" data-name="그룹 32" opacity="0.415">
                  <path
                    id="패스_282"
                    data-name="패스 282"
                    d="M244.223,256.351a5.591,5.591,0,0,1,5.072-.029L266.846,266.4c1.4.8,1.409,2.134.022,2.958l-11.935,7.085a5.548,5.548,0,0,1-5.063.03l-17.559-10.137c-1.4-.807-1.4-2.137-.013-2.956Z"
                    fill="#b3b3c1"
                  />
                </g>
                <g id="그룹_33" data-name="그룹 33" opacity="0.439">
                  <path
                    id="패스_283"
                    data-name="패스 283"
                    d="M244.274,256.425a5.535,5.535,0,0,1,5.023-.029l17.385,9.979c1.386.8,1.395,2.114.021,2.93l-11.821,7.017a5.494,5.494,0,0,1-5.015.03l-17.391-10.041c-1.384-.8-1.39-2.116-.013-2.927Z"
                    fill="#afafbe"
                  />
                </g>
                <g id="그룹_34" data-name="그룹 34" opacity="0.463">
                  <path
                    id="패스_284"
                    data-name="패스 284"
                    d="M244.325,256.5a5.484,5.484,0,0,1,4.975-.028l17.217,9.883c1.373.788,1.382,2.094.021,2.9L254.831,276.2a5.443,5.443,0,0,1-4.967.031L232.64,266.29c-1.37-.791-1.376-2.1-.013-2.9Z"
                    fill="#aaaaba"
                  />
                </g>
                <g id="그룹_35" data-name="그룹 35" opacity="0.488">
                  <path
                    id="패스_285"
                    data-name="패스 285"
                    d="M244.376,256.572a5.433,5.433,0,0,1,4.927-.028l17.05,9.787c1.359.781,1.368,2.074.021,2.873l-11.594,6.883a5.39,5.39,0,0,1-4.918.03L232.8,266.269c-1.358-.784-1.363-2.076-.013-2.872Z"
                    fill="#a6a6b6"
                  />
                </g>
                <g id="그룹_36" data-name="그룹 36" opacity="0.512">
                  <path
                    id="패스_286"
                    data-name="패스 286"
                    d="M244.427,256.646a5.379,5.379,0,0,1,4.879-.028l16.882,9.691c1.346.773,1.355,2.053.021,2.845l-11.48,6.815a5.336,5.336,0,0,1-4.87.03l-16.89-9.752c-1.344-.776-1.349-2.055-.012-2.843Z"
                    fill="#a1a1b3"
                  />
                </g>
                <g id="그룹_37" data-name="그룹 37" opacity="0.537">
                  <path
                    id="패스_287"
                    data-name="패스 287"
                    d="M244.478,256.72a5.323,5.323,0,0,1,4.83-.028l16.716,9.6c1.332.765,1.342,2.033.02,2.817l-11.366,6.748a5.285,5.285,0,0,1-4.822.029l-16.722-9.655c-1.331-.768-1.336-2.035-.013-2.815Z"
                    fill="#9d9daf"
                  />
                </g>
                <g id="그룹_38" data-name="그룹 38" opacity="0.561">
                  <path
                    id="패스_288"
                    data-name="패스 288"
                    d="M244.529,256.794a5.269,5.269,0,0,1,4.782-.027l16.548,9.5a1.479,1.479,0,0,1,.021,2.788l-11.253,6.68a5.231,5.231,0,0,1-4.774.029L233.3,266.2a1.478,1.478,0,0,1-.012-2.787Z"
                    fill="#9898ab"
                  />
                </g>
                <g id="그룹_39" data-name="그룹 39" opacity="0.585">
                  <path
                    id="패스_289"
                    data-name="패스 289"
                    d="M244.58,256.868a5.215,5.215,0,0,1,4.734-.027l16.381,9.4a1.464,1.464,0,0,1,.02,2.76l-11.139,6.612a5.175,5.175,0,0,1-4.725.029l-16.388-9.461a1.463,1.463,0,0,1-.012-2.759Z"
                    fill="#9494a8"
                  />
                </g>
                <g id="그룹_40" data-name="그룹 40" opacity="0.61">
                  <path
                    id="패스_290"
                    data-name="패스 290"
                    d="M244.631,256.941a5.165,5.165,0,0,1,4.685-.026l16.214,9.307a1.449,1.449,0,0,1,.02,2.732L254.525,275.5a5.124,5.124,0,0,1-4.677.028l-16.22-9.365a1.447,1.447,0,0,1-.012-2.73Z"
                    fill="#8f8fa4"
                  />
                </g>
                <g id="그룹_41" data-name="그룹 41" opacity="0.634">
                  <path
                    id="패스_291"
                    data-name="패스 291"
                    d="M244.683,257.015a5.106,5.106,0,0,1,4.636-.026l16.047,9.211a1.434,1.434,0,0,1,.019,2.7l-10.911,6.477a5.07,5.07,0,0,1-4.629.028l-16.053-9.268a1.433,1.433,0,0,1-.012-2.7Z"
                    fill="#8b8ba1"
                  />
                </g>
                <g id="그룹_42" data-name="그룹 42" opacity="0.659">
                  <path
                    id="패스_292"
                    data-name="패스 292"
                    d="M244.734,257.089a5.055,5.055,0,0,1,4.588-.026l15.879,9.115a1.42,1.42,0,0,1,.02,2.676l-10.8,6.41a5.019,5.019,0,0,1-4.581.027l-15.885-9.171a1.418,1.418,0,0,1-.012-2.674Z"
                    fill="#86869d"
                  />
                </g>
                <g id="그룹_43" data-name="그룹 43" opacity="0.683">
                  <path
                    id="패스_293"
                    data-name="패스 293"
                    d="M244.785,257.163a5,5,0,0,1,4.54-.026l15.712,9.019a1.4,1.4,0,0,1,.019,2.648l-10.684,6.342a4.963,4.963,0,0,1-4.532.027L234.121,266.1a1.4,1.4,0,0,1-.011-2.646Z"
                    fill="#828299"
                  />
                </g>
                <g id="그룹_44" data-name="그룹 44" opacity="0.707">
                  <path
                    id="패스_294"
                    data-name="패스 294"
                    d="M244.836,257.237a4.948,4.948,0,0,1,4.491-.026l15.545,8.923a1.39,1.39,0,0,1,.019,2.62l-10.57,6.274a4.912,4.912,0,0,1-4.484.028l-15.551-8.979a1.388,1.388,0,0,1-.012-2.618Z"
                    fill="#7e7e96"
                  />
                </g>
                <g id="그룹_45" data-name="그룹 45" opacity="0.732">
                  <path
                    id="패스_295"
                    data-name="패스 295"
                    d="M244.887,257.31a4.9,4.9,0,0,1,4.443-.025l15.378,8.827a1.375,1.375,0,0,1,.018,2.592l-10.456,6.207a4.864,4.864,0,0,1-4.436.027l-15.384-8.882a1.374,1.374,0,0,1-.011-2.59Z"
                    fill="#797992"
                  />
                </g>
                <g id="그룹_46" data-name="그룹 46" opacity="0.756">
                  <path
                    id="패스_296"
                    data-name="패스 296"
                    d="M244.938,257.384a4.843,4.843,0,0,1,4.395-.025l15.21,8.731a1.36,1.36,0,0,1,.019,2.564l-10.343,6.139a4.807,4.807,0,0,1-4.388.027l-15.216-8.786a1.358,1.358,0,0,1-.011-2.561Z"
                    fill="#75758e"
                  />
                </g>
                <g id="그룹_47" data-name="그룹 47" opacity="0.78">
                  <path
                    id="패스_297"
                    data-name="패스 297"
                    d="M244.989,257.458a4.792,4.792,0,0,1,4.347-.025l15.042,8.635a1.345,1.345,0,0,1,.019,2.535l-10.229,6.073a4.757,4.757,0,0,1-4.339.026l-15.049-8.689a1.343,1.343,0,0,1-.012-2.533Z"
                    fill="#70708b"
                  />
                </g>
                <g id="그룹_48" data-name="그룹 48" opacity="0.805">
                  <path
                    id="패스_298"
                    data-name="패스 298"
                    d="M245.04,257.532a4.736,4.736,0,0,1,4.3-.024l14.876,8.539a1.329,1.329,0,0,1,.018,2.506l-10.115,6.005a4.7,4.7,0,0,1-4.291.026l-14.882-8.592a1.328,1.328,0,0,1-.011-2.505Z"
                    fill="#6c6c87"
                  />
                </g>
                <g id="그룹_49" data-name="그룹 49" opacity="0.829">
                  <path
                    id="패스_299"
                    data-name="패스 299"
                    d="M245.091,257.606a4.682,4.682,0,0,1,4.25-.024l14.708,8.443a1.314,1.314,0,0,1,.018,2.478l-10,5.937a4.649,4.649,0,0,1-4.243.026l-14.714-8.495a1.314,1.314,0,0,1-.011-2.477Z"
                    fill="#676783"
                  />
                </g>
                <g id="그룹_50" data-name="그룹 50" opacity="0.854">
                  <path
                    id="패스_300"
                    data-name="패스 300"
                    d="M245.142,257.68a4.628,4.628,0,0,1,4.2-.024L263.885,266a1.3,1.3,0,0,1,.018,2.45l-9.888,5.87a4.6,4.6,0,0,1-4.195.025l-14.547-8.4a1.3,1.3,0,0,1-.011-2.448Z"
                    fill="#636380"
                  />
                </g>
                <g id="그룹_51" data-name="그룹 51" opacity="0.878">
                  <path
                    id="패스_301"
                    data-name="패스 301"
                    d="M245.193,257.754a4.575,4.575,0,0,1,4.153-.024l14.374,8.251a1.285,1.285,0,0,1,.018,2.422l-9.774,5.8a4.539,4.539,0,0,1-4.146.025l-14.38-8.3a1.284,1.284,0,0,1-.011-2.421Z"
                    fill="#5e5e7c"
                  />
                </g>
                <g id="그룹_52" data-name="그룹 52" opacity="0.902">
                  <path
                    id="패스_302"
                    data-name="패스 302"
                    d="M245.244,257.827a4.524,4.524,0,0,1,4.1-.023l14.207,8.155a1.27,1.27,0,0,1,.017,2.394l-9.66,5.735a4.491,4.491,0,0,1-4.1.024l-14.213-8.2a1.269,1.269,0,0,1-.01-2.393Z"
                    fill="#5a5a79"
                  />
                </g>
                <g id="그룹_53" data-name="그룹 53" opacity="0.927">
                  <path
                    id="패스_303"
                    data-name="패스 303"
                    d="M245.295,257.9a4.473,4.473,0,0,1,4.057-.023l14.039,8.059a1.255,1.255,0,0,1,.018,2.366l-9.547,5.667a4.437,4.437,0,0,1-4.05.025l-14.045-8.109a1.254,1.254,0,0,1-.011-2.365Z"
                    fill="#555575"
                  />
                </g>
                <g id="그룹_54" data-name="그룹 54" opacity="0.951">
                  <path
                    id="패스_304"
                    data-name="패스 304"
                    d="M245.346,257.975a4.419,4.419,0,0,1,4.009-.023l13.872,7.963a1.24,1.24,0,0,1,.017,2.338l-9.433,5.6a4.387,4.387,0,0,1-4,.025l-13.878-8.013a1.239,1.239,0,0,1-.01-2.336Z"
                    fill="#515171"
                  />
                </g>
                <g id="그룹_55" data-name="그룹 55" opacity="0.976">
                  <path
                    id="패스_305"
                    data-name="패스 305"
                    d="M245.4,258.049a4.363,4.363,0,0,1,3.96-.023l13.705,7.867a1.225,1.225,0,0,1,.017,2.31l-9.319,5.532a4.33,4.33,0,0,1-3.953.024L236.1,265.843a1.224,1.224,0,0,1-.01-2.308Z"
                    fill="#4c4c6e"
                  />
                </g>
                <g id="그룹_56" data-name="그룹 56">
                  <path
                    id="패스_306"
                    data-name="패스 306"
                    d="M245.448,258.123a4.312,4.312,0,0,1,3.912-.023l13.538,7.771a1.211,1.211,0,0,1,.016,2.282l-9.205,5.464a4.279,4.279,0,0,1-3.9.024l-13.543-7.819a1.209,1.209,0,0,1-.01-2.28Z"
                    fill="#48486a"
                  />
                </g>
              </g>
              <path
                id="패스_307"
                data-name="패스 307"
                d="M243.879,253.956a6.091,6.091,0,0,1,5.527-.032l19.127,10.98c1.525.875,1.536,2.326.024,3.223l-13.007,7.721a6.043,6.043,0,0,1-5.517.034L230.9,264.834c-1.522-.879-1.529-2.329-.014-3.221Z"
                fill="url(#linear-gradient-6)"
              />
              <g id="그룹_58" data-name="그룹 58">
                <path
                  id="패스_308"
                  data-name="패스 308"
                  d="M243.879,252.849a6.091,6.091,0,0,1,5.527-.032l19.127,10.98c1.525.875,1.536,2.326.024,3.224l-13.007,7.72a6.043,6.043,0,0,1-5.517.034L230.9,263.727c-1.522-.879-1.529-2.329-.014-3.221Z"
                  fill="#fff"
                />
              </g>
              <path
                id="패스_309"
                data-name="패스 309"
                d="M232.51,261.33l10.383,6.138.649-.388-10.382-6.116Z"
                fill="#d3daed"
              />
              <path
                id="패스_310"
                data-name="패스 310"
                d="M234.75,260.122l20.767,12.254.649-.388L235.4,259.757Z"
                fill="#d3daed"
              />
              <path
                id="패스_311"
                data-name="패스 311"
                d="M236.89,258.854l20.767,12.254.649-.388L237.54,258.488Z"
                fill="#d3daed"
              />
              <path
                id="패스_312"
                data-name="패스 312"
                d="M239.061,257.616l20.767,12.254.649-.388L239.711,257.25Z"
                fill="#d3daed"
              />
              <path
                id="패스_313"
                data-name="패스 313"
                d="M241.231,256.354,262,268.608l.649-.388-20.765-12.232Z"
                fill="#d3daed"
              />
              <path
                id="패스_314"
                data-name="패스 314"
                d="M243.4,255.116l20.767,12.254.649-.388L244.052,254.75Z"
                fill="#d3daed"
              />
              <path
                id="패스_315"
                data-name="패스 315"
                d="M245.644,254.368l20.018,11.887.632-.378-20.017-11.866Z"
                fill="#d3daed"
              />
            </g>
            <g id="그룹_62" data-name="그룹 62">
              <ellipse
                id="타원_2"
                data-name="타원 2"
                cx="11.47"
                cy="6.622"
                rx="11.47"
                ry="6.622"
                transform="translate(244.256 202.447)"
                fill="url(#radial-gradient)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <g id="그룹_145" data-name="그룹 145">
                <g id="그룹_61" data-name="그룹 61">
                  <path
                    id="패스_316"
                    data-name="패스 316"
                    d="M266.809,205.175c0-.235-.332-.426-.741-.425h-1.582c-.262,0-.7.276-.761.193-.108-.137-.069-.3.116-.4l1.118-.644c.289-.167-1.549-.089-1.839-.256l.016-1.4a1.162,1.162,0,0,0-1.048,0l-1.127.649a.965.965,0,0,1-.674.074c-.161-.039-.335-.079-.484-.113-.235-.053-.388-.182-.388-.327v-.919c0-.236-.331-.427-.74-.427H256.1c-.409,0-.74.191-.74.427v.91c0,.15-.169.279-.414.333-.156.033-.308.07-.458.109a.989.989,0,0,1-.7-.065l-1.122-.642a1.16,1.16,0,0,0-1.047,0l-.134,1.517c-.288.167-1.971-.029-1.681.137l1.131.647c.178.1.229.255.131.388-.068.093-.512-.18-.763-.18l-1.6,0c-.409,0-.74.192-.738.427l.006,2.166c0,.235.333.426.742.425h.928l-.022,1.788a.242.242,0,0,0,0,.093h0a.386.386,0,0,0,.211.259l1.826,1.046a1.118,1.118,0,0,0,.649.111l.013.006v-.008a.982.982,0,0,0,.385-.11l1.125-.65a.972.972,0,0,1,.675-.075c.16.04.334.08.484.113.235.052.388.182.388.327l0,.92c0,.235.333.425.742.425l2.577,0c.409,0,.74-.192.739-.427l0-.91c0-.151.169-.281.413-.334.148-.032.294-.067.437-.1a.991.991,0,0,1,.7.067l1.12.643a1,1,0,0,0,.385.108v.013l.019-.01a1.126,1.126,0,0,0,.644-.112l1.82-1.05a.4.4,0,0,0,.207-.246l.009,0V209.6l-.023-1.829h.911c.409,0,.741-.192.74-.427Zm-5.83,1.807c-.429,1.311-2.789,2.052-4.911,1.582-1.612-.358-2.594-1.362-2.275-2.338.429-1.311,2.789-2.052,4.911-1.581C260.316,205,261.3,206.005,260.979,206.982Z"
                    fill="#99a7d8"
                  />
                  <path
                    id="패스_317"
                    data-name="패스 317"
                    d="M265.184,202.71l-.008-1.163h-1.669l-.371-.213a1.159,1.159,0,0,0-1.048,0l-.37.213h-2.3v-.85c0-.235-.331-.426-.74-.426H256.1c-.409,0-.74.191-.74.426v.85h-2.341l-.357-.2a1.16,1.16,0,0,0-1.047,0l-.35.2h-1.681v1.138a.357.357,0,0,0,.216.314l1.131.647,12.913-.017,1.118-.644a.383.383,0,0,0,.213-.275Z"
                    fill="#99a7d8"
                  />
                  <g id="그룹_60" data-name="그룹 60">
                    <path
                      id="패스_318"
                      data-name="패스 318"
                      d="M264.05,205.721h2.021c.409,0,.741-.191.74-.427l0-1.483c0-.235-.332-.426-.741-.425h-1.582a.7.7,0,0,1-.579-.238c-.056-.085-.116-.169-.182-.251-.108-.138-.069-.3.116-.4l1.118-.644a.318.318,0,0,0,0-.6l-1.823-1.048a1.165,1.165,0,0,0-1.048,0l-1.127.649a.965.965,0,0,1-.674.074c-.161-.039-.335-.079-.484-.113-.235-.052-.388-.182-.388-.327v-.919c0-.236-.331-.427-.74-.427H256.1c-.409,0-.74.191-.74.427v.91c0,.15-.169.28-.414.333-.156.033-.308.07-.458.11a.989.989,0,0,1-.7-.066l-1.122-.642a1.16,1.16,0,0,0-1.047,0l-1.818,1.052a.317.317,0,0,0,0,.6l1.131.647c.178.1.229.255.131.388-.068.093-.138.193-.2.279a.7.7,0,0,1-.568.223l-1.6,0c-.409,0-.74.192-.738.427l.006,1.483c0,.236.333.426.742.425l1.582,0a.7.7,0,0,1,.58.238c.056.085.117.168.182.251.11.137.071.3-.114.4l-1.117.646a.317.317,0,0,0,0,.6l1.826,1.047a1.163,1.163,0,0,0,1.047,0l1.125-.65a.962.962,0,0,1,.675-.075c.16.039.334.079.484.112.235.053.388.182.388.327l0,.92c0,.235.333.426.742.425l2.577,0c.409,0,.74-.192.739-.427l0-.91c0-.151.169-.28.413-.334.148-.032.294-.067.437-.1a.985.985,0,0,1,.7.066l1.12.643a1.162,1.162,0,0,0,1.048,0l1.82-1.05a.318.318,0,0,0,0-.6l-1.129-.647c-.177-.1-.228-.256-.129-.389C263.873,206,264.042,205.737,264.05,205.721Zm-10.257-1.543c.429-1.311,2.789-2.052,4.911-1.581,1.612.357,2.594,1.361,2.275,2.338-.429,1.311-2.789,2.051-4.911,1.581C254.456,206.158,253.474,205.155,253.793,204.178Z"
                      fill="#d3daed"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="그룹_63"
              data-name="그룹 63"
              style={{ mixBlendMode: "multiply", isolation: "isolate" }}
            >
              <ellipse
                id="타원_3"
                data-name="타원 3"
                cx="17.946"
                cy="10.361"
                rx="17.946"
                ry="10.361"
                transform="translate(273.318 273.87)"
                fill="url(#radial-gradient-2)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <path
                id="패스_319"
                data-name="패스 319"
                d="M303.854,280.271h-1.436a9.387,9.387,0,0,0-2.309-1.823c-4.995-2.883-13.168-2.883-18.162,0a9.375,9.375,0,0,0-2.31,1.823H278.2v3.393h0c-.012,1.91,1.236,3.821,3.745,5.27,4.994,2.884,13.167,2.884,18.162,0,2.509-1.449,3.756-3.36,3.744-5.27h0Z"
                fill="#e5e9f4"
              />
              <path
                id="패스_320"
                data-name="패스 320"
                d="M281.947,278.448a9.375,9.375,0,0,0-2.31,1.823H278.2v3.393h0c-.012,1.91,1.236,3.821,3.745,5.27.077.044.157.086.235.129v-3.419l2.466-8.385A14.49,14.49,0,0,0,281.947,278.448Z"
                fill="#ffc107"
              />
              <path
                id="패스_321"
                data-name="패스 321"
                d="M281.947,275.029c4.994-2.884,13.167-2.884,18.162,0s4.994,7.6,0,10.485-13.168,2.884-18.162,0S276.952,277.912,281.947,275.029Z"
                fill="#fafbfd"
              />
              <path
                id="패스_322"
                data-name="패스 322"
                d="M291.028,272.866a18.58,18.58,0,0,0-9.081,2.163c-4.995,2.883-4.995,7.6,0,10.485.077.045.157.086.235.13l8.846-4.21Z"
                fill="#ffcd39"
              />
              <path
                id="패스_323"
                data-name="패스 323"
                d="M278.371,281.434h12.657v-8.568a18.58,18.58,0,0,0-9.081,2.163C278.938,276.765,277.755,279.167,278.371,281.434Z"
                fill="#48486a"
              />
            </g>
            <g id="그룹_78" data-name="그룹 78">
              <g id="그룹_74" data-name="그룹 74">
                <circle
                  id="타원_4"
                  data-name="타원 4"
                  cx="2.015"
                  cy="2.015"
                  r="2.015"
                  transform="translate(261.371 117.143)"
                  fill="#363650"
                />
                <path
                  id="패스_324"
                  data-name="패스 324"
                  d="M261.852,121.727a2.015,2.015,0,1,0,2.015-2.015A2.015,2.015,0,0,0,261.852,121.727Z"
                  fill="#363650"
                />
                <path
                  id="패스_325"
                  data-name="패스 325"
                  d="M271.892,117.483l-.182,5.121a3.943,3.943,0,0,1-2.16,3.66h0a4.685,4.685,0,0,1-7.024-3.909v-4.872a4.486,4.486,0,0,1,2.342-3.909h0A4.685,4.685,0,0,1,271.892,117.483Z"
                  fill="#363650"
                />
                <g id="그룹_64" data-name="그룹 64">
                  <path
                    id="패스_326"
                    data-name="패스 326"
                    d="M282.594,149.252a1.693,1.693,0,0,0,.9-3.129l-12.92-8.074V134.41a1.693,1.693,0,1,0-3.386,0v5.515l14.512,9.069A1.689,1.689,0,0,0,282.594,149.252Z"
                    fill="#f2b1b5"
                  />
                </g>
                <path
                  id="패스_327"
                  data-name="패스 327"
                  d="M278.755,188.621l0,1.3a1.03,1.03,0,0,1-.873,1.021l-.218.034a2.733,2.733,0,0,1-1.843-.37l-6.7-4.1.042-1.928,7.757,5.132Z"
                  fill="#48486a"
                />
                <path
                  id="패스_328"
                  data-name="패스 328"
                  d="M273.862,182.642l.662,2.52,3.5,2.266a1.509,1.509,0,0,1-.02,2.607h0a1.512,1.512,0,0,1-1.505,0l-7.351-4.617.018-2.774Z"
                  fill="#f1f2f2"
                />
                <path
                  id="패스_329"
                  data-name="패스 329"
                  d="M269.062,194.424l0,1.3a1.031,1.031,0,0,1-.874,1.021l-.217.034a2.741,2.741,0,0,1-1.844-.37l-6.7-4.1.043-1.927,7.756,5.132Z"
                  fill="#48486a"
                />
                <path
                  id="패스_330"
                  data-name="패스 330"
                  d="M264.17,188.446l.661,2.52,3.5,2.265a1.51,1.51,0,0,1-.02,2.608h0a1.509,1.509,0,0,1-1.505,0l-7.352-4.616.019-2.774Z"
                  fill="#f1f2f2"
                />
                <path
                  id="패스_331"
                  data-name="패스 331"
                  d="M273.846,134.41v48.52a2.362,2.362,0,0,1-1.181,2.046h0a2.363,2.363,0,0,1-3.544-2.046l-2.442-45.246Z"
                  fill="#48486a"
                />
                <path
                  id="패스_332"
                  data-name="패스 332"
                  d="M273.235,134.689v48.24a2.364,2.364,0,0,1-2.055,2.344,2.333,2.333,0,0,0,1.485-.3,2.364,2.364,0,0,0,1.181-2.047V134.41Z"
                  fill="#5c629e"
                  opacity="0.15"
                />
                <path
                  id="패스_333"
                  data-name="패스 333"
                  d="M267.387,150.8l.94,17.407,5.52-12.086V134.41l-6.46,2.951Z"
                  fill="#363650"
                />
                <path
                  id="패스_334"
                  data-name="패스 334"
                  d="M267.39,140.452l-3.154,48.039a2.365,2.365,0,0,1-1.181,2.047h0a2.363,2.363,0,0,1-3.544-2.047V142.7Z"
                  fill="#48486a"
                />
                <path
                  id="패스_335"
                  data-name="패스 335"
                  d="M273.846,134.47v20.216a3.2,3.2,0,0,1-1.6,2.774l-10.34,5.97a1.6,1.6,0,0,1-2.394-1.382V141.831a3.2,3.2,0,0,1,1.6-2.774l10.34-5.969A1.6,1.6,0,0,1,273.846,134.47Z"
                  fill="#48486a"
                />
                <path
                  id="패스_336"
                  data-name="패스 336"
                  d="M274.508,130.547v16.261c0,2.207.156,5.693-1.755,6.8,0,0-6.555,6.049-13.789,5.579,0,0-1.919-18.069,0-24.751.113-.39.279-.923.49-1.485a6.3,6.3,0,0,1,3.4-3.553L268.364,127A4.1,4.1,0,0,1,274.508,130.547Z"
                  fill="#5c629e"
                />
                <g id="그룹_65" data-name="그룹 65">
                  <path
                    id="패스_337"
                    data-name="패스 337"
                    d="M275.339,153.6a1.691,1.691,0,0,0,1.505-.915,1.753,1.753,0,0,0-.681-2.26L263.317,142.4v-3.576a1.733,1.733,0,0,0-1.357-1.723,1.694,1.694,0,0,0-2.028,1.66v3.468a3.693,3.693,0,0,0,1.737,3.133l12.775,7.984A1.687,1.687,0,0,0,275.339,153.6Z"
                    fill="#f2b1b5"
                  />
                </g>
                <g id="그룹_66" data-name="그룹 66">
                  <path
                    id="패스_338"
                    data-name="패스 338"
                    d="M261.456,143.3a1.861,1.861,0,0,0,1.861-1.861v-6.865a1.861,1.861,0,1,0-3.722,0v6.865A1.861,1.861,0,0,0,261.456,143.3Z"
                    fill="#5c629e"
                  />
                </g>
                <g id="그룹_67" data-name="그룹 67">
                  <path
                    id="패스_339"
                    data-name="패스 339"
                    d="M265.911,131.976A1.693,1.693,0,0,0,267.6,130.3l.058-5.412a1.693,1.693,0,0,0-3.385-.037l-.058,5.413a1.692,1.692,0,0,0,1.674,1.711Z"
                    fill="#f2b1b5"
                  />
                </g>
                <path
                  id="패스_340"
                  data-name="패스 340"
                  d="M264.273,128.087v-3l0-.231a1.693,1.693,0,0,1,3.385.037l-.039,3.673c-.542.161-1.114-.233-1.707-.233Z"
                  fill="#231f20"
                  opacity="0.3"
                />
                <path
                  id="패스_341"
                  data-name="패스 341"
                  d="M264.273,128.087h3.789a3.83,3.83,0,0,0,3.83-3.83v-6.39h-4.2a3.416,3.416,0,0,0-3.417,3.416Z"
                  fill="#f2b1b5"
                />
                <path
                  id="패스_342"
                  data-name="패스 342"
                  d="M271.281,117.867v6.39a3.831,3.831,0,0,1-3.831,3.83h.612a3.83,3.83,0,0,0,3.83-3.83v-6.39Z"
                  fill="#f5c5c8"
                />
                <path
                  id="패스_343"
                  data-name="패스 343"
                  d="M263.387,120.609a.887.887,0,1,0,.886-.886A.887.887,0,0,0,263.387,120.609Z"
                  fill="#f2b1b5"
                />
                <rect
                  id="사각형_3"
                  data-name="사각형 3"
                  width="1.283"
                  height="5.136"
                  rx="0.628"
                  transform="translate(265.939 121.727) rotate(180)"
                  fill="#363650"
                />
                <path
                  id="패스_344"
                  data-name="패스 344"
                  d="M269.585,117.143a1.45,1.45,0,1,0,1.45-1.449A1.45,1.45,0,0,0,269.585,117.143Z"
                  fill="#363650"
                />
                <path
                  id="패스_345"
                  data-name="패스 345"
                  d="M266.633,116.818a1.45,1.45,0,1,0,1.449-1.449A1.45,1.45,0,0,0,266.633,116.818Z"
                  fill="#363650"
                />
                <path
                  id="패스_346"
                  data-name="패스 346"
                  d="M264.518,117.384a1.449,1.449,0,1,0,1.449-1.449A1.45,1.45,0,0,0,264.518,117.384Z"
                  fill="#363650"
                />
                <path
                  id="패스_347"
                  data-name="패스 347"
                  d="M269.019,115.369a2.015,2.015,0,1,0,2.016-2.015A2.016,2.016,0,0,0,269.019,115.369Z"
                  fill="#363650"
                />
                <path
                  id="패스_348"
                  data-name="패스 348"
                  d="M266.067,114.56a2.016,2.016,0,1,0,2.015-2.015A2.015,2.015,0,0,0,266.067,114.56Z"
                  fill="#363650"
                />
                <path
                  id="패스_349"
                  data-name="패스 349"
                  d="M263.867,114.56a2.015,2.015,0,1,0,2.015-2.015A2.015,2.015,0,0,0,263.867,114.56Z"
                  fill="#363650"
                />
                <path
                  id="패스_350"
                  data-name="패스 350"
                  d="M261.852,116.575a2.015,2.015,0,1,0,2.015-2.015A2.015,2.015,0,0,0,261.852,116.575Z"
                  fill="#363650"
                />
                <g id="그룹_69" data-name="그룹 69">
                  <path
                    id="패스_351"
                    data-name="패스 351"
                    d="M278.116,136.718a4.607,4.607,0,0,0-4.18-.024L259.467,145a1.293,1.293,0,0,0-.018,2.438l9.839,5.84a4.571,4.571,0,0,0,4.173.026l14.475-8.357a1.292,1.292,0,0,0,.011-2.437Z"
                    fill="url(#linear-gradient)"
                  />
                  <g id="그룹_68" data-name="그룹 68">
                    <path
                      id="패스_352"
                      data-name="패스 352"
                      d="M278.116,135.881a4.607,4.607,0,0,0-4.18-.024l-14.469,8.305a1.294,1.294,0,0,0-.018,2.439l9.839,5.84a4.571,4.571,0,0,0,4.173.025l14.475-8.357a1.292,1.292,0,0,0,.011-2.436Z"
                      fill="#f1f2f2"
                    />
                  </g>
                </g>
                <g id="그룹_71" data-name="그룹 71">
                  <path
                    id="패스_353"
                    data-name="패스 353"
                    d="M276.442,135.881a4.607,4.607,0,0,0-4.18-.024l-14.469,8.306a1.293,1.293,0,0,0-.018,2.438l9.839,5.84a4.571,4.571,0,0,0,4.173.026l14.475-8.357a1.292,1.292,0,0,0,.011-2.437Z"
                    fill="url(#linear-gradient)"
                  />
                  <g id="그룹_70" data-name="그룹 70">
                    <path
                      id="패스_354"
                      data-name="패스 354"
                      d="M276.442,135.044a4.607,4.607,0,0,0-4.18-.024l-14.469,8.3a1.294,1.294,0,0,0-.018,2.439l9.839,5.84a4.571,4.571,0,0,0,4.173.025l14.475-8.357a1.292,1.292,0,0,0,.011-2.436Z"
                      fill="url(#linear-gradient-9)"
                    />
                  </g>
                </g>
                <g id="그룹_73" data-name="그룹 73">
                  <path
                    id="패스_355"
                    data-name="패스 355"
                    d="M278.116,132.534a4.607,4.607,0,0,0-4.18-.023l-14.469,8.3a1.293,1.293,0,0,0-.018,2.438l9.839,5.841a4.571,4.571,0,0,0,4.173.025l14.475-8.357a1.292,1.292,0,0,0,.011-2.437Z"
                    fill="url(#linear-gradient)"
                  />
                  <g id="그룹_72" data-name="그룹 72">
                    <path
                      id="패스_356"
                      data-name="패스 356"
                      d="M278.116,131.7a4.607,4.607,0,0,0-4.18-.024l-14.469,8.306a1.293,1.293,0,0,0-.018,2.438l9.839,5.84a4.571,4.571,0,0,0,4.173.026l14.475-8.357a1.292,1.292,0,0,0,.011-2.437Z"
                      fill="#f1f2f2"
                    />
                  </g>
                </g>
              </g>
              <g id="그룹_77" data-name="그룹 77">
                <path
                  id="패스_357"
                  data-name="패스 357"
                  d="M265.211,138.48a15.5,15.5,0,0,1,13.967,0c3.841,2.218,3.841,5.847,0,8.064a15.49,15.49,0,0,1-13.967,0C261.37,144.327,261.37,140.7,265.211,138.48Z"
                  fill="url(#radial-gradient-3)"
                  style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                />
                <g id="그룹_76" data-name="그룹 76">
                  <path
                    id="패스_358"
                    data-name="패스 358"
                    d="M281.737,139.16c0-.2-.286-.367-.639-.367h-1.361c-.226,0-.6.237-.655.166-.094-.118-.06-.257.1-.348l.963-.555c.249-.143-1.334-.077-1.583-.22l.013-1.2a1,1,0,0,0-.9,0l-.97.558a.832.832,0,0,1-.581.064c-.139-.033-.288-.068-.417-.1-.2-.045-.333-.157-.333-.282v-.791c0-.2-.286-.367-.638-.367h-2.219c-.353,0-.638.164-.638.367v.783c0,.13-.146.241-.357.287-.133.029-.264.06-.394.1a.85.85,0,0,1-.605-.057l-.967-.552a1,1,0,0,0-.9,0l-.114,1.306c-.249.144-1.7-.024-1.448.118l.974.557c.153.088.2.22.113.335-.059.079-.441-.156-.658-.156l-1.376,0c-.352,0-.637.165-.636.368l0,1.865c0,.2.288.366.64.366h.8l-.02,1.54a.233.233,0,0,0,0,.079h0a.339.339,0,0,0,.182.224l1.572.9a.971.971,0,0,0,.56.094l.01.006v-.007a.851.851,0,0,0,.332-.094l.969-.56a.837.837,0,0,1,.581-.065c.138.034.288.069.417.1.2.046.334.157.334.282l0,.792c0,.2.287.366.639.366l2.219,0c.352,0,.637-.165.637-.368l0-.783c0-.13.145-.242.356-.288.127-.027.252-.057.376-.09a.855.855,0,0,1,.6.057l.964.554a.873.873,0,0,0,.331.094v.01l.017-.008a.96.96,0,0,0,.554-.1l1.568-.9a.339.339,0,0,0,.178-.211l.007,0v-.036a.091.091,0,0,0,0-.017l-.019-1.575h.784c.353,0,.638-.165.638-.367Zm-5.02,1.556c-.369,1.129-2.4,1.766-4.229,1.361-1.388-.308-2.233-1.172-1.958-2.013.369-1.129,2.4-1.766,4.228-1.361C276.146,139.01,276.992,139.874,276.717,140.716Z"
                    fill="#363650"
                  />
                  <path
                    id="패스_359"
                    data-name="패스 359"
                    d="M280.337,137.037l-.007-1h-1.437l-.319-.184a1,1,0,0,0-.9,0l-.319.184h-1.982V135.3c0-.2-.286-.367-.638-.367h-2.219c-.353,0-.638.164-.638.367v.732h-2.015l-.308-.176a1,1,0,0,0-.9,0l-.3.175H266.9v.979a.308.308,0,0,0,.186.271l.974.557,11.118-.015.963-.554a.33.33,0,0,0,.183-.237Z"
                    fill="#363650"
                  />
                  <g id="그룹_75" data-name="그룹 75">
                    <path
                      id="패스_360"
                      data-name="패스 360"
                      d="M279.361,139.63h1.74c.353,0,.638-.165.638-.368l0-1.277c0-.2-.286-.366-.639-.366h-1.361a.6.6,0,0,1-.5-.205q-.072-.109-.156-.216c-.094-.118-.06-.257.1-.349l.963-.554a.274.274,0,0,0,0-.519l-1.57-.9a1,1,0,0,0-.9,0l-.97.558a.832.832,0,0,1-.581.064c-.139-.034-.288-.068-.417-.1-.2-.045-.333-.157-.333-.282v-.791c0-.2-.286-.367-.638-.367h-2.219c-.353,0-.638.164-.638.367v.783c0,.13-.146.241-.357.287-.133.029-.264.06-.394.094a.845.845,0,0,1-.605-.056l-.967-.553a1,1,0,0,0-.9,0l-1.564.9a.273.273,0,0,0,0,.519l.974.557c.153.088.2.22.113.334-.059.08-.119.166-.169.24a.6.6,0,0,1-.489.193l-1.376,0c-.352,0-.637.165-.636.368l0,1.277c0,.2.288.366.64.366l1.362,0a.6.6,0,0,1,.5.2c.048.073.1.145.157.217.094.118.061.256-.1.348l-.962.555a.274.274,0,0,0,0,.519l1.572.9a1,1,0,0,0,.9,0l.969-.56a.837.837,0,0,1,.581-.065c.138.034.288.068.417.1.2.045.334.157.334.282l0,.791c0,.2.287.367.639.367l2.219,0c.352,0,.637-.165.637-.368l0-.783c0-.13.145-.242.356-.288.127-.027.252-.057.376-.09a.855.855,0,0,1,.6.057l.964.554a1,1,0,0,0,.9,0l1.568-.9a.274.274,0,0,0,0-.519l-.972-.558c-.153-.087-.2-.219-.112-.334C279.209,139.87,279.354,139.643,279.361,139.63ZM270.53,138.3c.369-1.129,2.4-1.767,4.228-1.361,1.388.307,2.234,1.171,1.959,2.013-.369,1.128-2.4,1.766-4.229,1.361C271.1,140.006,270.255,139.142,270.53,138.3Z"
                      fill="#48486a"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="그룹_80" data-name="그룹 80" opacity="0.3">
              <g id="그룹_79" data-name="그룹 79">
                <path
                  id="패스_361"
                  data-name="패스 361"
                  d="M334.661,251.713a4.441,4.441,0,0,0,.581,4.332,4.012,4.012,0,0,0,1.147.99l8.41,3.9.718.334,2.378,1.1a7.584,7.584,0,0,1,3.764,6.091l5.484-3.134a13.578,13.578,0,0,0-5.45-7.594l.767-.44,2.28,1.208a7.585,7.585,0,0,1,3.761,6.049l5.479-3.132a13.605,13.605,0,0,0-2.744-5.137c-.226-.264-.458-.522-.7-.769s-.5-.479-.765-.706a13.579,13.579,0,0,0-1.262-.966l.229-.127-.007,0,.119-.069a1.242,1.242,0,0,0,.564-.973l1.192-8.9a.4.4,0,0,0-.162-.361h0l-.012,0-.167-.048a.643.643,0,0,0-.5.06l-7.578,4.337L335.4,249.766Z"
                  fill="#231f20"
                />
              </g>
            </g>
            <g id="그룹_95" data-name="그룹 95">
              <path
                id="패스_362"
                data-name="패스 362"
                d="M349.358,249.115a4.365,4.365,0,1,1-4.364-4.365A4.364,4.364,0,0,1,349.358,249.115Z"
                fill="#ffc107"
              />
              <path
                id="패스_363"
                data-name="패스 363"
                d="M360.015,279.9v2.209a1.747,1.747,0,0,0,.831,1.489l6.718,4.151a2.175,2.175,0,0,0,2.273.009l2.056-1.164a1.3,1.3,0,0,0,.658-1.134l-.005-1.269a.874.874,0,0,1-.7-.156l-4.471-2.709V279.9Z"
                fill="#48486a"
              />
              <path
                id="패스_364"
                data-name="패스 364"
                d="M360.015,278.668v2.209a1.749,1.749,0,0,0,.831,1.489l6.718,4.151a2.177,2.177,0,0,0,2.273.009l2.014-1.224a1.461,1.461,0,0,0,0-2.5l-4.471-2.709v-1.426Z"
                fill="#fff"
              />
              <g id="그룹_81" data-name="그룹 81">
                <path
                  id="패스_365"
                  data-name="패스 365"
                  d="M364.543,282.908v-.445l2.584-1.492.006.405Z"
                  fill="#48486a"
                />
              </g>
              <g id="그룹_82" data-name="그룹 82">
                <path
                  id="패스_366"
                  data-name="패스 366"
                  d="M365.517,283.773v-.424l2.585-1.492.016.378Z"
                  fill="#48486a"
                />
              </g>
              <g id="그룹_83" data-name="그룹 83">
                <path
                  id="패스_367"
                  data-name="패스 367"
                  d="M366.81,284.682v-.426l2.585-1.492-.014.424Z"
                  fill="#48486a"
                />
              </g>
              <ellipse
                id="타원_5"
                data-name="타원 5"
                cx="3.687"
                cy="2.128"
                rx="3.687"
                ry="2.128"
                transform="translate(360.011 276.539)"
                fill="#48486a"
              />
              <ellipse
                id="타원_6"
                data-name="타원 6"
                cx="2.432"
                cy="1.404"
                rx="2.432"
                ry="1.404"
                transform="translate(361.265 277.263)"
                fill="#363650"
              />
              <path
                id="패스_368"
                data-name="패스 368"
                d="M353.15,283.986V286.2a1.751,1.751,0,0,0,.831,1.489l6.718,4.151a2.174,2.174,0,0,0,2.273.009l2.057-1.164a1.3,1.3,0,0,0,.658-1.134l-.005-1.269a.876.876,0,0,1-.7-.156l-4.47-2.709v-1.426Z"
                fill="#48486a"
              />
              <path
                id="패스_369"
                data-name="패스 369"
                d="M353.15,282.755v2.209a1.751,1.751,0,0,0,.831,1.489L360.7,290.6a2.175,2.175,0,0,0,2.273.009l2.015-1.224a1.461,1.461,0,0,0,0-2.5l-4.47-2.709v-1.426Z"
                fill="#fff"
              />
              <g id="그룹_84" data-name="그룹 84">
                <path
                  id="패스_370"
                  data-name="패스 370"
                  d="M357.679,287v-.445l2.584-1.492.005.405Z"
                  fill="#48486a"
                />
              </g>
              <g id="그룹_85" data-name="그룹 85">
                <path
                  id="패스_371"
                  data-name="패스 371"
                  d="M358.652,287.86v-.424l2.585-1.492.016.378Z"
                  fill="#48486a"
                />
              </g>
              <g id="그룹_86" data-name="그룹 86">
                <path
                  id="패스_372"
                  data-name="패스 372"
                  d="M359.945,288.769v-.426l2.585-1.492-.014.424Z"
                  fill="#48486a"
                />
              </g>
              <path
                id="패스_373"
                data-name="패스 373"
                d="M354.226,284.26c-1.434-.828-1.434-2.182,0-3.01a5.781,5.781,0,0,1,5.213,0c1.434.828,1.434,2.182,0,3.01A5.781,5.781,0,0,1,354.226,284.26Z"
                fill="#48486a"
              />
              <path
                id="패스_374"
                data-name="패스 374"
                d="M355.113,283.748a1.05,1.05,0,0,1,0-1.986,3.815,3.815,0,0,1,3.439,0,1.05,1.05,0,0,1,0,1.986A3.815,3.815,0,0,1,355.113,283.748Z"
                fill="#363650"
              />
              <g id="그룹_87" data-name="그룹 87">
                <path
                  id="패스_375"
                  data-name="패스 375"
                  d="M363.643,280.191a2.975,2.975,0,0,1-2.976-2.976V262.944a7.6,7.6,0,0,0-3.8-6.566l-11.208-5.939c-2.293-1.32-2.825-3.784-2.825-6.431v-.646a2.976,2.976,0,1,1,5.952,0v.646a1.472,1.472,0,0,0,.736,1.273l10.315,5.939a13.564,13.564,0,0,1,6.778,11.724v14.271A2.975,2.975,0,0,1,363.643,280.191Z"
                  fill="url(#linear-gradient-11)"
                />
              </g>
              <path
                id="패스_376"
                data-name="패스 376"
                d="M359.841,251.22l-10.315-5.939a1.472,1.472,0,0,1-.736-1.273v-.646a2.9,2.9,0,0,0-3.4-2.934,2.969,2.969,0,0,1,2.55,2.934v.646a1.472,1.472,0,0,0,.736,1.273l10.315,5.939a13.564,13.564,0,0,1,6.778,11.724v14.271a2.967,2.967,0,0,1-2.55,2.933,2.9,2.9,0,0,0,3.4-2.933V262.944A13.564,13.564,0,0,0,359.841,251.22Z"
                fill="#ffc107"
                opacity="0.45"
              />
              <g id="그룹_88" data-name="그룹 88">
                <path
                  id="패스_377"
                  data-name="패스 377"
                  d="M356.8,284.06a2.975,2.975,0,0,1-2.976-2.976V266.813a7.6,7.6,0,0,0-3.8-6.566L338.52,254.9c-2.293-1.32-2.527-4.379-2.527-7.026v-.647a2.976,2.976,0,1,1,5.952,0v.647a1.472,1.472,0,0,0,.736,1.273L353,255.089a13.563,13.563,0,0,1,6.778,11.724v14.271A2.975,2.975,0,0,1,356.8,284.06Z"
                  fill="url(#linear-gradient-11)"
                />
              </g>
              <g id="그룹_89" data-name="그룹 89">
                <path
                  id="패스_378"
                  data-name="패스 378"
                  d="M347.707,258.895l-8-3.657a1.12,1.12,0,0,1-.022-1.951l12.185-7.043,9,5.346Z"
                  fill="#efedf0"
                />
                <path
                  id="패스_379"
                  data-name="패스 379"
                  d="M348.8,258.188l-6.977-3.11a.966.966,0,0,1-.019-1.683l11.147-6.443,7.762,4.612Z"
                  fill="#fbfafb"
                />
              </g>
              <g id="그룹_90" data-name="그룹 90">
                <g id="그룹_151" data-name="그룹 151">
                  <path
                    id="패스_380"
                    data-name="패스 380"
                    d="M352.527,251.23a1.786,1.786,0,0,1-1.786-1.786v-6.951c0-4.128-.569-6.106-1.691-10.078l-2.081-7.37a1.785,1.785,0,1,1,3.436-.97l2.082,7.369c1.211,4.289,1.825,6.593,1.825,11.049v6.951A1.785,1.785,0,0,1,352.527,251.23Z"
                    fill="#5c629e"
                  />
                </g>
              </g>
              <path
                id="패스_381"
                data-name="패스 381"
                d="M352.487,231.444l-2.082-7.369a1.784,1.784,0,0,0-2.153-1.242,1.775,1.775,0,0,1,1.3,1.242l2.082,7.369c1.211,4.289,1.825,6.593,1.825,11.049v6.951a1.782,1.782,0,0,1-1.359,1.729,1.758,1.758,0,0,0,.426.057,1.785,1.785,0,0,0,1.785-1.786v-6.951C354.312,238.037,353.7,235.733,352.487,231.444Z"
                fill="#fff"
                opacity="0.15"
              />
              <path
                id="패스_382"
                data-name="패스 382"
                d="M343,218.93l-1.469.848a18,18,0,0,0-8.868,13.322c-.812,6.408,1.692,18.24,1.692,18.24,7.357-.415,14.863-7.823,14.863-7.823-1.3-8.274,1.344-12.466,1.4-18.018C350.643,222.563,346.519,216.9,343,218.93Z"
                fill="#5c629e"
              />
              <path
                id="패스_383"
                data-name="패스 383"
                d="M350.615,225.5c.028-2.936-4.1-8.6-7.617-6.569l-1.469.848a18.02,18.02,0,0,0-4.088,3.228,18.078,18.078,0,0,1,3.662-2.8l1.469-.848c3.521-2.032,7.645,3.633,7.617,6.57-.055,5.547-2.691,9.732-1.4,17.995.27-.249.429-.4.429-.4C347.918,235.243,350.56,231.051,350.615,225.5Z"
                fill="#fff"
                opacity="0.15"
              />
              <path
                id="사각형_4"
                data-name="사각형 4"
                d="M0,0H3.393a0,0,0,0,1,0,0V3.888A1.661,1.661,0,0,1,1.733,5.549H1.661A1.661,1.661,0,0,1,0,3.888V0A0,0,0,0,1,0,0Z"
                transform="translate(340.629 218.265)"
                fill="#f2b1b5"
              />
              <path
                id="패스_384"
                data-name="패스 384"
                d="M340.629,219.9l-.933.5,1.423,7.981,1.9-4.714Z"
                fill="#fff"
              />
              <path
                id="패스_385"
                data-name="패스 385"
                d="M343.023,223.661l1-5.056h.434l1.921,6.7Z"
                fill="#fff"
              />
              <g id="그룹_92" data-name="그룹 92">
                <path
                  id="패스_387"
                  data-name="패스 387"
                  d="M362.571,241.285l-.18-.052a.649.649,0,0,0-.5.059l-13.21,7.562a1.259,1.259,0,0,0-.567.975l-1.192,9.054a.259.259,0,0,0,.111.214l.2.139,13.583-7.881a1.245,1.245,0,0,0,.564-.974Z"
                  fill="#e1e1e2"
                />
                <path
                  id="패스_388"
                  data-name="패스 388"
                  d="M362.734,241.647c0-.358-.254-.5-.563-.325l-13.329,7.695a1.261,1.261,0,0,0-.567.975l-1.25,8.859c0,.358.25.5.56.328l13.392-7.662a1.24,1.24,0,0,0,.565-.973Z"
                  fill="#f3f2f4"
                />
                <path
                  id="패스_389"
                  data-name="패스 389"
                  d="M356.046,249.516a2.49,2.49,0,0,0-1.334,1.523c-.186.646.108,1.019.655.828a2.5,2.5,0,0,0,1.334-1.523C356.889,249.7,356.594,249.326,356.046,249.516Z"
                  fill="#e8e7e8"
                />
              </g>
              <path
                id="패스_390"
                data-name="패스 390"
                d="M341.111,242.27c-1.158-2.092-2.2-4.5-2.121-6.242,0,0-.194-6.75-1.343-6.806-1.132-.05-1.742-1.249-1.8-.1l-.425,6.706a20.156,20.156,0,0,0,4.158,13.255l.588.683a29.894,29.894,0,0,0,4.325-2.461A36.893,36.893,0,0,1,341.111,242.27Z"
                fill="#231f20"
                opacity="0.33"
              />
              <g id="그룹_150" data-name="그룹 150">
                <g id="그룹_91" data-name="그룹 91">
                  <path
                    id="패스_386"
                    data-name="패스 386"
                    d="M346.03,256.533a1.781,1.781,0,0,1-1.411-.69l-5.339-6.867a19.857,19.857,0,0,1-4.154-13.133l.425-6.706a1.786,1.786,0,0,1,3.567.171l-.425,6.706a16.284,16.284,0,0,0,3.406,10.77l5.339,6.867a1.785,1.785,0,0,1-1.408,2.882Z"
                    fill="#f2b1b5"
                  />
                </g>
                <path
                  id="사각형_5"
                  data-name="사각형 5"
                  d="M.685,0H6.167a.687.687,0,0,1,.687.687v.03a.684.684,0,0,1-.684.684H.685A.685.685,0,0,1,0,.715V.685A.685.685,0,0,1,.685,0Z"
                  transform="translate(341.91 249.39) rotate(20.53)"
                  fill="#f2b1b5"
                />
                <g id="그룹_93" data-name="그룹 93">
                  <path
                    id="패스_391"
                    data-name="패스 391"
                    d="M344.183,249.154l-1.741-2.4s-3.635-6.916-3.452-10.725l.426-6.706a2.083,2.083,0,1,0-4.162-.2l-.426,6.706a20.157,20.157,0,0,0,4.159,13.255l2.372,2.754Z"
                    fill="#5c629e"
                  />
                </g>
              </g>
              <path
                id="사각형_6"
                data-name="사각형 6"
                d="M0,0H9.52a0,0,0,0,1,0,0V4.056a6.435,6.435,0,0,1-6.435,6.435h-.2A2.885,2.885,0,0,1,0,7.606V0A0,0,0,0,1,0,0Z"
                transform="translate(340.207 208.108) rotate(11.294)"
                fill="#f2b1b5"
              />
              <path
                id="패스_392"
                data-name="패스 392"
                d="M340.207,208.108l-.028.136,8.653,1.728-.864,4.331a6.071,6.071,0,0,1-5.6,4.868,6.07,6.07,0,0,0,6.308-4.868l.865-4.331Z"
                fill="#f5c5c8"
              />
              <path
                id="패스_393"
                data-name="패스 393"
                d="M351.066,209.489a1.684,1.684,0,0,0-1.587-1.354,2.8,2.8,0,0,1-2.073-.869,6.111,6.111,0,0,0-3.458-1.6,3.563,3.563,0,0,0-3.479,1.07,1.649,1.649,0,0,0-2.255-.08,4.237,4.237,0,0,0-1.222,2.15,9.628,9.628,0,0,0-.451,4.923,12.1,12.1,0,0,0,.364,1.205c.342,1.005.465,2.057.864,3.052a.738.738,0,0,0,.226.346.5.5,0,0,0,.565-.036,1.2,1.2,0,0,0,.354-.482c.413-.876-.044-1.361-.01-2.259a1.387,1.387,0,0,1,.671-1.2c.577-.293,1.258.008,1.895.121,1.5.262,4.715-1.313,3.8-3.236,2.077.564,5.024.848,5.8-1.751Z"
                fill="#48486a"
              />
              <g id="그룹_94" data-name="그룹 94" opacity="0.15">
                <path
                  id="패스_394"
                  data-name="패스 394"
                  d="M349.479,208.135a2.8,2.8,0,0,1-2.073-.869,6.111,6.111,0,0,0-3.458-1.6,3.563,3.563,0,0,0-3.479,1.07,1.649,1.649,0,0,0-2.255-.08,2.506,2.506,0,0,0-.4.407,1.64,1.64,0,0,1,2.226.1,3.563,3.563,0,0,1,3.479-1.07,6.111,6.111,0,0,1,3.458,1.6,2.8,2.8,0,0,0,2.073.869,1.684,1.684,0,0,1,1.587,1.354,3,3,0,0,1-.54,1.039,2.8,2.8,0,0,0,.966-1.465A1.684,1.684,0,0,0,349.479,208.135Z"
                  fill="#ffc107"
                />
              </g>
            </g>
            <g id="그룹_98" data-name="그룹 98">
              <path
                id="패스_395"
                data-name="패스 395"
                d="M406.9,89.465a4.794,4.794,0,0,0-2.151-3.736L318.873,35.892a1.671,1.671,0,0,0-1.6-.04l-1.338.69.61,116.9a4.794,4.794,0,0,0,2.151,3.736l86.875,51.651,1.332.774Z"
                fill="url(#linear-gradient)"
              />
              <path
                id="패스_396"
                data-name="패스 396"
                d="M405.567,90.442a4.793,4.793,0,0,0-2.152-3.736L317.36,36.736c-1.183-.687-2.151-.13-2.151,1.239V156.359l90.358,52.468Z"
                fill="#f9f7f9"
              />
              <path
                id="패스_397"
                data-name="패스 397"
                d="M403.415,86.706,317.36,36.736c-1.183-.687-2.151-.13-2.151,1.239v5.616l90.358,52.467V90.442A4.793,4.793,0,0,0,403.415,86.706Z"
                fill="#48486a"
              />
              <path
                id="패스_398"
                data-name="패스 398"
                d="M319.986,41.455c-.385-.222-.7-.039-.7.406a1.555,1.555,0,0,0,.7,1.216c.386.223.7.04.7-.406A1.559,1.559,0,0,0,319.986,41.455Z"
                fill="#fff"
              />
              <path
                id="패스_399"
                data-name="패스 399"
                d="M323.043,43.267c-.385-.222-.7-.04-.7.406a1.555,1.555,0,0,0,.7,1.216c.386.223.7.04.7-.406A1.559,1.559,0,0,0,323.043,43.267Z"
                fill="#fff"
              />
              <path
                id="패스_400"
                data-name="패스 400"
                d="M326.1,45.079c-.384-.223-.7-.04-.7.406a1.556,1.556,0,0,0,.7,1.216c.386.223.7.04.7-.406A1.56,1.56,0,0,0,326.1,45.079Z"
                fill="#fff"
              />
              <path
                id="패스_401"
                data-name="패스 401"
                d="M322.312,57.229v34.2l75.435,43.41V100.859Z"
                fill="#e5e9f4"
              />
              <path
                id="패스_402"
                data-name="패스 402"
                d="M348.242,77.02v2.622l46.1,26.72v-2.6Z"
                fill="#fff"
              />
              <g id="그룹_96" data-name="그룹 96">
                <path
                  id="패스_403"
                  data-name="패스 403"
                  d="M322.2,134.891v2.622L397.747,181.4v-2.6Z"
                  fill="#edf0f8"
                />
                <path
                  id="패스_404"
                  data-name="패스 404"
                  d="M322.2,142v2.622l75.544,43.889v-2.6Z"
                  fill="#edf0f8"
                />
                <path
                  id="패스_405"
                  data-name="패스 405"
                  d="M322.2,149.455v2.622l75.544,43.889v-2.6Z"
                  fill="#edf0f8"
                />
              </g>
              <path
                id="패스_406"
                data-name="패스 406"
                d="M348.242,84.124v2.622l46.1,26.721v-2.6Z"
                fill="#fff"
              />
              <path
                id="패스_407"
                data-name="패스 407"
                d="M348.242,91.228V93.85l46.1,26.72v-2.6Z"
                fill="#fff"
              />
              <path
                id="패스_408"
                data-name="패스 408"
                d="M348.242,98.332v2.622l21.591,12.512v-2.6Z"
                fill="#fff"
              />
              <path
                id="패스_409"
                data-name="패스 409"
                d="M322.312,96.032V123.3L344,136.083V108.988Z"
                fill="#edf0f8"
              />
              <path
                id="패스_410"
                data-name="패스 410"
                d="M376.061,127.646v27.271l21.686,12.78V140.6Z"
                fill="#edf0f8"
              />
              <path
                id="패스_411"
                data-name="패스 411"
                d="M334.166,69.419c-5.494-3.172-9.989-.562-9.989,5.8S328.685,89.39,334.2,92.571s10.005.571,9.99-5.8S339.661,72.591,334.166,69.419Z"
                fill="#fff"
              />
              <path
                id="패스_412"
                data-name="패스 412"
                d="M333.613,116.162,344,109.4v-.407l-.353-.211-10.371,6.758L322.917,96.394l-.605-.362v.736l10.365,19.156-10.365,6.754v.625l.18.106,10.524-6.858,10.374,19.173.608.358v-.728Z"
                fill="#fff"
              />
              <path
                id="패스_413"
                data-name="패스 413"
                d="M397.747,140.6l-.088-.053L387.1,147.43l-10.545-19.49-.492-.294v.876l10.441,19.3-10.441,6.8v.294l.447.263,10.333-6.734,10.186,18.826.719.424v-.587l-10.308-19.053,10.308-6.717Z"
                fill="#fff"
              />
              <path
                id="패스_414"
                data-name="패스 414"
                d="M350.252,113.171v27.271l21.685,12.78V126.127Z"
                fill="#bcbec0"
                opacity="0.15"
              />
              <path
                id="패스_415"
                data-name="패스 415"
                d="M344.725,114.947v27.271L366.411,155V127.9Z"
                fill="#808285"
                opacity="0.15"
              />
              <g id="그룹_97" data-name="그룹 97">
                <path
                  id="패스_416"
                  data-name="패스 416"
                  d="M338.438,120.275l-1.421.355,1.421,26.916L358.7,160.681l1.421-.355V133.231Z"
                  fill="#d3daed"
                />
                <path
                  id="패스_417"
                  data-name="패스 417"
                  d="M337.017,120.63V147.9l21.685,12.78V133.587Z"
                  fill="#e5e9f4"
                />
              </g>
              <path
                id="패스_418"
                data-name="패스 418"
                d="M335.927,80.668a1.917,1.917,0,0,0,.605-1.566,5.73,5.73,0,0,0-2.576-4.462c-1.413-.815-2.569-.144-2.569,1.492a5.668,5.668,0,0,0,1.329,3.366,4.121,4.121,0,0,0-2.37,3.729v1.025l7.669,4.486V86.657A9.639,9.639,0,0,0,335.927,80.668Z"
                fill="#c2cae8"
              />
            </g>
            <path
              id="패스_419"
              data-name="패스 419"
              d="M312.641,104.99v-3.27a2.184,2.184,0,0,0-.984-1.705l-11.534-6.669v17.8l10.852,6.265a1.042,1.042,0,0,0,.236.1l.005,0a.528.528,0,0,0,.5.1,1.363,1.363,0,0,0,.927-1.291v-5.6l1.178-.46a.8.8,0,0,0,.7-1.133Z"
              fill="#231f20"
              opacity="0.3"
            />
            <g id="그룹_104" data-name="그룹 104">
              <path
                id="패스_420"
                data-name="패스 420"
                d="M273.657,77.69h0a1.551,1.551,0,0,1,1.861-.235l34.718,20.073a2.185,2.185,0,0,1,.984,1.705V102.5l1.873,4.139a.807.807,0,0,1-.733,1.139h-1.14v6.054a1.365,1.365,0,0,1-.926,1.292h0a.53.53,0,0,1-.5-.1L274.581,94.68a2.2,2.2,0,0,1-1.067-1.664V78.03A.476.476,0,0,1,273.657,77.69Z"
                fill="url(#linear-gradient)"
              />
              <path
                id="패스_421"
                data-name="패스 421"
                d="M273.514,78.312c0-.625.443-.881.984-.569l35.056,20.24a2.184,2.184,0,0,1,.984,1.705v3.271l1.873,4.139a.806.806,0,0,1-.733,1.138l-1.14,0v6.123c0,.625-.443.881-.984.568L274.5,94.69a2.186,2.186,0,0,1-.984-1.705Z"
                fill="#f9f7f9"
              />
              <g id="그룹_99" data-name="그룹 99">
                <path
                  id="패스_422"
                  data-name="패스 422"
                  d="M308.269,106.695,287.99,95.024l0-1.314,20.281,11.649Z"
                  fill="#adb9e0"
                />
              </g>
              <g id="그룹_100" data-name="그룹 100">
                <path
                  id="패스_423"
                  data-name="패스 423"
                  d="M308.269,109.293,287.988,97.659V96.346L308.269,108Z"
                  fill="#adb9e0"
                />
              </g>
              <g id="그룹_101" data-name="그룹 101">
                <path
                  id="패스_424"
                  data-name="패스 424"
                  d="M299.269,106.726l-11.281-6.453V98.96l11.281,6.468Z"
                  fill="#adb9e0"
                />
              </g>
              <g id="그룹_102" data-name="그룹 102">
                <path
                  id="패스_425"
                  data-name="패스 425"
                  d="M298.314,97.252l-10.326-6.109V87.65l10.326,6.157Z"
                  fill="#adb9e0"
                />
              </g>
              <path
                id="패스_426"
                data-name="패스 426"
                d="M286.648,84.758,274.5,77.743c-.541-.312-.984-.056-.984.569V92.985a2.186,2.186,0,0,0,.984,1.705l12.15,7.014Z"
                fill="#48486a"
              />
              <g id="그룹_103" data-name="그룹 103">
                <path
                  id="패스_427"
                  data-name="패스 427"
                  d="M282.7,93.132q.293.166.514-.244a2.65,2.65,0,0,0,.222-1.224,7.223,7.223,0,0,0-.877-3.421,6.021,6.021,0,0,0-2.346-2.495,2.306,2.306,0,0,0-2.477-.2,2.55,2.55,0,0,0-1.006,2.334,6.736,6.736,0,0,0,.946,3.36,6.343,6.343,0,0,0,2.342,2.461,1.9,1.9,0,0,0,1.925.173l.444,1.141a1.935,1.935,0,0,1-1.1.077,3.827,3.827,0,0,1-1.265-.452,9.027,9.027,0,0,1-4.239-7.3,3.015,3.015,0,0,1,1.281-2.846,2.969,2.969,0,0,1,3.088.279,8.187,8.187,0,0,1,3.018,3.147,8.48,8.48,0,0,1,1.211,4.243,2.918,2.918,0,0,1-.492,1.927.942.942,0,0,1-1.293.094,2.38,2.38,0,0,1-.752-.717,2.982,2.982,0,0,1-.47-.993,1.184,1.184,0,0,1-1.508.027,4.4,4.4,0,0,1-1.591-1.708,4.833,4.833,0,0,1-.676-2.417,2.115,2.115,0,0,1,.611-1.728,1.351,1.351,0,0,1,1.606.072,2.552,2.552,0,0,1,.638.533,3.422,3.422,0,0,1,.433.578l.141.244v-.513l1.352.771v3.989C282.379,92.739,282.486,93.009,282.7,93.132Zm-1.665-2.838a2.671,2.671,0,0,0-.287-1.2,1.91,1.91,0,0,0-.752-.841q-.465-.266-.741-.01a1.138,1.138,0,0,0-.275.862,2.929,2.929,0,0,0,.275,1.214,1.849,1.849,0,0,0,.752.879c.318.181.568.181.751,0A1.231,1.231,0,0,0,281.038,90.294Z"
                  fill="#fff"
                />
              </g>
            </g>
            <g id="그룹_105" data-name="그룹 105">
              <path
                id="패스_428"
                data-name="패스 428"
                d="M257.316,40.507l.4-.354a1.03,1.03,0,0,1,.986.024L295.64,61.511a1.445,1.445,0,0,1,.652,1.129V80.272a.657.657,0,0,1-.29.544l-.5.423L258.469,59.366a1.446,1.446,0,0,1-.652-1.128Z"
                fill="url(#linear-gradient)"
              />
              <path
                id="패스_429"
                data-name="패스 429"
                d="M257.135,40.92c0-.414.294-.583.652-.376L294.958,62a1.445,1.445,0,0,1,.652,1.129V80.9c0,.414-.293.583-.652.377L257.788,59.86a1.446,1.446,0,0,1-.653-1.129Z"
                fill="#f9f7f9"
              />
              <path
                id="패스_430"
                data-name="패스 430"
                d="M261.261,50.6c0-.062.043-.087.1-.056l30.584,17.639a.216.216,0,0,1,.1.168V71c0,.062-.044.087-.1.056L261.358,53.426a.213.213,0,0,1-.1-.168Z"
                fill="#dad9db"
              />
              <path
                id="패스_431"
                data-name="패스 431"
                d="M261.261,50.6c0-.062.043-.087.1-.056L281.8,62.326a.216.216,0,0,1,.1.168v2.649c0,.062-.044.087-.1.056L261.358,53.426a.213.213,0,0,1-.1-.168Z"
                fill="url(#linear-gradient-11)"
              />
            </g>
            <g id="그룹_108" data-name="그룹 108">
              <path
                id="패스_432"
                data-name="패스 432"
                d="M424.552,84.708h0a1.5,1.5,0,0,1,1.837-.258l31.321,18.143a2.427,2.427,0,0,1,1.091,1.893v23.5a1.087,1.087,0,0,1-.862,1.064l-.186.04a1.438,1.438,0,0,1-1.023-.162l-31.36-18.166a2.427,2.427,0,0,1-1.092-1.893V85.387A.984.984,0,0,1,424.552,84.708Z"
                fill="url(#linear-gradient)"
              />
              <path
                id="패스_433"
                data-name="패스 433"
                d="M424.278,85.568c0-.693.492-.977,1.092-.63L456.826,103.1a2.422,2.422,0,0,1,1.092,1.891v23.374c0,.694-.491.978-1.092.631L425.37,110.834a2.425,2.425,0,0,1-1.092-1.892Z"
                fill="#f9f7f9"
              />
              <path
                id="패스_434"
                data-name="패스 434"
                d="M425.37,110.834l31.456,18.161c.6.347,1.092.063,1.092-.631v-4.442l-.075-.045-33.565-19.4v4.47A2.425,2.425,0,0,0,425.37,110.834Z"
                fill="#48486a"
              />
              <path
                id="패스_435"
                data-name="패스 435"
                d="M445.48,107.9l-5.88.6c-.679.07-1.528-.823-1.528-1.607v-6.789c0-.784.849-.872,1.528-.157l5.88,6.185c.679.714.679,1.694,0,1.763l-5.88.6c-.679.07-1.528-.823-1.528-1.607v-6.789c0-.784.849-.872,1.528-.157l5.88,6.185C446.159,106.85,446.159,107.83,445.48,107.9Z"
                fill="#48486a"
              />
              <g id="그룹_106" data-name="그룹 106">
                <path
                  id="패스_436"
                  data-name="패스 436"
                  d="M453.907,124.973a.461.461,0,0,1-.233-.063l-25.891-14.948a.466.466,0,0,1,.466-.807L454.14,124.1a.466.466,0,0,1-.233.87Z"
                  fill="#fff"
                />
              </g>
              <g id="그룹_107" data-name="그룹 107">
                <path
                  id="패스_437"
                  data-name="패스 437"
                  d="M439.874,116.871a.464.464,0,0,1-.232-.062l-11.859-6.847a.466.466,0,0,1,.466-.807L440.108,116a.466.466,0,0,1-.234.869Z"
                  fill="url(#linear-gradient-18)"
                />
              </g>
              <path
                id="패스_438"
                data-name="패스 438"
                d="M440.563,117.23a.5.5,0,0,1-.953,0,1.83,1.83,0,0,1,0-1.65.5.5,0,0,1,.953,0A1.83,1.83,0,0,1,440.563,117.23Z"
                fill="#ff9800"
              />
            </g>
            <path
              id="패스_439"
              data-name="패스 439"
              d="M421.453,127.8,390.5,109.928v22.119l33.516,19.793v-19.6A5.122,5.122,0,0,0,421.453,127.8Z"
              fill="#231f20"
              opacity="0.2"
            />
            <g id="그룹_109" data-name="그룹 109">
              <path
                id="패스_440"
                data-name="패스 440"
                d="M430.171,151.217l-.047-23.924L393.371,105.58l-.735.49.735,23.416,36.065,22.221Z"
                fill="#ffc107"
              />
              <path
                id="패스_441"
                data-name="패스 441"
                d="M429.436,151.707l-.047-23.924L392.637,106.07v23.906Z"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="패스_442"
                data-name="패스 442"
                d="M401.772,116.039c-1.694-.978-3.081-.173-3.081,1.789a6.854,6.854,0,0,0,3.089,5.35c1.7.98,3.085.176,3.08-1.789A6.868,6.868,0,0,0,401.772,116.039Z"
                fill="#231f20"
                opacity="0.17"
              />
              <path
                id="패스_443"
                data-name="패스 443"
                d="M392.637,129.976l15.062-5.017,5.627,9.587,10.629-2.918,5.536,8.649-.056,11.43Z"
                fill="#231f20"
                opacity="0.17"
              />
              <path
                id="패스_444"
                data-name="패스 444"
                d="M400.89,128.154l.347.987,3.623-.292,2.838-3.891-12.715,4.235Z"
                fill="#231f20"
                opacity="0.17"
              />
              <path
                id="패스_445"
                data-name="패스 445"
                d="M420.795,134.268v1.042l3.161-3.682-10.629,2.918,3.543,1.945Z"
                fill="#231f20"
                opacity="0.17"
              />
            </g>
            <path
              id="패스_446"
              data-name="패스 446"
              d="M406.9,107.494V89.465a4.794,4.794,0,0,0-2.151-3.736L377.235,69.762v20.6l.812.469h0Z"
              fill="#231f20"
              opacity="0.2"
            />
            <g id="그룹_110" data-name="그룹 110">
              <path
                id="패스_447"
                data-name="패스 447"
                d="M379.52,54.279v-.732a.659.659,0,0,1,.3-.554l.8-.514a1.342,1.342,0,0,1,1.4-.034l32.65,18.88a3.9,3.9,0,0,1,1.76,3.048v34.243a.856.856,0,0,1-.472.764l-.7.35L379.88,89.308Z"
                fill="url(#linear-gradient)"
              />
              <path
                id="패스_448"
                data-name="패스 448"
                d="M379.069,54.322c0-1.118.792-1.575,1.759-1.016l32.618,18.832a3.9,3.9,0,0,1,1.76,3.047V109.7L379.069,88.84Z"
                fill="#48486a"
              />
              <path
                id="패스_449"
                data-name="패스 449"
                d="M413.446,72.138,380.828,53.306c-.967-.559-1.759-.1-1.759,1.016v4.922l36.137,20.864V75.185A3.9,3.9,0,0,0,413.446,72.138Z"
                fill="#fff"
              />
              <path
                id="패스_450"
                data-name="패스 450"
                d="M382.505,57.364c-.364-.21-.662-.038-.662.383a1.47,1.47,0,0,0,.662,1.147c.365.211.663.038.663-.382A1.47,1.47,0,0,0,382.505,57.364Z"
                fill="#48486a"
              />
              <path
                id="패스_451"
                data-name="패스 451"
                d="M385.754,59.272c-.365-.21-.663-.038-.663.382a1.47,1.47,0,0,0,.663,1.148c.364.21.662.038.662-.383A1.47,1.47,0,0,0,385.754,59.272Z"
                fill="#48486a"
              />
              <path
                id="패스_452"
                data-name="패스 452"
                d="M389,61.18c-.364-.211-.662-.039-.662.382A1.468,1.468,0,0,0,389,62.709c.365.211.663.039.663-.382A1.467,1.467,0,0,0,389,61.18Z"
                fill="#48486a"
              />
              <path
                id="패스_453"
                data-name="패스 453"
                d="M387.413,66.887l16.542,9.55v1.084l-16.542-9.55Z"
                fill="#fff"
              />
              <path
                id="패스_454"
                data-name="패스 454"
                d="M380.737,63.047l5.68,3.3v1.084l-5.68-3.3Z"
                fill="#fff"
              />
              <path
                id="패스_455"
                data-name="패스 455"
                d="M383.576,67.791l4.722,2.732v1.084l-4.722-2.732Z"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="패스_456"
                data-name="패스 456"
                d="M389.108,70.953l6.424,3.74v1.084l-6.424-3.74Z"
                fill="#fff"
              />
              <path
                id="패스_457"
                data-name="패스 457"
                d="M399.478,80.108l14.06,8.118V89.31l-14.06-8.118Z"
                fill="#fff"
              />
              <path
                id="패스_458"
                data-name="패스 458"
                d="M394.242,77.1l3.73,2.15v1.084l-3.73-2.15Z"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="패스_459"
                data-name="패스 459"
                d="M380.737,69.319l11.811,6.819v1.084L380.737,70.4Z"
                fill="#fff"
              />
              <path
                id="패스_460"
                data-name="패스 460"
                d="M389.437,77.521l11.509,6.646v1.084L389.437,78.6Z"
                fill="#fff"
              />
              <path
                id="패스_461"
                data-name="패스 461"
                d="M386.148,75.592l2.58,1.5v1.084l-2.58-1.5Z"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="패스_462"
                data-name="패스 462"
                d="M390.815,81.385,411.037,93.03v1.084L390.815,82.469Z"
                fill="#fff"
              />
              <path
                id="패스_463"
                data-name="패스 463"
                d="M382.354,76.553l7.474,4.29v1.084l-7.474-4.29Z"
                fill="#fff"
              />
              <path
                id="패스_464"
                data-name="패스 464"
                d="M394.837,86.894l18.7,10.741v1.084l-18.7-10.741Z"
                fill="#fff"
              />
              <path
                id="패스_465"
                data-name="패스 465"
                d="M386.091,81.865l7.759,4.488v1.084l-7.759-4.488Z"
                fill="#fff"
              />
              <path
                id="패스_466"
                data-name="패스 466"
                d="M380.737,78.729l4.615,2.657v1.084l-4.615-2.657Z"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="패스_467"
                data-name="패스 467"
                d="M389.108,86.7l2.656,1.522V89.31l-2.656-1.522Z"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="패스_468"
                data-name="패스 468"
                d="M385.731,84.709l2.567,1.454v1.084l-2.567-1.454Z"
                fill="#fff"
              />
              <path
                id="패스_469"
                data-name="패스 469"
                d="M395.684,93.572l17.854,10.337v1.084L395.684,94.656Z"
                fill="#fff"
              />
              <path
                id="패스_470"
                data-name="패스 470"
                d="M387.666,88.97l5.691,3.295v1.084l-5.691-3.295Z"
                fill="#fff"
              />
              <path
                id="패스_471"
                data-name="패스 471"
                d="M380.737,85l2.983,1.7v1.084l-2.983-1.7Z"
                fill="url(#linear-gradient-11)"
              />
            </g>
            <path
              id="패스_472"
              data-name="패스 472"
              d="M330.139,159.787a5.368,5.368,0,0,0,4.841-1.852l4.44-5.05.7-.8a2.312,2.312,0,0,0,.235-4.6l1.82-1.912a.253.253,0,0,0-.009-.357l-.377-.359a.251.251,0,0,0-.356.009l-3.277,3.442a.248.248,0,0,0-.054.139,2.336,2.336,0,0,0-.24.436,1.848,1.848,0,0,0-.216.2l-.915,1.041h0L332.1,155.4a1.717,1.717,0,0,1-1.82.516c-.035-.011-.084-.028-.139-.05v-4.109a11.866,11.866,0,0,0-4.727-9.475q-.06-.273-.121-.544a4.8,4.8,0,0,0,3.6-4.644V132.15l.148-.136c1.106-1.021,1.667-2.032.55-3.356a3.048,3.048,0,0,0-.381-.373c-.039-.033-.083-.061-.124-.092a3.266,3.266,0,0,0-.322-.22c-.044-.026-.09-.05-.136-.074a4.048,4.048,0,0,0-.382-.18l-.1-.041c-1.935-.737-4.674-.264-6.365.278-2.689.862-6.061,1.868-6.545,5.119a16.342,16.342,0,0,0-.031,2.494,12.389,12.389,0,0,1-1.292,5.559c-1.223,2.457-1.81,4.022-.514,6.572.367.722.673,1.636,1.062,2.5a14.4,14.4,0,0,0-1.823,3.9v.866l3.31,1.922a10.487,10.487,0,0,1,.632-2.529l.11,2.96,13.449,7.81v-5.333Z"
              fill="#231f20"
              opacity="0.3"
            />
            <g id="그룹_117" data-name="그룹 117">
              <path
                id="패스_473"
                data-name="패스 473"
                d="M325.813,211.133l-.992-.285a5.491,5.491,0,0,0-4.719.813l-1.034-.766-1.053,2.429-5.086-.433,1.107,4.518a.837.837,0,0,0,.577.6l2.09.613a5.085,5.085,0,0,0,4.937-1.2l4.634-4.414A1.117,1.117,0,0,0,325.813,211.133Z"
                fill="#48486a"
              />
              <path
                id="패스_474"
                data-name="패스 474"
                d="M325.813,211.133l-.992-.285a5.491,5.491,0,0,0-4.719.813l-1.034-.766-1.053,2.429-5.086-.433.574,2.345a3,3,0,0,0,2.072,2.167l.892.261a5.082,5.082,0,0,0,4.937-1.2l4.634-4.414a.475.475,0,0,0-.225-.92Z"
                fill="#fff"
              />
              <path
                id="패스_475"
                data-name="패스 475"
                d="M318.662,212.837c1.065-1.016.78-2.181-.635-2.589a5.15,5.15,0,0,0-4.5,1.1,1.409,1.409,0,0,0,.634,2.589A5.15,5.15,0,0,0,318.662,212.837Z"
                fill="#48486a"
              />
              <path
                id="패스_476"
                data-name="패스 476"
                d="M332.856,217.587l-.9-.513a5.488,5.488,0,0,0-4.776-.333l-.822-.991-1.6,2.109-4.837-1.631v4.652a.838.838,0,0,0,.417.724l1.884,1.092a5.08,5.08,0,0,0,5.079.013l5.552-3.184A1.117,1.117,0,0,0,332.856,217.587Z"
                fill="#48486a"
              />
              <path
                id="패스_477"
                data-name="패스 477"
                d="M332.856,217.587l-.9-.513a5.488,5.488,0,0,0-4.776-.333l-.822-.991-1.6,2.109-4.837-1.631v2.414a3,3,0,0,0,1.5,2.6l.8.467a5.085,5.085,0,0,0,5.08.013l5.552-3.185a.475.475,0,0,0,0-.947Z"
                fill="#fff"
              />
              <path
                id="패스_478"
                data-name="패스 478"
                d="M325.505,217.54c1.276-.733,1.276-1.932,0-2.666a5.151,5.151,0,0,0-4.631,0,1.409,1.409,0,0,0,0,2.666A5.151,5.151,0,0,0,325.505,217.54Z"
                fill="#48486a"
              />
              <g id="그룹_111" data-name="그룹 111">
                <path
                  id="패스_479"
                  data-name="패스 479"
                  d="M323.185,217.27a1.679,1.679,0,0,1-1.679-1.679V209.12a1.679,1.679,0,0,1,3.358,0v6.471A1.679,1.679,0,0,1,323.185,217.27Z"
                  fill="#ef9ea3"
                />
              </g>
              <g id="그룹_112" data-name="그룹 112">
                <path
                  id="패스_480"
                  data-name="패스 480"
                  d="M315.871,212.993a1.678,1.678,0,0,1-1.678-1.678v-6.471a1.678,1.678,0,0,1,3.357,0v6.471A1.678,1.678,0,0,1,315.871,212.993Z"
                  fill="#ef9ea3"
                />
              </g>
              <g id="그룹_113" data-name="그룹 113">
                <path
                  id="패스_481"
                  data-name="패스 481"
                  d="M315.873,172.349a1.917,1.917,0,0,1-1.663-.959l-3.47-6.007a14.4,14.4,0,0,1,3.74-18.626l.949-.723a1.918,1.918,0,0,1,2.324,3.053l-.949.723a10.558,10.558,0,0,0-2.741,13.654l3.47,6.007a1.918,1.918,0,0,1-1.66,2.878Z"
                  fill="#48486a"
                />
              </g>
              <path
                id="패스_482"
                data-name="패스 482"
                d="M323.509,182.083l-9.533-5.992V163.962h9.806Z"
                fill="#fff"
              />
              <g id="그룹_114" data-name="그룹 114">
                <path
                  id="패스_483"
                  data-name="패스 483"
                  d="M323.188,215.391q-.108,0-.219-.009a2.637,2.637,0,0,1-2.413-2.844l1.423-17.354-2.146-22.669c-.091-1.454,1.733-2.707,3.186-2.8a2.639,2.639,0,0,1,2.8,2.466l1.45,23.051-1.454,17.735A2.637,2.637,0,0,1,323.188,215.391Z"
                  fill="#fff"
                />
              </g>
              <path
                id="패스_484"
                data-name="패스 484"
                d="M325.818,172.183a2.639,2.639,0,0,0-2.8-2.466c-.059,0-.119.012-.179.019a2.639,2.639,0,0,1,2.276,2.447l1.451,23.051-1.455,17.735a2.632,2.632,0,0,1-2.276,2.389c.046.006.087.02.133.024q.111.009.219.009a2.637,2.637,0,0,0,2.626-2.422l1.454-17.735Z"
                fill="#fec55e"
                opacity="0.2"
              />
              <g id="그룹_115" data-name="그룹 115">
                <path
                  id="패스_485"
                  data-name="패스 485"
                  d="M315.874,211.938c-.041,0-.081,0-.122,0a2.637,2.637,0,0,1-2.516-2.754l.716-15.833-.717-25.336A2.638,2.638,0,0,1,315.8,165.3c1.449-.053,3.389,1.105,3.431,2.562l0,25.529,0,.1-.719,15.93A2.639,2.639,0,0,1,315.874,211.938Z"
                  fill="#fff"
                />
              </g>
              <path
                id="패스_486"
                data-name="패스 486"
                d="M312.3,168.085l.264-2.52a35.947,35.947,0,0,0,.319-5.225,10.45,10.45,0,0,0,1.179,3.124l3.47,6.007a1.919,1.919,0,0,1-3.323,1.919Z"
                fill="#231f20"
                opacity="0.3"
              />
              <path
                id="패스_487"
                data-name="패스 487"
                d="M312.727,136.7l8.28,4.781a11.87,11.87,0,0,1,5.935,10.279v23.578c-3.362,2.353-12.409-.926-14.215-4.61l.54-5.162a35.874,35.874,0,0,0,.3-6.18Z"
                fill="#48486a"
              />
              <path
                id="사각형_7"
                data-name="사각형 7"
                d="M0,0H9.755a0,0,0,0,1,0,0V4.508A6.757,6.757,0,0,1,3,11.264H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                transform="translate(315.943 130.632)"
                fill="#f2b1b5"
              />
              <path
                id="사각형_8"
                data-name="사각형 8"
                d="M0,0H4.079a0,0,0,0,1,0,0V6.746A1.413,1.413,0,0,1,2.666,8.159H1.413A1.413,1.413,0,0,1,0,6.746V0A0,0,0,0,1,0,0Z"
                transform="translate(317.163 135.611)"
                fill="#f2b1b5"
              />
              <path
                id="패스_488"
                data-name="패스 488"
                d="M326.942,156.92a1.7,1.7,0,0,1-1.082-1.4l-.772-5.988a1.919,1.919,0,0,0-2.148-1.657c-1.051.135-1.1,1.238-.967,2.289l.082,5.847a5.521,5.521,0,0,0,3.9,4.624,5.762,5.762,0,0,0,.982.2Z"
                fill="#231f20"
                opacity="0.3"
              />
              <g id="그룹_141" data-name="그룹 141">
                <path
                  id="패스_489"
                  data-name="패스 489"
                  d="M327.621,159.838a5.7,5.7,0,0,1-1.661-.25,5.521,5.521,0,0,1-3.9-4.625l-.773-5.988a1.919,1.919,0,1,1,3.806-.49l.772,5.988a1.745,1.745,0,0,0,3.041.929l5.552-6.315a1.918,1.918,0,1,1,2.881,2.534l-5.551,6.314A5.519,5.519,0,0,1,327.621,159.838Z"
                  fill="#48486a"
                />
                <g id="그룹_116" data-name="그룹 116">
                  <path
                    id="패스_490"
                    data-name="패스 490"
                    d="M333.538,150.128l.915-1.041a1.918,1.918,0,1,1,2.881,2.534l-1.111,1.264Z"
                    fill="#f2b1b5"
                  />
                </g>
                <path
                  id="패스_491"
                  data-name="패스 491"
                  d="M339.134,149.775a2.321,2.321,0,1,1-2.321-2.321A2.321,2.321,0,0,1,339.134,149.775Z"
                  fill="#f2b1b5"
                />
                <rect
                  id="사각형_9"
                  data-name="사각형 9"
                  width="5.257"
                  height="1.025"
                  rx="0.355"
                  transform="matrix(0.689, -0.724, 0.724, 0.689, 334.789, 148.495)"
                  fill="#f2b1b5"
                />
              </g>
              <path
                id="패스_492"
                data-name="패스 492"
                d="M326.4,128.658c-1.685-2-5.627-1.4-7.812-.7-2.689.862-6.061,1.868-6.545,5.119a16.339,16.339,0,0,0-.031,2.494,12.389,12.389,0,0,1-1.292,5.559c-1.223,2.457-1.81,4.022-.514,6.572.767,1.509,1.257,3.859,2.823,4.786,1.612.954,5.025,1.548,6.567.379,2.151-1.63,3.234-5.576,3.037-8.111-.212-2.736-1.579-5.5-.75-8.113.52-1.638,2.722-3.477,3.967-4.627C326.952,130.993,327.513,129.982,326.4,128.658Z"
                fill="#5c629e"
              />
              <path
                id="패스_493"
                data-name="패스 493"
                d="M326.4,128.658c-.988-1.17-2.753-1.439-4.484-1.329a4.479,4.479,0,0,1,3.258,1.329c1.117,1.324.557,2.335-.549,3.356-1.246,1.15-3.448,2.989-3.967,4.627-.83,2.616.537,5.377.75,8.113.2,2.535-.887,6.481-3.037,8.111a3.7,3.7,0,0,1-1.936.611,4.723,4.723,0,0,0,3.161-.611c2.151-1.63,3.234-5.576,3.037-8.111-.212-2.736-1.58-5.5-.75-8.113.52-1.638,2.722-3.477,3.967-4.627C326.952,130.993,327.513,129.982,326.4,128.658Z"
                fill="#fec55e"
                opacity="0.2"
              />
            </g>
            <g id="그룹_138" data-name="그룹 138">
              <path
                id="패스_494"
                data-name="패스 494"
                d="M299.327,220.537c.412-.152-1.753-2.067-1.753-2.067l-15.318,3.038-4.332,2.32a14.22,14.22,0,0,0-2.471,1.757l-2.667,1.54a.361.361,0,0,0-.21.288l0,0v.265a.392.392,0,0,0,.2.34l.691.4a10.462,10.462,0,0,0-.706,4.016s-.1,1.144-.163,2.67l8.063,4.655.175.042v.059l3.621,2.09v-1.314a7.414,7.414,0,0,1,.845.269c1.181.477,1.5-5.439,1.5-5.439s.089-1.584,2.237-2.823l7.241-4.174A6.733,6.733,0,0,0,299.327,220.537Z"
                fill="#231f20"
                opacity="0.3"
              />
              <g id="그룹_137" data-name="그룹 137">
                <g id="그룹_149" data-name="그룹 149">
                  <path
                    id="패스_495"
                    data-name="패스 495"
                    d="M273.681,246.62l-8.593,5.03,0,.877a2.231,2.231,0,0,0,1.064,1.912l1.647,1.01a1.429,1.429,0,0,0,1.49,0l6.67-3.9A2.174,2.174,0,0,0,277,249.69V248.7Z"
                    fill="#e6e7e8"
                  />
                  <path
                    id="패스_496"
                    data-name="패스 496"
                    d="M271.638,246.951l-5.653,3.268a1.879,1.879,0,0,0-.011,3.209l1.509.926a2.027,2.027,0,0,0,2.114,0l6.394-3.735a2.1,2.1,0,0,0-.055-3.623l-.18-.1A4.069,4.069,0,0,0,271.638,246.951Z"
                    fill="#fff"
                  />
                  <g id="그룹_118" data-name="그룹 118">
                    <path
                      id="패스_497"
                      data-name="패스 497"
                      d="M275.64,248.743a1.9,1.9,0,0,0-.939-1.37l-.165-.094a3.77,3.77,0,0,0-1.4-.461,2.679,2.679,0,0,0-1,.358L269.1,248.95a1.238,1.238,0,0,0-.007,2.116l.995.611a1.337,1.337,0,0,0,1.394,0l3.531-2.082A1.366,1.366,0,0,0,275.64,248.743Z"
                      fill="#f2b1b5"
                    />
                  </g>
                  <g id="그룹_121" data-name="그룹 121">
                    <g id="그룹_120" data-name="그룹 120">
                      <path
                        id="패스_501"
                        data-name="패스 501"
                        d="M273.933,249.96a1.811,1.811,0,0,1-1.811-1.811V236.427a1.811,1.811,0,1,1,3.622,0v11.722A1.811,1.811,0,0,1,273.933,249.96Z"
                        fill="#f2b1b5"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="패스_502"
                  data-name="패스 502"
                  d="M272.53,248.149V236.427a1.8,1.8,0,0,1,1.607-1.79,1.72,1.72,0,0,0-.2-.021,1.811,1.811,0,0,0-1.811,1.811v11.722a1.811,1.811,0,0,0,1.811,1.811,1.717,1.717,0,0,0,.2-.021A1.8,1.8,0,0,1,272.53,248.149Z"
                  fill="#ef9ea3"
                  opacity="0.3"
                />
                <g id="그룹_148" data-name="그룹 148">
                  <path
                    id="패스_498"
                    data-name="패스 498"
                    d="M281.176,250.7l-8.594,5.031,0,.877a2.23,2.23,0,0,0,1.065,1.911l1.647,1.01a1.426,1.426,0,0,0,1.49,0l6.67-3.9a2.178,2.178,0,0,0,1.044-1.859v-.994Z"
                    fill="#e6e7e8"
                  />
                  <path
                    id="패스_499"
                    data-name="패스 499"
                    d="M279.133,251.033,273.48,254.3a1.878,1.878,0,0,0-.011,3.209l1.509.925a2.025,2.025,0,0,0,2.114,0l6.394-3.735a2.1,2.1,0,0,0-.055-3.623l-.18-.1A4.071,4.071,0,0,0,279.133,251.033Z"
                    fill="#fff"
                  />
                  <g id="그룹_119" data-name="그룹 119">
                    <path
                      id="패스_500"
                      data-name="패스 500"
                      d="M283.135,252.825a1.9,1.9,0,0,0-.939-1.369l-.166-.094a3.729,3.729,0,0,0-1.395-.461,2.666,2.666,0,0,0-1,.358l-3.042,1.774a1.238,1.238,0,0,0-.007,2.116l.995.61a1.334,1.334,0,0,0,1.394,0l3.531-2.082A1.365,1.365,0,0,0,283.135,252.825Z"
                      fill="#f2b1b5"
                    />
                  </g>
                  <g id="그룹_123" data-name="그룹 123">
                    <g id="그룹_122" data-name="그룹 122">
                      <path
                        id="패스_503"
                        data-name="패스 503"
                        d="M281.417,254.067a1.811,1.811,0,0,1-1.811-1.811V235.1a1.811,1.811,0,0,1,3.622,0v17.153A1.811,1.811,0,0,1,281.417,254.067Z"
                        fill="#f2b1b5"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="패스_504"
                  data-name="패스 504"
                  d="M286.336,210.048l-3.488,9.674-6.147,3.292c-2.756,1.61-5.169,3.633-5.169,8.606,0,0-.122,1.382-.181,3.139-.109,3.242,10.417,4.4,12.722,5.331,1.181.478,1.5-5.439,1.5-5.439s.088-1.584,2.237-2.823l7.241-4.174a6.753,6.753,0,0,0,.01-11.7Z"
                  fill="#48486a"
                />
                <path
                  id="패스_505"
                  data-name="패스 505"
                  d="M272.166,234.759c.058-1.757.181-3.139.181-3.139,0-4.973,2.413-7,5.169-8.606l6.147-3.292,3.328-9.232-.655-.442-3.488,9.674-6.147,3.292c-2.756,1.61-5.169,3.633-5.169,8.606,0,0-.122,1.382-.181,3.139-.109,3.242,10.417,4.4,12.722,5.331a.374.374,0,0,0,.432-.134C281.611,239.043,272.062,237.849,272.166,234.759Z"
                  fill="#5c629e"
                  opacity="0.15"
                />
                <g id="그룹_125" data-name="그룹 125">
                  <path
                    id="패스_506"
                    data-name="패스 506"
                    d="M271.35,226.6v.265a.393.393,0,0,0,.2.34l7.836,4.515a1.15,1.15,0,0,0,1.035,0l12.19-7.016a.5.5,0,0,0,.252-.437v-.1l-8.017-4.624a1.145,1.145,0,0,0-1.034,0Z"
                    fill="#e6e7e8"
                  />
                  <path
                    id="패스_507"
                    data-name="패스 507"
                    d="M271.564,226.31a.315.315,0,0,0,0,.6l7.818,4.514a1.147,1.147,0,0,0,1.035,0l12.244-7.043a.316.316,0,0,0,0-.6l-7.82-4.539a1.142,1.142,0,0,0-1.034,0Z"
                    fill="#fff"
                  />
                  <path
                    id="패스_508"
                    data-name="패스 508"
                    d="M273.755,225.721l7.667,4.461,9.67-5.563-7.668-4.481Z"
                    fill="url(#linear-gradient-27)"
                  />
                  <g id="그룹_124" data-name="그룹 124">
                    <path
                      id="패스_509"
                      data-name="패스 509"
                      d="M277.2,228.934a.156.156,0,0,1-.075-.02l-1.642-.948a.15.15,0,0,1,.15-.259l1.641.948a.149.149,0,0,1-.074.279Z"
                      fill="#48486a"
                    />
                  </g>
                </g>
                <g id="그룹_147" data-name="그룹 147">
                  <g id="그룹_126" data-name="그룹 126">
                    <path
                      id="패스_510"
                      data-name="패스 510"
                      d="M283.751,222.6a2.035,2.035,0,0,1-1.365-.525,16.793,16.793,0,0,1-.448-24.5l.392-.382a2.037,2.037,0,0,1,2.843,2.919l-.394.382a12.719,12.719,0,0,0,.338,18.556,2.037,2.037,0,0,1-1.366,3.549Z"
                      fill="#f2b1b5"
                    />
                  </g>
                  <path
                    id="패스_518"
                    data-name="패스 518"
                    d="M283.751,222.6a2.029,2.029,0,0,1-1.365-.526,16.754,16.754,0,0,1-5.325-15.111,2.037,2.037,0,0,1,4.023.64,12.694,12.694,0,0,0,4.033,11.449,2.037,2.037,0,0,1-1.366,3.548Z"
                    fill="#f2b1b5"
                  />
                  <g id="그룹_134" data-name="그룹 134">
                    <path
                      id="패스_521"
                      data-name="패스 521"
                      d="M281.761,218.024l3.94,1.147a.559.559,0,0,0,.7-.4h0a.559.559,0,0,0-.383-.668l-3.922-1.168Z"
                      fill="#f2b1b5"
                    />
                  </g>
                </g>
                <path
                  id="패스_511"
                  data-name="패스 511"
                  d="M280.134,187.063l.151-.7a1.5,1.5,0,0,1,1.05-1.245,4.388,4.388,0,0,1,1.711-.137l3.13.161c2.025.1,4.371.387,5.444,2.108.746,1.2.653,2.812,1.534,3.912.3.368.684.655.966,1.034a4.13,4.13,0,0,1,.246,3.591,1.982,1.982,0,0,1-.555,1.029,1.7,1.7,0,0,1-1.9-.094,13.132,13.132,0,0,0-1.691-1.116c-.986-.4-2.138-.119-3.15-.454-1.994-.659-2.656-3.376-4.6-4.166a6.928,6.928,0,0,1-1.839-.7C279.734,189.564,279.892,188.184,280.134,187.063Z"
                  fill="#48486a"
                />
                <path
                  id="패스_512"
                  data-name="패스 512"
                  d="M297.8,201.8a10.212,10.212,0,0,0-3.787-4.455l-5.192-3-3.675.053a2.657,2.657,0,0,0-1.938.881h0a4.112,4.112,0,0,0-.68,2.277v1.921l-1.006,3.82a2.457,2.457,0,0,0,.382,2.062l.665.969a9.384,9.384,0,0,1,1.629,5.959l-.448,6.512c2.8,5.692,15.007.667,15.007.667C297.544,210.46,300.376,208.517,297.8,201.8Z"
                  fill="#fff"
                />
                <path
                  id="패스_513"
                  data-name="패스 513"
                  d="M284.566,218.8l.449-6.512a9.384,9.384,0,0,0-1.629-5.959l-.665-.969a2.457,2.457,0,0,1-.382-2.062l1.006-3.82v-1.921a4.112,4.112,0,0,1,.68-2.277,2.657,2.657,0,0,1,1.938-.881l2.88-.042-.02-.011-3.675.053a2.657,2.657,0,0,0-1.938.881,4.112,4.112,0,0,0-.68,2.277v1.921l-1.006,3.82a2.457,2.457,0,0,0,.382,2.062l.665.969a9.384,9.384,0,0,1,1.629,5.959l-.448,6.512c1.069,2.169,3.5,2.78,6.107,2.707C287.573,221.439,285.523,220.746,284.566,218.8Z"
                  fill="#d3daed"
                  opacity="0.15"
                />
                <g id="그룹_128" data-name="그룹 128">
                  <g id="그룹_127" data-name="그룹 127">
                    <path
                      id="패스_514"
                      data-name="패스 514"
                      d="M290.245,198.867a1.811,1.811,0,0,0,1.811-1.811v-6.421a1.811,1.811,0,0,0-3.622,0v6.421A1.811,1.811,0,0,0,290.245,198.867Z"
                      fill="#f2b1b5"
                    />
                  </g>
                </g>
                <g id="그룹_129" data-name="그룹 129">
                  <path
                    id="사각형_10"
                    data-name="사각형 10"
                    d="M0,0H1.37A6.345,6.345,0,0,1,7.715,6.345v3.72a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                    transform="matrix(-0.956, 0.293, -0.293, -0.956, 291.082, 194.327)"
                    fill="#f2b1b5"
                  />
                </g>
                <path
                  id="패스_515"
                  data-name="패스 515"
                  d="M290.5,221.481a27,27,0,0,0,5.852-1.17,17.506,17.506,0,0,0-1.686-19.4c-.708-.874-1.53,2.081-1.53,2.081,3.939,4.864,2.3,12.4-1.64,17.266Z"
                  fill="#231f20"
                  opacity="0.17"
                />
                <path
                  id="패스_517"
                  data-name="패스 517"
                  d="M297.053,202a2.033,2.033,0,0,0-2.06-1.186A2.029,2.029,0,0,1,296.645,202a17.486,17.486,0,0,1-5.628,21.178l-4.219,3.075a2.02,2.02,0,0,1-.954.372c.055.005.108.019.163.019a2.027,2.027,0,0,0,1.2-.391l4.219-3.075A17.487,17.487,0,0,0,297.053,202Z"
                  fill="#ef9ea3"
                  opacity="0.3"
                />
                <g id="그룹_146" data-name="그룹 146">
                  <path
                    id="패스_516"
                    data-name="패스 516"
                    d="M286.007,226.643a2.037,2.037,0,0,1-1.2-3.683l4.218-3.075a13.392,13.392,0,0,0,4.311-16.22A2.037,2.037,0,0,1,297.053,202a17.487,17.487,0,0,1-5.629,21.178l-4.219,3.075A2.027,2.027,0,0,1,286.007,226.643Z"
                    fill="#f2b1b5"
                  />
                  <g id="그룹_135" data-name="그룹 135">
                    <path
                      id="패스_522"
                      data-name="패스 522"
                      d="M285.244,222.91l-3.263,2.488a.558.558,0,0,0-.091.8h0a.557.557,0,0,0,.764.089l3.27-2.461Z"
                      fill="#f2b1b5"
                    />
                  </g>
                </g>
                <path
                  id="패스_519"
                  data-name="패스 519"
                  d="M296.153,203.673a3.838,3.838,0,0,1,1.585.317,2.322,2.322,0,0,0,1.432.378c.659-.168.884-.973.994-1.644a4.513,4.513,0,0,0,.054-1.754,6.658,6.658,0,0,0-1.155-2,7.025,7.025,0,0,1-1.129-3.159c-.087-.848-.018-1.706-.111-2.554a5.668,5.668,0,0,0-1.058-2.46c-.593-.971-.765-2.134-1.388-3.084s-1.676-1.57-2.366-2.476a6.653,6.653,0,0,0-2.253-2.219,9.631,9.631,0,0,0-2.555-1.358,5.761,5.761,0,0,0-2.079.239c-.731.1-1.457,0-2.2.17a7.369,7.369,0,0,0-3.855,2.174c-.8.89-1,2.018.184,2.646,1.333.706,2.531.168,3.908.09a1.226,1.226,0,0,1,.728.146c.575.362.314,1.813.473,2.44a3.087,3.087,0,0,0,1.043,1.719,6.216,6.216,0,0,0,1.643.744,2.587,2.587,0,0,1,1.429,1.051c.539.991-.152,2.194-.122,3.321a2.605,2.605,0,0,0,.8,1.793,5.88,5.88,0,0,1,.987.92,2.976,2.976,0,0,1,.345,1.315,6.968,6.968,0,0,0,.4,1.773,2.457,2.457,0,0,0,2.636,1.671c.465-.025.92-.147,1.385-.185C295.985,203.678,296.069,203.674,296.153,203.673Z"
                  fill="#48486a"
                />
                <g id="그룹_133" data-name="그룹 133" opacity="0.15">
                  <g id="그룹_132" data-name="그룹 132">
                    <path
                      id="패스_520"
                      data-name="패스 520"
                      d="M300.164,202.724a2.706,2.706,0,0,1-.521,1.347,5.03,5.03,0,0,0,.113-.533,4.488,4.488,0,0,0,.054-1.753,6.6,6.6,0,0,0-1.155-2,7.057,7.057,0,0,1-1.129-3.159c-.086-.848-.018-1.706-.111-2.554a5.656,5.656,0,0,0-1.058-2.46c-.593-.971-.765-2.134-1.387-3.084s-1.677-1.57-2.367-2.476a6.65,6.65,0,0,0-2.252-2.219,9.679,9.679,0,0,0-2.555-1.359,5.737,5.737,0,0,0-2.079.24c-.732.1-1.457,0-2.2.169a7.374,7.374,0,0,0-3.855,2.175c-.074.082-.137.168-.2.254a2.554,2.554,0,0,1,.608-1.069,7.369,7.369,0,0,1,3.855-2.174c.742-.167,1.468-.074,2.2-.17a5.739,5.739,0,0,1,2.079-.239,9.616,9.616,0,0,1,2.555,1.358,6.653,6.653,0,0,1,2.253,2.219c.69.906,1.751,1.537,2.366,2.476s.795,2.113,1.388,3.084a5.668,5.668,0,0,1,1.058,2.46c.093.848.024,1.706.111,2.554a7.029,7.029,0,0,0,1.129,3.159,6.658,6.658,0,0,1,1.155,2A4.513,4.513,0,0,1,300.164,202.724Z"
                      fill="#ef9ea3"
                    />
                  </g>
                </g>
                <g id="그룹_136" data-name="그룹 136">
                  <path
                    id="패스_523"
                    data-name="패스 523"
                    d="M287.325,222.608l-3.263,2.488a.559.559,0,0,0-.091.8h0a.558.558,0,0,0,.765.089l3.269-2.461Z"
                    fill="#f2b1b5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
export default MainSVG;
