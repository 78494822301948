import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Header from "../Layout/Header";
import { useDocumentMeta } from '../useDocumentMeta';
function Portfolio(props) {
  props.setColorData("#48486a");
  props.setHeaderClassName("");
  const [portfolio, setPortfolio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // 요청이 시작 할 때에는 error 와 users 를 초기화하고
        setError(null);
        setPortfolio(null);
        // loading 상태를 true 로 바꿉니다.
        setLoading(true);
        const response = await axios.get("https://api.0u0.kr//portfolio/list");

        setPortfolio(response.data.data); // 데이터는 response.data 안에 들어있습니다.
        console.log(response.data.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);
  useDocumentMeta("Portfolio", "#48486a");


  if (loading) return <div>로딩중..</div>;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!portfolio) return null;
  return (
    <>
      <div className="whiteBg">
        <div className="basicWidth">
          <div className="postBox">
            <ul>
              {portfolio.map((pt) => (
                <li>
                  <Link to={"/Portfolio/" + pt.pjFile}>
                    <div className="pbBox">
                      <div className="imgBox">
                        <img
                          className="thumImg"
                          src={"https://img.0u0.kr/new_port/" + pt.thumNail}
                          alt={pt.pjName + "의 배경"}
                        />
                        <div
                          className="bgColorBox"
                          style={{ backgroundColor: pt.thmnailBgColor }}
                        ></div>
                        <div className="logoBox">
                          <div className="logoBoxPt">
                            <img
                              src={"https://img.0u0.kr/new_port/" + pt.imgLogo}
                              alt={pt.pjName + "의 로고"}
                            />
                          </div>
                        </div>
                      </div>
                      <h2>{pt.pjName}</h2>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Portfolio;
