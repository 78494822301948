import React, { useEffect, useState } from "react";
import webDesign from "../Static/img/web_design.svg";
import appDeveloper from "../Static/img/app_developer.svg";
import axios from "axios";
import { Parallax } from "react-parallax";
import { useDocumentMeta } from '../useDocumentMeta';

function AboutMe(props) {
  props.setColorData("#48486a");
  props.setHeaderClassName("");
  window.scrollTo(0, 0);
  const [aboutMe, setAboutMe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setAboutMe(null);
      setLoading(true);
      const response = await axios.get("https://api.0u0.kr/aboutMe");
      setAboutMe(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta("About me", "#48486a");
  if (loading) return <div></div>;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!aboutMe) return null;

  return (
    <>
      <div className="whiteBg aboutmes">
        <div className="basicWidth mainSlide ">
          <div className="msTextBox">
            <div className="msTxtBoxPt">
              <h1>{aboutMe.about1Title}</h1>
              <div className="subtxt">{aboutMe.about1Text}</div>
            </div>
          </div>
          <div className="msImgBox">
            <div className="msImgBoxPt middle">
              <img src={webDesign} alt="" />
            </div>
          </div>
        </div>

        <div className="basicWidth mainSlide hiddenMenu rightFix">
          <div className="msTextBox">
            <div className="msTxtBoxPt">
              <h1>{aboutMe.about2Title}</h1>
              <div className="subtxt">{aboutMe.about2Text}</div>
            </div>
          </div>
          <div className="msImgBox">
            <div className="msImgBoxPt middle">
              <img src={appDeveloper} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutMe;
