import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/SejongSafer"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox x2size">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/sejongansim_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight "></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>세종안심이 소개</h2>
            <div className="txtBox">
              세종안심이 서비스는 위급한 상황 속에서 SOS호출을 통해
              도시통합정보센터와 연계하여 상황에 맞는 신속한 대응을 할 수 있으며
              위치관제 기능이 포함되어 있어 이용자의 동의를 전제로 노인, 어린이
              등사회적 약자를 위한 안심장소 출·도착 알림 서비스를 제공합니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox mobiCont">
              <img
                src="https://img.0u0.kr/new_port/sejongansim_bg2.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox yellowton">
              <h2 className="leftAlign">IONIC을 활용하여 앱 퍼블리싱을 진행</h2>
              <div className="txtBox">
                처음 진행한 IONIC은 퍼블리싱을 진행하며 접하게 되었습니다.
                프로젝트가 장기화되며 일부 API연동 및 유지보수 일부
                진행했습니다.
                <div className="linkBox">
                  <a
                    href="https://img.0u0.kr/pdf/sjsafer.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    소개 PDF
                  </a>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="blnakHeight100"></div>
        <div className="fullPhotoBox two">
          <img
            src="https://img.0u0.kr/new_port/sejong_footer_img1.jpg"
            alt=""
          />
          <img
            src="https://img.0u0.kr/new_port/sejong_footer_img2.jpg"
            alt=""
          />
        </div>
        <div className="fullPhotoBox two">
          <img
            src="https://img.0u0.kr/new_port/sejong_footer_img4.jpg"
            alt=""
          />
          <img
            src="https://img.0u0.kr/new_port/sejong_footer_img3.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
export default Detail;
