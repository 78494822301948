import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/ItSchool"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/itschool_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight"></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>IT스쿨 프로젝트 스토리</h2>
            <div className="txtBox">
              프로그래밍 공부를 위하여 손잡이닷컴을 퇴사하고, IT스쿨에서
              프로그래밍 교육을 받았습니다. 다양한 프로그램을 제작하였고,
              만들었던 프로그램을 간단하게 소개해드리고자 합니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/itschool_prgMaker.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">프로그래머 메이커 개인 프로젝트</h2>
              <div className="txtBox">
                프로그래머 메이커는 각종 프로그램을 배워 최고의 프로그래머를
                키우는 게임입니다 처음으로 배운 java로 GUI 프로그램을
                만들었습니다. 게임 '프린세스 메이커'를 잇는 프로그래머 메이커..
                <div className="linkBox">
                  <a
                    href="https://dl.0u0.kr/new_port/prgMaker.zip"
                    rel="noreferrer"
                    target="_blank"
                  >
                    소스파일 다운로드
                  </a>
                  <a
                    href="https://dl.0u0.kr/new_port/prgMaker.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    발표자료 보기
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/itschool_amart.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox yellowton">
              <h2 className="leftAlign">마트 재고/납품 관리 개인 프로젝트</h2>
              <div className="txtBox">
                마트의 기본정보와 구매자 통계, 그리고 제품의 담당자들을 관리할
                수 있는 Java 프로그램으로 디자인도 어느정도 신경써서
                제작하였습니다.
                <div className="linkBox">
                  <a
                    href="https://dl.0u0.kr/new_port/amrt.zip"
                    rel="noreferrer"
                    target="_blank"
                  >
                    소스파일 다운로드
                  </a>
                  <a
                    href="https://dl.0u0.kr/new_port/amart.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    발표자료 보기
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/itschool_codingoverflow.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox greenton">
              <h2 className="leftAlign">코딩오버플로우 팀 프로젝트</h2>
              <div className="txtBox">
                Coding Overflow는 한국형 Stack Overflow로 개발자들의
                커뮤니티입니다. 시간절약을 위해 퍼블리싱은 Stack Overflow에서
                가져온 후 조금 수정하였지만, Spring, Mybatis등을 활용하지 않고
                순수 JSP와 DB통신으로 2명의 팀프로젝트입니다.
                <div className="linkBox">
                  <a href="http://kin.0u0.kr" rel="noreferrer" target="_blank">
                    홈페이지 방문
                  </a>
                  <a
                    href="https://dl.0u0.kr/new_port/kin.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    발표자료 보기
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/itschool_start.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">출발했어요 팀 프로젝트</h2>
              <div className="txtBox">
                '출발했어요'는 음식 주문 웹사이트이다. 사용자위치 기반으로
                음식점을 찾아주며 주문할 수 있습니다. 보통은 APP에 집중되어
                있기에 WEB형태로 제작한 배달 주문 사이트.
                <div className="linkBox">
                  <a
                    href="http://start.0u0.kr/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    홈페이지 방문
                  </a>
                  <a
                    href="https://dl.0u0.kr/new_port/start.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    발표자료 보기
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>
        </div>
      </div>
    </>
  );
}
export default Detail;
