import React, { useState, useEffect } from "react";
import axios from "axios";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import { Link } from "react-router-dom";
import MainSVG from "./MainSVG";
import myJonCon1 from "../Static/img/main_myjob_icon_1.svg";
import myJonCon2 from "../Static/img/main_myjob_icon_2.svg";
import myJonCon3 from "../Static/img/main_myjob_icon_3.svg";
import myJonConSub from "../Static/img/main_sub_icon.svg";
import mobiImgFix from "../Static/img/main_slide_bgcolor_mobi.png";
import { useDocumentMeta } from '../useDocumentMeta';

function Main(props) {
  props.setColorData("#48486a");
  props.setHeaderClassName("realMain");
  const [mainMent, setMainMent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMent = async () => {
    try {
      // 요청이 시작 할 때에는 error 와 users 를 초기화하고
      setError(null);
      setMainMent(null);
      // loading 상태를 true 로 바꿉니다.
      setLoading(true);
      const response = await axios.get("https://api.0u0.kr/mainMent");
      setMainMent(response.data.data); // 데이터는 response.data 안에 들어있습니다.
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMent();
  }, []);
  useDocumentMeta("", "#ebebeb");

  if (loading) return <div></div>;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!mainMent) return null;

  return (
    <>
      <ParallaxProvider>
        <div className="whiteBg homefix">
          <div className="basicWidth mainSlide ">
            <div className="msTextBox">
              <div className="msTxtBoxPt">
                <h1>{mainMent.mainImgTitle}</h1>
                <div className="subtxt">{mainMent.mainImgTxt}</div>
                <div className="subtxtBtn">
                  <Link to="/Portfolio">SEE MY WORK</Link>
                </div>
              </div>
            </div>
            <div className="msImgBox">
              <div className="msImgBoxPt">
                <MainSVG />
              </div>
            </div>
            <img src={mobiImgFix} className="mobiMainImgFix" alt="" />
          </div>

          <div className="mainInfo">
            <div className="basicWidth">
              <h2 className="tit">About Me</h2>
              <div className="mainInfoIconList">
                <ul>
                  <li>
                    <img
                      src={myJonCon1}
                      className="mainCon"
                      alt="디자이너 아이콘, 그림아래에 자가 그려져 있습니다."
                    />
                    <h3>Designer</h3>
                    <div className="txtbox">{mainMent.designerTxt}</div>
                  </li>

                  <li>
                    <img
                      src={myJonCon2}
                      className="mainCon"
                      alt="디자이너 아이콘, 그림아래에 자가 그려져 있습니다."
                    />
                    <h3>Publisher</h3>
                    <div className="txtbox">{mainMent.publisherTxt}</div>
                  </li>
                  <li>
                    <img
                      src={myJonCon3}
                      className="mainCon"
                      alt="디자이너 아이콘, 그림아래에 자가 그려져 있습니다."
                    />
                    <h3>Developer</h3>
                    <div className="txtbox">{mainMent.developerTxt}</div>
                  </li>
                </ul>

                <div className="subtxtBtn">
                  <Link to="/AboutMe">SEE ABOUT ME</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ParallaxProvider>
    </>
  );
}

export default Main;
