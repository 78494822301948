import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Kmf"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/kmf_bg.jpg"
        className="topmobiImgStyle"
        strength={500}
      >
        <div className="parallaxHeight "></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>케이엠에프 소개</h2>
            <div className="txtBox">
              (주)케이엠에프는 1994년 6월 진명산업으로 창업하여, 고객만족!
              사원소통! 이라는 경영방침을 기본으로 현대.기아.르노삼성.쉐보레 등
              1차 협력사인 덴소풍성. 현담산업. TI Autimotive. 코아비스 . BOSCH
              등에 독자적 기술력을 바탕으로 자동차용 연료펌프휠터와 정밀
              플라스틱 부품을 생산 및 공급하고 있으며, 자동차용 Wire Harness
              제조사업 추진으로 사업의 다각화를 실현하고 있습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/kmf_bg2.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">케이엠에프 홈페이지 소개</h2>
              <div className="txtBox">
                스킨을 구매하였으므로 퍼블리싱 작업의 비중보다는 그누보드
                스킨작업과 제품소개 부분 Javascript 작업의 비중이 더 큽니다.
                관리자의 특수 기능으로는 사이버홍보관 게시글 유투브 링크
                자동으로 넣어주는 기능이 있습니다.
                <div className="linkBox">
                  <a
                    href="https://www.kmfco.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    홈페이지 방문
                  </a>
            
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Detail;
