import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';
function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Dystec"
      );
      setDetailInfo(response.data.data);

    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
 
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox ">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/dystec_bg.png"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight "></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>동양에스텍 e-biz 소개</h2>
            <div className="txtBox">
            회사 내 업무지원 프로그램입니다.
            기존 연차프로그램 유지보수, 신규 기능으로 계약 관리 기능과 인사평가 기능을 추가하였습니다. 
            전산실 ITGC 대응중 하나로 db모니터링 시스템을 구축하였습니다. 자세한 내용은 PDF를 참조하여주세요.
            <div class="linkBox" >
                <a href="https://img.0u0.kr/new_port/ebiz.pdf" rel="noreferrer" target="_blank">매뉴얼 PDF</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
       
            <div className="txtGroupBox center" >
              <h2 className="leftAlign" >동양에스텍&ENC 홈페이지</h2>
              <div className="txtBox">
              STEC:기존 홈페이지는 구형 디자인을 가지고 있으며 대부분의 컨텐츠가 이미지로 구성되어 있어<br/>
              현대적인 인터넷 웹사이트에 맞지 않으며 접근성이 떨어졌습니다.<br/>
              이번 홈페이지 개편으로 컨텐츠를 텍스트화하고 디자인을 직접 제작 및 변경하고 반응형 홈페이지로 변경하였습니다.<br/>
              PHP이며 그누보드로 구성되어있습니다.
              <div class="linkBox" >
                <a href="https://img.0u0.kr/new_port/STEC_OLD.pdf" rel="noreferrer" target="_blank" style={{backgroundColor:'#103093'}}>기존 PDF</a>
                <a href="https://img.0u0.kr/new_port/STEC_NEW.pdf" rel="noreferrer" target="_blank" style={{backgroundColor:'#103093'}}>신규 PDF</a>
                <a href="http://dystec.co.kr/new/" rel="noreferrer" target="_blank" style={{backgroundColor:'#2a2d4a'}}>홈페이지 접속</a>
      
              </div><br/><br/>
              ENC: 기존에는 형식상으로만 존재하던 홈페이지를 디자인하고 제작하였습니다.<br/>
              다국어 및 반응형 홈페이지이며 언어는 그누보드 없이 순전히 PHP로 구성되어있습니다.<br/>
              포토샵의 AI와 DALL-E3로 배경이미지를 생성하였습니다.
              <div class="linkBox">
                <a href="https://img.0u0.kr/new_port/ENC_OLD.pdf" rel="noreferrer" target="_blank" style={{backgroundColor:'#123fa6'}}>기존 PDF</a>
                <a href="https://img.0u0.kr/new_port/ENC_NEW.pdf" rel="noreferrer" target="_blank" style={{backgroundColor:'#123fa6'}}>신규 PDF</a>
                <a href="http://site.dy-enc.co.kr/" rel="noreferrer" target="_blank" style={{backgroundColor:'#203564'}}>홈페이지 접속</a>
              
              </div>
              </div>
            </div>
          </div>





          <div className="blnakHeight150"></div>

        
          <div class="detailInfo basicWidth topImgTxtStyle">
            <div class="basicDetailTitleBox"><h2>기타 진행 업무</h2>
            <div class="txtBox">회사 서버 관리, ITGC(내부회계관리제도, IT시스템 통제) 대응</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default Detail;
