import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Codexbridge"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox x2size">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/codex_main_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight"></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>코덱스브리지 스토리</h2>
            <div className="txtBox">
              유지보수 및 일부 신규프로젝트에 대한 설명이 들어가있습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">




          <div className="blnakHeight150"></div>
          <div className="basicDetailTitleBox ">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_01.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">EBM센터 통합 업무 프로그램 유지보수</h2>
              <div className="txtBox">
                JSP, Mysql<br/>
                EBM센터에 가입한 회원들의 건강정보와 처방에 관련된 정보를
                등록하여 회원들의 건강을 보다 체계적으로 관리 할 수 있는 웹
                프로그램입니다. <br/>
                2018.06.14 ~ 2018.11.15
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_02.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox yellowton">
              <h2 className="leftAlign">한국한의학연구원 K-prism 유지보수</h2>
              <div className="txtBox">
                JSP, Mysql<br/>
                K-Prism 내부 프로그램 사용성 개선 및 유지보수<br/>
                2018.09.30 ~ 2021.06.14
              </div>
            </div>
          </div>







          <div className="blnakHeight150"></div>


          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_99.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">주차장 이용 시스템 및 관리 시스템</h2>
              <div className="txtBox">
                Spring Boot, Mysql<br/>
                주차장 관리시스템 제작 참여 (계약상 원청 업체 공개불가)<br/>
                유저는 주차장 위치를 파악하고, 정기권 구매를 쉽게 할 수 있습니다. 관리자 페이지에서는 고객사 관리 및 주차장 이용 고객들을 파악할 수 있습니다.<br/> 
                Spring Boot 활용하여 REST API 제작을 주로 맡았으며 KICC 온라인 결제시스템 탑재 (제공 문서 참조하여 제작), VAN사 통해 오프라인 결재 내역 연동 (Linux를 통해 불러옴), 휴대폰 인증 연동, 카카오맵 활용하여 주차장 위치 표시도 진행하였습니다.<br/>
                2019.11.18 ~ 2020.09.28
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>
          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_03.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">충남어촌특화지원센터 퍼블리싱</h2>
              <div className="txtBox">
                HTML<br/>
                접근성을 고려하여 마크업 한 퍼블리싱.<br/>
                2020.10.26 ~ 2020.11.06
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox ">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_04.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">세종인재육성진흥원 유지보수</h2>
              <div className="txtBox">
                eGovFramework, MySQL<br/>
                세종출장 유지보수 업무<br/>
                다른 업체에서 개발자 채용전까지 업무 진행하도록 하였음<br/>
                2021.01.13 ~ 2021.05.21
              </div>
            </div>
          </div>

          <div className="blnakHeight150"></div>



          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_05.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">세종의뜻 유지보수</h2>
              <div className="txtBox">
                eGovFramework, Oracle<br/>
                세종의뜻 관리자 페이지 및 웹 및 앱페이지 유지보수<br/>
                다른 업체에서 개발자 채용전까지 업무 진행하도록 하였음<br/>
                2021.01.29 ~ 2021.05.21
              </div>
            </div>
          </div>


          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox ">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/codex_06.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox yellowton">
              <h2 className="leftAlign">조안호수학 어플리케이션 및 API / 관리 시스템 유지보수</h2>
              <div className="txtBox">
                eGovFramework, MySQL, IONIC<br/>
                조안호수학 유지보수 업무<br/>
                결재 시스템 탑재, iOS 앱에 신규 등록 등 진행 <br/>
                2020.05.25 ~ 2021.06.15
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default Detail;
