import React, { Component, useState } from "react";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouterSet from "./Router/RouterSet";

function App() {
  const [colorData, setColorData] = useState("#fs0");
  const [headerClassName, setHeaderClassName] = useState("");
  return (
    <Router>
      <>
        <Header colorData={colorData} headerClassName={headerClassName} />
        <>
          <RouterSet
            setColorData={setColorData}
            setHeaderClassName={setHeaderClassName}
          />
        </>
        <>
          <Footer />
        </>
      </>
    </Router>
  );
}

export default App;
