import React, { Component } from "react";

function Footer(){
    return (
        <>
            <footer>
                <div className="ft_container">
                    <div className="inner_wrapper">
                        <div className="logo_wrapper">
                        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="468.552"
            height="76.96"
            viewBox="0 0 468.552 76.96"
          >
          
          <g data-name="그룹 1" id="그룹_1">
              <path d="M210.68572,76.95999C215.57372,76.95999 224.62172,75.60799 224.62172,65.72799L224.62172,25.37599L195.70972,25.37599L195.70972,13.51999L213.90972,13.51999L213.90972,-0.00001L190.92572,-0.00001C183.95772,-0.00001 180.00572,2.49999 180.00572,9.25599L180.00572,38.68799L208.91772,38.68799L208.91772,63.75199L180.00572,63.75199L180.00572,76.95999L210.68572,76.95999zM263.62172,38.68799L277.55772,38.68799L277.55772,25.37599L263.62172,25.37599L263.62172,-0.00001L246.77372,-0.00001L246.77372,25.37599L232.94172,25.37599L232.94172,38.68799L246.77372,38.68799L246.77372,67.38799C246.77372,72.89999 249.06172,76.95599 257.27372,76.95599L274.22572,76.95599L274.22572,63.75199L263.62172,63.75199L263.62172,38.68799zM297.73372,76.43999L330.49372,76.43999L330.49372,24.85599L314.79372,24.85599L314.79372,63.23199L301.58172,63.23199L301.58172,24.85599L285.88172,24.85599L285.88172,66.87199C285.87772,73.21599 289.30972,76.43999 297.73372,76.43999zM352.74972,76.95999L385.50972,76.95999L385.50972,-0.00001L369.80972,-0.00001L369.80972,25.37599L340.89372,25.37599L340.89372,67.07599C340.89372,74.15199 345.46972,76.95999 352.74972,76.95999zM369.80572,38.68799L369.80572,63.75199L356.59772,63.75199L356.59772,38.68799L369.80572,38.68799zM396.94972,13.51999L412.64972,13.51999L412.64972,-0.00001L396.94972,-0.00001L396.94972,13.51999zM396.94972,76.95999L412.64972,76.95999L412.64972,25.37599L396.94972,25.37599L396.94972,76.95999zM436.67772,76.43999L468.70972,76.43999L468.70972,24.85599L424.09372,24.85599L424.09372,65.83199C424.09372,73.83999 429.81372,76.43999 436.67772,76.43999zM453.00572,38.06399L453.00572,63.23199L439.79772,63.23199L439.79772,38.06399L453.00572,38.06399z" data-name="패스 3" class="headerZeroFont" id="패스_3"></path>
              <path d="M14.885864653367996,76.45083086679837 L49.51559179045677,76.45083086679837 L49.51559179045677,0.7399136752929311 L0.1577200000476829,0.7399136752929311 L0.1577200000476829,63.25351502607723 C0.1577200000476829,74.78380149744412 9.477874038476944,76.45083086679837 14.885864653367996,76.45083086679837 zM32.03477106310844,18.79939850996395 L32.03477106310844,58.80810337446591 L17.417264515657426,58.80810337446591 L17.417264515657426,18.79939850996395 L32.03477106310844,18.79939850996395 zM136.62761082770348,76.44548782394787 L171.25733796479227,76.44548782394787 L171.25733796479227,0.7345706324424368 L121.89504065014839,0.7345706324424368 L121.89504065014839,63.24817198322674 C121.89504065014839,74.78380149744412 131.21519468857767,76.45083086679837 136.62318530346872,76.45083086679837 L136.62761082770348,76.44548782394787 zM153.77651723744393,18.794055467113456 L153.77651723744393,58.80810337446591 L139.15458516575814,58.80810337446591 L139.15458516575814,18.79939850996395 L153.77651723744393,18.794055467113456 z" data-name="패스 4" class="headerStudioFont" id="패스_4" stroke="null"></path>
              <path d="M62.82076833507878 33.641287700163275 H104.75625150118759 V47.189674569213821 H62.82076833507878 Z" id="svg_1" class="headerZeroFont"></path>
            </g>
          </svg>     </div>

                        <div className="copyright_wrapper">
                            <p className="copyright">COPYRIGHT ALL RIHGT 0-0 Studio</p>
                            <p className="copyright contus">Actor : Ahn Gwang Sik</p>
                            <p className="copyright contus">Contact Us : admin@0u0.kr</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;