import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function AceDetail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Ace"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/ace_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight"></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>ACE칸막이 로고 스토리</h2>
            <div className="txtBox">
              디자이너로 회사 생활을 시작하기 전에 운 좋게 인터넷을 통하여
              개인적으로 일을 따내게 되었고 처음에는 ACE칸막이의 로고 제작으로
              시작하게 되었습니다. A부분을 강조해서 작업하였으며, 붉은색 계열을
              사용하여 더 강조시켰습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <img
            src="https://img.0u0.kr/new_port/ace_bg2.jpg"
            className="bdImgBox"
            alt=""
          />
          <div className="basicDetailTitleBox">
            <h2>ACE칸막이 홈페이지 스토리</h2>
            <div className="txtBox">
              로고를 제작 한 후, 혹시 홈페이지도 만들어 줄 수 있는지 추가 문의가
              들어왔습니다. 해당 의뢰에 흔쾌히 수락했고 당시 홈페이지는
              그누보드4를 활용하여 제작하였습니다. 몇 년이 지난 지금 홈페이지는
              더 이상 운영되고 있지 않으며 메인 스크린샷으로만 보존되고
              있습니다.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AceDetail;
