import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Xerox"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <div className="whiteBg">
        <div className="fullPhotoBox two flexStyle fixMobies">
          <img src="https://img.0u0.kr/new_port/xerox_img1_fix.jpg" alt="" />
          <div className="textAreas">
            <div className="basicDetailTitleBox">
              <div className="txtBox">
                <h2>PhotoMeter 서비스 소개</h2>
                후지제록스 제품 고객이 링크가 포함 된 문자를 받고, 링크에
                접속하면 사용량을 촬영하여 전송하면 후지제록스 관리자 페이지에서
                해당 사진 내역을 확인할 수 있습니다. 매월 특정기간에 문자가
                자동으로 발송되며, 관리자 페이지에서 문자를 재전송할 수
                있습니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="blnakHeight200"></div>
        <div className="fullPhotoBox one ">
          <img src="https://img.0u0.kr/new_port/xerox_img2_fix.jpg" alt="" />
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo topImgTxtStyle basicWidth">
          <div className="basicDetailTitleBox">
            <h2>PhotoMeter 서비스 개발</h2>
            <div className="txtBox">
              모바일 화면, 관리자 화면 모두 JSP이고 DB는 Oracle DB입니다. 문자를
              받고 해당 링크에 휴대폰 브라우저로 접속하는 방식으로
              어플리케이션은 따로 없습니다. 문자메시지 발송, 전송 내역 통계를
              확인할 수 있습니다. 후지제록스 프린터 임대 서비스를 사용하는 사람
              혹은 담당 관리자만 실제 화면을 확인할 수 있습니다.
              <div className="linkBox">
                <a
                  href="https://codexbridge.com/46"
                  rel="noreferrer"
                  target="_blank"
                >
                  회사 포폴 페이지
                </a>  <a
                      href="https://img.0u0.kr/pdf/xerox.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                    소개 PDF
                    </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Detail;
