import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Sonjabee"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/sonjabee_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight"></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>손잡이닷컴 스토리</h2>
            <div className="txtBox">
              기획부터 디자인,퍼블리싱까지 작업하거나 혹은 퍼블리싱만 작업한
              페이지가 있습니다. 회사에서는 모바일 페이지의 개선이 시급하다고
              하였고, 실제로도 모바일 페이지에 많은 변화가 생겼으며 CSS를 가장
              많이 활용할 수 있어 좋은 경험이 되었습니다. 이 외에도 퍼블리싱과
              관련없이 순수 디자인만 하는 경우도 있습니다.
              <br />
              <b>각 이미지를 클릭하면 퍼블리싱된 화면을 보실 수 있습니다.</b>
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/promi.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_01.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>체험약속 모바일 리뉴얼 디자인/퍼블리싱</h2>
            <div className="txtBox">
              체험약속은 고객들에게 제품을 주고, 고객이 SNS나 블로그에 업로드를
              약속하는 페이지입니다. 입사 후 처음으로 진행된 퍼블리싱
              작업입니다. 단색과 손잡이닷컴의 컬러와 유사한 색을 사용해 심플하게
              제작되 깔끔한 느낌으로 작업하였습니다. 남은 기간이 표시된 부분이
              현재 진행중인 이벤트인데, 이 곳에 들어갈 사진은 제품 썸네일이
              들어가지만 이미지 크기가 정사각형이라 남는 공간에 Background-color
              처리를 수동으로 하기에는 손이 많이가 같은 사진을 배경으로 넣어
              최대한 유사한 색상이 배경으로 들어가게끔 처리된것이 특징입니다.
              디자인과 퍼블리싱을 작업하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/contest.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_02.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>콘테스트 모바일 신설 디자인/퍼블리싱</h2>
            <div className="txtBox">
              콘테스트는 인기투표와 비슷합니다. 자신의 DIY를 올리거나 투표할 수
              있습니다. 기존에는 모바일 버전이 없어서 모바일 버전을 새롭게
              기획하였습니다. 예시 이미지에 가로 스크롤 기능을 넣어 보기 편하게
              하였고, 이 과정에서 iOS의 경우에는 -webkit-overflow-scrolling:
              touch 속성을 추가해줘야 스크롤이 부드럽게 된다는 것을
              인지하였습니다. 메인 이미지에는 자세히 보면 패턴배경이 살짝 들어가
              있는데 포토샵 작업이 아닌 CSS3로 처리해 굳이 포토샵에서 작업없이
              사진만 넣어도 보기 좋게 처리하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/chulcheck.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_03.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>출석체크 모바일 리뉴얼 디자인/퍼블리싱</h2>
            <div className="txtBox">
              쇼핑몰에 흔하게 있었던 출석체크 후 포인트 지급 이벤트 입니다.
              (*소금=포인트) 계절에 따라 배경과 주색상이 변경되어서 계절마다
              디자인을 디자이너가 수동으로 변경할 필요도 없고 사용자들도 직접
              달력을 보는듯한 느낌을 줄 수 있습니다. 데모 페이지에서는 다음버튼
              누르면 배경만 변경됩니다. 모바일 전용 페이지라서 vw,vh등을
              사용해보기도 하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/promi.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_06.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>체험약속, 모야모야, 아이워너비 각 상세 페이지 퍼블리싱</h2>
            <div className="txtBox">
              퍼블리싱만 진행하였습니다. 이전에는 이 상세페이지에 들어가는
              텍스트를 포토샵으로 직접 작업하였으나, 관리자 페이지에서 텍스트를
              입력하면 들어가게끔 디자인이 구성되고 퍼블리싱도 이를 고려하여
              진행되었습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/newmobi.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_04.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>
              모바일 헤더,풋터,사이드메뉴 개선 및 고객센터 모바일 신설
              디자인/퍼블리싱
            </h2>
            <div className="txtBox">
              기존 모바일의 헤더 및 풋터와 사이드메뉴등을 개선하였습니다.
              상품분류의 경우 텍스트로만 되어있었으나, 대분류는 보기 쉽게
              아이콘으로 표현하였으며 그라데이션을 사용하였던 헤더부분은
              단색으로 사용하여 깔끔한 느낌을 줍니다. 풋터는 기존에는
              하얀색이였으나 PC버전과 동일한 색상을 사용해 통일감을 주도록
              하였습니다. 고객센터의 경우에는 일부 고객센터에 있던 게시판이
              모바일 버전이 존재하지 않았으며, 기존 게시판 디자인을 개선하면서
              대부분 게시판에 사용할 수 있도록 목록,상세,작성,비밀글보기로
              구성되어있습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/refur.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_11.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>PC/모바일 리퍼상품 페이지 신설 디자인/퍼블리싱</h2>
            <div className="txtBox">
              리퍼 상품을 판매하는 기획 페이지를 신설하였다. 매진이면 매진,
              다양한 리퍼 이유에 대한 아이콘을 제작하였습니다. 따로 웹폰트
              사용이 제한된 상태에서 윈도우 기준 최대한 기본 폰트를 활용하여
              제작하였습니다. 제품이미지에 hover했을 경우 나오는 할인 퍼센트의
              경우가 그러한 경우입니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/brand.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_07.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>브랜드 페이지 리뉴얼 디자인/퍼블리싱</h2>
            <div className="txtBox">
              브랜드별로 상품을 볼 수 있는 페이지입니다. 레이어팝업, 슬라이드
              메뉴 사용하고 PC와 최대한 비슷한 느낌으로 구성하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/ev443.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_12.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>PC/모바일 쪽지온 복주머니 이벤트 퍼블리싱</h2>
            <div className="txtBox">
              쪽지온 이벤트 페이지입니다. 퍼블리싱과 Javascript 제작하였습니다.
              랜덤이벤트와 클릭시 gif이미지 노출로 브라우저 버전이 낮아도
              애니메이션 호과가 잘 적용되도록 처리하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/ev447.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_13.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>PC/모바일 쪽지온 복주머니 이벤트 퍼블리싱</h2>
            <div className="txtBox">
              쪽지온 이벤트 페이지입니다. 퍼블리싱과 Javascript 제작하였습니다.
              룰렛이 돌아가는 javascript 적용과 모바일에서도 원형 룰렛이 화면에
              꽉 차더라도 돌아가는 부분에 문제없게끔 퍼블리싱하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/renew.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_05.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>P모바일 상세페이지, 후기 작성 디자인/퍼블리싱</h2>
            <div className="txtBox">
              1차 리뉴얼, 2차 리뉴얼로 구분됩니다. 첫 상품상세 리뉴얼때는 하단에
              Fixed처리된 구매/장바구니 버튼을 추가하였으며 기본 CSS를 사용하여
              스크롤 주면, 일부기기에서 같이 스크롤되는 이슈가 발생하여
              iScroll.js 사용하여 이슈를 해결함과 동시에 조금 더 재미있는
              스크롤을 할 수 있게 되었습니다. 2차 리뉴얼에서는 탭메뉴를 추가하고
              후기 작성 및 목록 페이지를 전체적으로 개선하였습니다. 구매 고객 총
              평점의 경우에도 기존에는 별 반개, 1개짜리 색칠된 이미지가 따로
              있어 표시되었으나 CSS의 Width로 처리하여 조금 더 직관적인 평균을
              표시해주었습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/_new_m/chulcheck.php"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_08.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>모바일 출석체크 리뉴얼 디자인/퍼블리싱</h2>
            <div className="txtBox">
              모바일 출석체크 화면을 다시 한번 리뉴얼하였다. 당시 다녔던 회사의
              의견에 따라 달력 날짜를 가로 스크롤로 구현하였고, 큰 틀의
              일러스트는 결제하고 있는 이미지 사이트에서 다운 받아 사용한 것
              이지만, 일부 사람들은 이미지에 맞게 많은 수정이 있었습니다.
              퍼블리싱은 특별한게 없고 디자인에 힘을 불어넣은 경우입니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/_new_m/new_mlist.php"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_09.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>모바일 상품 목록 디자인/퍼블리싱</h2>
            <div className="txtBox">
              기존 레이아웃은 사진이 한줄당 2칸으로 채워져있는 구성이였습니다.
              하지만 많은 쇼핑몰들은 한줄에 한개의 사진으로 구성되어 있었기에 이
              레이아웃으로 된 디자인을 구성하고 퍼블리싱 하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/pddetail.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_14.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>PC 상품 상세페이지 디자인/퍼블리싱</h2>
            <div className="txtBox">
              같은 넓이여도 기존에는 좁게 보였던 상세페이지를 넓게 보이게
              변경하였고, 잘 사용하지 않을것 같은 부분은 버튼을 눌러야만 보일 수
              있게 수정하였습니다 디자인의 획기적인 개선 및 상품 옵션 목록
              마우스 오버 시 큰 이미지로 보여주는 기능 등을 추가하습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <a
            href="https://demo.0u0.kr/sonjabee/mobipay.html"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="https://img.0u0.kr/newport/sonjabee/sjb_m_10.jpg"
              className="bdImgBox"
              alt=""
            />
          </a>
          <div className="basicDetailTitleBox">
            <h2>모바일 상품 장바구니/결제/찜 디자인/퍼블리싱</h2>
            <div className="txtBox">
              기존 디자인은 완전 초창기 모바일 레이아웃과 디자인이였지만 당시에
              맞는 트렌드로 완전히 새롭게 디자인하였습니다.
            </div>
          </div>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/sonjabee_bg2.jpg"
        strength={200}
      >
        <div className="parallaxHeight"></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>손잡이닷컴 디자인 스토리</h2>
            <div className="txtBox">
              손잡이닷컴에서 작업한 내용중 퍼블리싱이 필요 없는 부분, 기본적으로
              배너와 상세페이지를 포토샵으로 작업한 내용을 보여주고 있습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <img
            src="https://img.0u0.kr/newport/sonjabee/design_001.jpg"
            className="bdImgBox"
            alt=""
          />
          <img
            src="https://img.0u0.kr/newport/sonjabee/design_002.jpg"
            className="bdImgBox"
            alt=""
          />
          <div className="basicDetailTitleBox">
            <h2>목재재단 안내 상세페이지</h2>
            <div className="txtBox">
              목재재단 안내 상세페이지를 디자인하였습니다. 밝은색 사용으로
              깔끔한 느낌을 주며, 아이콘으로 일반,규격초과,화물배송의 느낌을 잘
              나타내주었습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <img
            src="https://img.0u0.kr/newport/sonjabee/design_004.jpg"
            className="bdImgBox"
            alt=""
          />
          <div className="basicDetailTitleBox">
            <h2>우리집데코 각 게시판 모바일 배너</h2>
            <div className="txtBox">
              우리집데코는 일종의 포토 후기 게시판입니다. 기존에는 단순 배경에
              텍스트만 있는 배너였지만 새로 만들 배너는 일러스트도 넣고 화려하게
              변경하였습니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <img
            src="https://img.0u0.kr/newport/sonjabee/design_005.jpg"
            className="bdImgBox"
            alt=""
          />
          <div className="basicDetailTitleBox">
            <h2>앱스토어 앱 소개 페이지</h2>
            <div className="txtBox">
              플레이스토어와 애플앱스토어에 올라가는 앱 소개 페이지를
              제작하였습니다. 손잡이닷컴 컬러에 맞는 배경과 핸드폰 목업에 앱
              구동 화면을 넣어 디자인하여 앱의 장점을 소개합니다.
            </div>
          </div>
        </div>

        <div className="detailInfo basicWidth topImgTxtStyle">
          <img
            src="https://img.0u0.kr/newport/sonjabee/design_006.jpg"
            className="bdImgBox"
            alt=""
          />
          <div className="basicDetailTitleBox">
            <h2>각종 메인 배너</h2>
            <div className="txtBox">
              쿠폰,생일 등 이벤트 배너를 디자인하였습니다. 꼭 사각형이여한다는
              기본관념을 깨고 디자인하였습니다.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Detail;
