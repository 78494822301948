import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Bingli"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/bing_bg.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">빙글이 JS메이커</h2>
              <div className="txtBox">
                고객의 셀프 인테리어 사진을 촬영해가고 포인트를 지급하는
                이벤트가 있습니다. 사진 촬영을 하고 인테리어 제품 중에
                손잡이닷컴 제품이 있으면 제품 페이지로 링크합니다. 이 과정은
                기존에는 일일히 javascript로도 작업하고 css에서도 수작업으로
                맞춰줬어야 했습니다. 그러나 일일히 위치를 잡아 링크를 거는것은
                너무 불편했기에 프로그램을 만들었습니다. 이미지 주소 넣고
                확인버튼을 누른다음, 원하는 영역에 마우스를 클릭하면 빙글이를
                넣을 수 있습니다.
                <div className="linkBox">
                  <a
                    href="https://demo.0u0.kr/bing/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    홈페이지 접속
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Detail;
