import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";

function LoadDetail(props) {
  return (
    <>
      <div className="detailHeader">
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3></h3>
                <h1></h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul>
            <li>
              <div className="tit">카테고리</div>
              <h2></h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2></h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2></h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2></h2>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default LoadDetail;
