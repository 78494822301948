import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Ksel"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox ">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/ksel_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight "></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>신안환경연구소 관리자 페이지 소개</h2>
            <div className="txtBox">
            회사 내 업무관리를 편하게 해주는 신안환경연구소만의 자체 웹 프로그램!
반응형 웹과 PWA를 적용하여 별도의 앱스토어 없이 앱처럼 쓸 수 있게 하여 PC와 휴대폰에서 직원들의 업무능력 향상에 도움을 주고자 진행된 프로젝트입니다.
추후 계획으론 PWA의 단점인 아이폰에서 푸시 메시지를 수신할 수 없어, 시놀로지의 채팅으로 알림을 활용할 계획이였습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/ksel001.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">성적서 기반 업체관리</h2>
              <div className="txtBox">
               성적서를 엑셀 문서로 작성하면, 업체관리와 성적서 관리 페이지에 업로드 됩니다.
               프로그램이 만들어지기전에 작성된 성적서 또한 업로드됩니다.
               기존의 엑셀 문서는 이 프로그램을 위해 만들어지지 않았으며 업체명의 일부 불규칙한 부분을 바로잡아주기위해
               Java단에서 업체명을 이니셜로 바꿔 ID를 생성해서 최대한 업체명의 중복등록을 방지하였습니다.
                
              </div>
            </div>
          </div>



          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/ksel002.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">업로드 된 성적서로 각종 통계</h2>
              <div className="txtBox">
                간단하게는 기온부터 측정항목(먼지,화학물질 등)을 통계낼 수 있습니다.
                전체 지역은 물론 지역 및 업체별로 필터링해서 통계를 내고
                그래프의 색상변경 명칭변경, 결과 값 변경 또한 가능합니다.
                연간 및 월간 또는 원하는 일정별로도 검색할 수 있습니다.
                PDF로 출력하여 클라이언트와 공유할 수도 있습니다.
              </div>
            </div>
          </div>



          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox ">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/ksel003.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">일정 공유 시스템</h2>
              <div className="txtBox">
                업무 프로그램에서 업체 일정을 등록하면 PC나 모바일에서 확인할 수 있으며
                분석업무담당자들이 근무하는 위치하는 모니터에 상시적으로는 업체를 표시하는 것이 아닌, 해당 업체에서 분석해야할 항목들을 표시해줍니다.
                분석항목별로 묶어 볼 수 있어 분석일정을 정하는데 있어, 분석을 한번에 진행하여 효율적으로 분석을 진행할 수 있습니다.
              </div>
            </div>
          </div>



          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox right">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/ksel004.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">출/퇴근 여부 및 현재 위치 현황</h2>
              <div className="txtBox">
                출근 시간이 직원마다 다르기에 CAPS 경비시스템의 DB를 읽어 직원들의 출근 및 퇴근 여부를 확인할 수 있게 만들었습니다.
                또한 자율좌석제로 인하여 현 위치 파악을 위해 각 좌석에 배치된 QR코드를 인식하여 현재 위치를 현황 모니터에 출력해주도록 하였습니다.
                하이웍스에서 휴가유무 데이터를 가져오는것은 API도 없고 일반적인 방법으로 빼올 수 없었으므로, Python의 Selnium 활용하여 데이터를 가져와서
                휴가일때는 휴가임을 사무실의 현황 모니터에 표시해주고 있습니다.
              </div>
            </div>
          </div>


          <div className="blnakHeight150"></div>

          <div className="basicDetailTitleBox ">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/ksel005.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">주요 업무를 현황 모니터에 표시</h2>
              <div className="txtBox">
                위의 분석일정이 아닌, 다른 외적인 업무등을 표시합니다.
                해당주차 혹은 해당월에 완료해야하는 업무들을 표시하거나
                분리수거 등 각자의 메인 업무 외적의 할 일들을 사무실의 현황 모니터에 표시해주고 있습니다.
              </div>
            </div>
          </div>
          <div className="blnakHeight150"></div> 

          <div class="detailInfo basicWidth topImgTxtStyle">
            <div class="basicDetailTitleBox"><h2>기타 진행 업무</h2>
            <div class="txtBox">회사 NAS 장비 구축, 회사 웹 사이트 관리, 사내 컴퓨터 관리 등 전반적인 IT업무를 진행하였습니다.</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default Detail;
