import React, { useEffect, useState } from "react";
import axios from "axios";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useDocumentMeta } from '../useDocumentMeta';
function Detail(props) {
  window.scrollTo(0, 0);
  // const [detailInfo, setDetailInfo] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  // const fetchDetail = async () => {
  //   try {
  //     setError(null);
  //     setDetailInfo(null);
  //     setLoading(true);
  //     const response = await axios.get(
  //       "https://api.0u0.kr/portfolio/detail/Kycu"
  //     );
  //     setDetailInfo(response.data.data);
  //   } catch (e) {
  //     setError(e);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   fetchDetail();
  //   //
  // }, []);

  // if (loading) return <div></div>;
  // if (error) return <div>에러가 발생했습니다.</div>;
  // if (!detailInfo) return null;
  // if (detailInfo) {
  //   props.setColorData('#005834');
  // }
  props.setColorData("#005834");
  useDocumentMeta("건양사이버대학교 군경교육지원센터 & 학생지원센터","#005834");

  return (
    <>
      <ParallaxProvider>
        <div className="detailHeader" style={{ backgroundColor: "#00683D" }}>
          <div className="dhTb">
            <div className="dhTbCell">
              <div className="dhDiv">
                <div className="txtBox">
                  <h3>학생이 체감할 수 있는 교육혁신!</h3>
                  <h1>건양사이버대학교 군경교육지원센터 & 학생지원센터</h1>
                </div>
                <div className="logoBox">
                  <div className="logoBoxPt">
                    <img
                      src={"https://img.0u0.kr/new_port/kycu_logo.png"}
                      alt={"건양사이버대학교의 로고"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="whiteBg">
          <div className="detailInfo basicWidth">
            <ul style={{ color: "#344537" }}>
              <li>
                <div className="tit">카테고리</div>
                <h2>Front-End,Back-End</h2>
              </li>

              <li>
                <div className="tit">업무처</div>
                <h2>코덱스브리지</h2>
              </li>

              <li>
                <div className="tit">기간</div>
                <h2>2019.01</h2>
              </li>

              <li>
                <div className="tit">사용 툴/언어</div>
                <h2>JSP</h2>
              </li>
            </ul>
          </div>
        </div>
        <div className="whiteBg">
          <div className="blnakHeight200"></div>
        </div>
        <div className="laptop_dungdung">
          <Parallax y={[0, -20]} className="kycu_mobi_fix1">
            <div className="imgDungDungBox">
              <img
                src="https://img.0u0.kr/new_port/kycu_main_deco.png"
                className=""
                alt=""
              />
            </div>
          </Parallax>

          <div className="DungDungTxtBox">
            <div className="realTxtBox">
              <Parallax x={[0, -20]} className="kycu_mobi_fix2">
                <h2>
                  <b>건양사이버대학교</b>
                  <br />
                  군경지원센터
                </h2>
                <div className="txtBox">
                  건양사이버대학교 군경교육센터 반응형 홈페이지입니다. 각종
                  군경교육 안내와 자격증, 장학 안내에 대한 정보를 확인할 수
                  있습니다. JSP와 MS SQL로 구성되어있으며, 현재는 개편되어
                  서비스를 확인할 수 없습니다.
                  <div className="linkBox">
                  <a
                    href="https://img.0u0.kr/pdf/kycu.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    소개 PDF
                  </a>
                  </div>
                </div>
                <div className="clearboth"></div>
              </Parallax>
            </div>
          </div>

          <div
            className="bgBackColorBox"
            style={{ backgroundColor: "#282e53" }}
          ></div>
        </div>

        <div className="whiteBg">
          <div className="blnakHeight150"></div>
          <div className="detailInfo basicWidth pdbnone topImgTxtStyle">
            <div className="basicDetailTitleBox">
              <div className="imgGroupBox"></div>
              <div className="txtGroupBox">
                <h2 className="leftAlign">군경지원센터 작업 스토리</h2>
                <div className="txtBox">
                  유지보수가 아닌 처음부터 진행하게 된 JSP 프로젝트입니다.
                  그동안 MySQL를 사용해왔으나 MS SQL을 처음 사용해 본 프로젝트가
                  되었습니다. 게시판이 있는데 따로 글 작성 기능은 없으며 외부
                  테이블에서 게시글을 불러오게끔만 작업하였습니다.
                </div>
              </div>
            </div>
          </div>
          <div className="blnakHeight150"></div>
        </div>

        <div className="whiteBg">
          <div className="imgShadowBox">
            <img src="https://img.0u0.kr/new_port/kycu_shadowImg.jpg" alt="" />
          </div>
        </div>

        <div className="whiteBg">
          <div className="blnakHeight200"></div>
          <div
            className="fullPhotoBox two ovParallaxTwo"
            style={{ backgroundColor: "#97a7c1" }}
          >
            <Parallax y={[-6, 3]}>
              <img src="https://img.0u0.kr/new_port/kycu_full_1.jpg" alt="" />
            </Parallax>
            <Parallax y={[3, -6]}>
              <img src="https://img.0u0.kr/new_port/kycu_full_2.jpg" alt="" />
            </Parallax>
          </div>
        </div>

        <div className="whiteBg">
          <div className="blnakHeight200"></div>
          <div className="blnakHeight200"></div>
        </div>

        <div className="laptop_dungdung color_kycu_fix">
          <Parallax y={[0, -20]} className="kycu_mobi_fix1">
            <div className="imgDungDungBox">
              <img
                src="https://img.0u0.kr/new_port/kycu_main_deco2.png"
                className=""
                alt=""
              />
            </div>
          </Parallax>

          <div className="DungDungTxtBox">
            <div className="realTxtBox">
              <Parallax x={[0, -20]} className="kycu_mobi_fix2 color_kycu">
                <h2 style={{ color: "#00683D" }}>
                  <b>건양사이버대학교</b>
                  <br />
                  학생지원센터
                </h2>
                <div className="txtBox">
                  건양사이버대학교 학생지원센터 반응형 홈페이지입니다. 해당
                  메뉴를 통해 정보를 얻을 수 있으며, 캘린더를 통해 학사 일정을
                  확인할 수 있습니다. 현재는 개편되어 서비스를 확인할 수
                  없습니다.
                  <div className="linkBox greenFix">
                    <a
                      href="https://img.0u0.kr/pdf/kycu.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                    소개 PDF
                    </a>
                  </div>
                </div>
                <div className="clearboth"></div>
              </Parallax>
            </div>
          </div>

          <div
            className="bgBackColorBox"
            style={{ backgroundColor: "#00683D" }}
          ></div>
        </div>

        <div className="whiteBg">
          <div className="blnakHeight150"></div>
          <div className="detailInfo basicWidth pdbnone topImgTxtStyle">
            <div className="basicDetailTitleBox">
              <div className="imgGroupBox"></div>
              <div className="txtGroupBox">
                <h2 className="leftAlign">학생지원센터 작업 스토리</h2>
                <div className="txtBox">
                  캘린더가 생각보다 복잡해서 어려웠던 작업이였습니다. 여러가지
                  로직이 요구되었기에 계산이 많이 필요했던 작업으로 기억됩니다.
                </div>
              </div>
            </div>
          </div>
          <div className="blnakHeight150"></div>
        </div>

        <div className="whiteBg">
          <div className="imgShadowBox">
            <img src="https://img.0u0.kr/new_port/kycu_shadowImg2.png" alt="" />
          </div>
          <div className="blnakHeight200"></div>
        </div>
      </ParallaxProvider>
    </>
  );
}
export default Detail;
