import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Classys"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox x2size">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <Parallax
        bgImage="https://img.0u0.kr/new_port/classys_bg.jpg"
        strength={500}
        className="topmobiImgStyle"
      >
        <div className="parallaxHeight "></div>
      </Parallax>
      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <h2>클래시스 앱 소개</h2>
            <div className="txtBox">
              사용자에게 커뮤니티를 제공하고, 제품의 사용 및 활용 방법을 더
              자세히 확인 할 수 있도록 도움을 주며 제품 마케팅 담당자에게 도움을
              주는 어플리케이션입니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox mobiCont">
              <img
                src="https://img.0u0.kr/new_port/classys_bg2.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox classys">
              <h2 className="leftAlign">깔끔한 코드로 편리한 유지보수</h2>
              <div className="txtBox">
                시간적 여유가 많았던 프로젝트라서 코드가 다른 프로젝트에 비해
                훨씬 깔끔합니다. 최대한 코드를 정리 후 작업하였습니다. 영어로
                되어있지만 향후 다국어 기능을 위해 한국어 기능이 숨겨져
                있습니다. ionic으로 개발한 만큼 기존에는 애플 앱스토어에도
                업로드 되었고 사용할 수 있었으나 개발자 계정이 비활성화 되어서
                더 이상 앱스토어에서는 운영하고 있지 않습니다.
                <div className="linkBox">
                <a
                      href="https://img.0u0.kr/pdf/classys.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                    소개 PDF
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="blnakHeight100"></div>
        <div className="fullPhotoBox one">
          <img src="https://img.0u0.kr/new_port/classys_img1.jpg" alt="" />
        </div>
        <div className="fullPhotoBox one">
          <img src="https://img.0u0.kr/new_port/classys_img2.jpg" alt="" />
        </div>
      </div>
    </>
  );
}
export default Detail;
