import { useEffect } from 'react';

export const useDocumentMeta = (title, themeColor) => {
  useEffect(() => {
    if (title) {
      document.title = `${title} - 0-0 Studio`;
    } else {

      document.title = `0-0 Studio`;
    }
    
    if (themeColor) {
      const metaThemeColor = document.querySelector("meta[name=theme-color]");
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", themeColor);
      } else {
        const meta = document.createElement('meta');
        meta.name = "theme-color";
        meta.content = themeColor;
        document.getElementsByTagName('head')[0].appendChild(meta);
      }
    }

    // iOS Safari용
    const metaAppleStatusBar = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
    if (metaAppleStatusBar) {
      metaAppleStatusBar.setAttribute("content", "black-translucent");
    } else {
      const meta = document.createElement('meta');
      meta.name = "apple-mobile-web-app-status-bar-style";
      meta.content = "black-translucent";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

  }, [title, themeColor]);
};