import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parallax } from "react-parallax";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';

function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Ongime"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);

  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox x2size">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth ">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo topImgTxtStyle">
          <img
            src="https://img.0u0.kr/new_port/ongime_bg.jpg"
            className="bdImgBox"
            alt=""
          />
          <div className="basicWidth basicDetailTitleBox">
            <h2>온기메 어플리케이션 제작</h2>
            <div className="txtBox">
              가정집의 간단한 보수/교체 작업부터 상가, 산업체의 각종 시설비,
              자동화장비 제작 등을 의뢰하면 주변 전문기술자들과 실시간으로
              매칭시켜 시간과 비용의 가성비를 극대화 시키는 중개플랫폼 입니다.
            </div>
          </div>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox mobiCont">
              <img
                src="https://img.0u0.kr/new_port/ongime_first_bg.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox classys">
              <h2 className="leftAlign">API도 직접 제작한 IONIC 프로젝트</h2>
              <div className="txtBox">
                기존 작업에서는 DB와 통신, 연계되는 API 작업하는 사람이 따로
                있었더라면 이번에는 API 제작부터 IONIC 플러그인 작업까지 모두
                본인이 작업하였습니다. 채팅 기능과 휴대폰 본인 인증기능이
                있습니다. API는 PHP로 되어있으며 DB는 Mysql입니다.
                {/* iOS는 존재하였으나 현재 내려갔습니다. */}
                <div className="linkBox">

   
              <a
                    href="https://img.0u0.kr/pdf/ongime.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    소개 PDF
                  </a>
                  {/* <a
                    href="https://play.google.com/store/apps/details?id=com.ongime.app"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Android 다운로드
                  </a> */}

                  {/* <a
                    href="https://apps.apple.com/kr/app/%EC%98%A8%EA%B8%B0%EB%A9%94/id1553844393?app=itunes&ign-mpt=uo%3D4"
                    rel="noreferrer"
                    target="_blank"
                  >
                    iOS 다운로드
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="blnakHeight100"></div>
        <div className="fullPhotoBox two">
          <img src="https://img.0u0.kr/new_port/ongime_img4.jpg" alt="" />
          <img src="https://img.0u0.kr/new_port/ongime_img1.jpg" alt="" />
        </div>
      </div>
    </>
  );
}
export default Detail;
