import React, { useEffect, useState } from "react";
import axios from "axios";
import { XMasonry, XBlock } from "react-xmasonry";
import LoadingPage from "./LoadingPage";
import { useDocumentMeta } from '../useDocumentMeta';


function Detail(props) {
  window.scrollTo(0, 0);
  const [detailInfo, setDetailInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDetail = async () => {
    try {
      setError(null);
      setDetailInfo(null);
      setLoading(true);
      const response = await axios.get(
        "https://api.0u0.kr/portfolio/detail/Shop"
      );
      setDetailInfo(response.data.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    //
  }, []);
  useDocumentMeta(detailInfo?.pjDetailBigName, detailInfo?.contentHdBgColor);


  if (loading) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }
  if (error) return <div>에러가 발생했습니다.</div>;
  if (!detailInfo) return null;
  if (detailInfo) {
    props.setColorData(detailInfo.contentHdBgColor);
  }

  return (
    <>
      <div
        className="detailHeader"
        style={{ backgroundColor: detailInfo.contentBgColor }}
      >
        <div className="dhTb">
          <div className="dhTbCell">
            <div className="dhDiv">
              <div className="txtBox">
                <h3>{detailInfo.pjDetailSmName}</h3>
                <h1>{detailInfo.pjDetailBigName}</h1>
              </div>
              <div className="logoBox">
                <div className="logoBoxPt">
                  <img
                    src={"https://img.0u0.kr/new_port/" + detailInfo.imgLogo}
                    alt={detailInfo.pjName + "의 로고"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="detailInfo basicWidth">
          <ul style={{ color: detailInfo.contentFontColor }}>
            <li>
              <div className="tit">카테고리</div>
              <h2>{detailInfo.tagName}</h2>
            </li>

            <li>
              <div className="tit">업무처</div>
              <h2>{detailInfo.teamName}</h2>
            </li>

            <li>
              <div className="tit">기간</div>
              <h2>{detailInfo.pjDate}</h2>
            </li>

            <li>
              <div className="tit">사용 툴/언어</div>
              <h2>{detailInfo.pjTool}</h2>
            </li>
          </ul>
        </div>
      </div>

      <div className="whiteBg">
        <div className="detailInfo basicWidth topImgTxtStyle">
          <div className="basicDetailTitleBox">
            <div className="imgGroupBox">
              <img
                src="https://img.0u0.kr/new_port/shop_bg.jpg"
                className="bdImgBox"
                alt=""
              />
            </div>
            <div className="txtGroupBox">
              <h2 className="leftAlign">그 외 쇼핑몰 작업물 스토리</h2>
              <div className="txtBox">
                의류 쇼핑몰 제이앤와이스타일과 생활용품 쇼핑몰 하우쿨에서
                근무했을 당시 상세페이지 혹은 배너이미지를 제작했습니다. 당시
                트렌드에 맞게 제작하였으며 그 중 일부 자료를 업로드합니다.
              </div>
            </div>
          </div>

          <div className="basicPadding"></div>
        
          <XMasonry>
            <XBlock>
              <div className="xbCard">
                <img
                  src="https://img.0u0.kr/newport/2020/07/03/e0bd014f_hw_01.jpg"
                  alt=""
                />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img
                  src="https://img.0u0.kr/newport/2020/07/03/c95c22f3_hw_02.jpg"
                  alt=""
                />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img
                  src="https://img.0u0.kr/newport/2020/07/03/c3716e3c_hw_03.jpg"
                  alt=""
                />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img
                  src="https://img.0u0.kr/newport/2020/07/03/bf2225a3_hw_04.jpg"
                  alt=""
                />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img
                  src="https://img.0u0.kr/newport/2020/07/03/c8f673b4_hw_05.jpg"
                  alt=""
                />
              </div>
            </XBlock>

            <XBlock>
              <div className="xbCard">
                <img src="https://img.0u0.kr/new_port/shop_cont1.jpg" alt="" />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img src="https://img.0u0.kr/new_port/shop_cont2.jpg" alt="" />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img src="https://img.0u0.kr/new_port/shop_cont3.jpg" alt="" />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img src="https://img.0u0.kr/new_port/shop_cont4.jpg" alt="" />
              </div>
            </XBlock>
            <XBlock>
              <div className="xbCard">
                <img src="https://img.0u0.kr/new_port/shop_cont5.jpg" alt="" />
              </div>
            </XBlock>
          </XMasonry>
        </div>
      </div>
    </>
  );
}
export default Detail;
