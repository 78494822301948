import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Navigation(props) {
  const [menuState, setMenuState] = useState("");
  function openMenu() {
    setMenuState("menuOn");
  }
  function closeMenu() {
    setMenuState("");
  }

  return (
    <>
      <nav>
        <ul>
          <li>
            <NavLink to="/AboutMe" activeClassName="act">
              <span>About me</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Portfolio" activeClassName="act">
              <span>Portfolio</span>
            </NavLink>
          </li>
          <li>
            <a href="https://blog.0-0.kr" target="_blank" rel="noreferrer">
              <span>Blog</span>
            </a>
          </li>
        </ul>
        <div className="mobiHambuger" onClick={openMenu}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            enable-background="new 0 0 50 50"
          >
            <path
              fill="#fff"
              d="M8.667,15h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,15,8.667,15z"
            />
            <path
              fill="#fff"
              d="M8.667,37h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,37,8.667,37z"
            />
            <path
              fill="#fff"
              d="M8.667,26h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,26,8.667,26z"
            />
          </svg>
        </div>
      </nav>
      <div
        style={{ backgroundColor: props.colorData }}
        className={"mobiRightMenu " + menuState}
      >
        <div className="rmenu_list">
          <NavLink to="/Main" activeClassName="act" onClick={closeMenu}>
            <span>Home</span>
          </NavLink>
          <NavLink to="/AboutMe" activeClassName="act" onClick={closeMenu}>
            <span>About me</span>
          </NavLink>
          <NavLink to="/Portfolio" activeClassName="act" onClick={closeMenu}>
            <span>Portfolio</span>
          </NavLink>
          <a href="https://blog.0-0.kr" target="_blank" rel="noreferrer">
            <span>Blog</span>
          </a>
        </div>
      </div>
      <div
        onClick={closeMenu}
        className={"mobiRightMenuBack " + menuState}
      ></div>
    </>
  );
}

export default Navigation;
